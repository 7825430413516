import React from "react";
import './AppTemp.css';
import 'antd/dist/antd.css';
import {HelmetProvider} from "react-helmet-async";
import {Helmet} from "react-helmet";
import {Layout} from "antd";


import {isMobile} from "react-device-detect";
import {Base64} from 'js-base64';
import {getProjectTitle} from "./util/ProjectUtil";
import {useLocation} from "react-router-dom"
// import WebifyPower from "./comp/WebifyPower"
import IcpFooter from "./comp/IcpFooter"
// import Snowfall from "react-snowfall";
// import MyFireworks from "./comp/MyFireworks";
// import {MyCalendar} from "./util/MyCalendar";

const {Footer} = Layout;
// export
// default // class
export default function AppTemp(props) {
    let ps = props.showFooter;

    let showFooter;
    const location = useLocation();
    const {pathname} = location;
    let current = props.current;
    if (!current) {
        current = pathname && pathname.length > 1 && pathname.substr(1);
    }
    // React.useEffect(() => {
    //     props.currentFn(current)
    // }, [current]);

    if (ps === false) {
        showFooter = false;
    } else {
        showFooter = current !== 'pan' && !isMobile;
    }
    //
    // // !isHttps() && categories.push({key: "live", value: '直播'},);
    // if (Profiles) {
    //     // alert("come here");
    //     categories.push({key: "live", value: '直播'});
    // }
    // categories.push({key: "site", value: '网址'});
    // if (isMobile) {
    //     for (let i = categories.length - 1; i >= 0; i--) {
    //         if (categories[i].key === 'tv') {
    //             categories.splice(i, 1);
    //         }
    //     }
    // }
    // setInterval(function(){let a = new Date(); debugger; return new Date() - a > 100;}, 100);

    // function KeepFoot() {

    // document.addEventListener("DOMContentLoaded", function (event) {
    //     let element = document.getElementById('app_layout');
    //     let height = element.offsetHeight;
    //     if (height < window.screen.height) {
    //         document.getElementById("footer").classList.add('stikybottom');
    //     }
    // }, false);

    // }

    //useEffect
    // useEffect(() => {
    //     if (current) {
    //         // localStorage.setItem("menu_key", current);
    //         store.set("menu_key", current);
    //     }
    //
    //     // showFooter && KeepFoot();
    // }, [current]);

    // componentDidMount(): void {
    //
    //     !isMobile && this.KeepFoot();
    // }
    let navBarHeight = isMobile ? 48 : 64;
    // let contentHeight = this.contentHeight;
    let headerHeight = navBarHeight - 4;

    // const [colorMode, setColorMode] = useColorMode();

    function getContentHeight() {
        let height = window.screen.height;

        let offset = 115;
        // if (showFooter) {
        //     offset = 120;
        // }
        return height - navBarHeight - offset;
    }


    // console.log("fireworksDay:" + fireworksDay());


    let marginBottom = showFooter ? "5em" : 0;
    // console.log("current:"+current)
    //     return <Layout style={{background: 'rgba(255,255,255,1)'}}>
    let contentHeight = props.contentHeight;

    contentHeight = contentHeight ? contentHeight : getContentHeight();

    return <Layout
        style={{background: '#F6F7F9'}}
    >

        {/*<Provider store={ZhidahaoStoreDataStore}>*/}


        <HelmetProvider>
            <Helmet>
                <title>{getProjectTitle(current)}</title>
                {/*<title>个人资料储存</title>*/}
                <meta name="description" content="一键直达"/>
                <meta name="theme-color" content="#008f68"/>
            </Helmet>
        </HelmetProvider>


        <Layout.Content style={{marginTop: headerHeight, marginBottom: marginBottom,background:'#ffff'}}>


            <div id="content" style={{
                // padding: 2,
                minHeight:contentHeight,
                paddingBottom: 0
            }}

            >

                {/*{this.props.children}*/}
                {props.children}

                {/*<div className="float-right justify-content-center "*/}
                {/*     style={{*/}
                {/*         marginTop: '-2rem'*/}
                {/*         // , background: '#ffff'*/}
                {/*     }}*/}
                {/*>*/}
                {/*    <ScrollButton scrollStepInPx="50" delayInMs="16.66"/>*/}
                {/*</div>*/}


            </div>


        </Layout.Content>

        {showFooter && <Footer

            id="footer"
            style={{
                height: 48,
                background: "#F0F2F5",
                //     background: "#7DBCEA"
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0

            }}

            className='box'

        >
            {window.location.host ? window.location.host.replace("www.", "") : ''} ©<span className={"ms-1 me-1"}>{new Date().getFullYear()}</span> {Base64.decode('MTM3NTk5OTVAcXEuY29t')}

            <span className={"ms-1 me-1"}>{getProjectTitle()}</span>
            {/*{window.location.host.indexOf('zhidahao.com') !== -1 ?*/}
            {window.location.host.indexOf('zhidahao.com') !== -1 ? <IcpFooter/> : ''}
            {/*: */}
            {/*<WebifyPower/>*/}
            {/*}*/}


        </Footer>}



        {/*position: absolute;*/}
        {/*top: 0;*/}
        {/*left: 0;*/}
        {/*width: 100%;*/}
        {/*height: 100%;*/}
        {/*<Snowfall*/}
        {/*    // Changes the snowflake color*/}
        {/*    color="white"*/}
        {/*    // Applied to the canvas element*/}
        {/*    style={{ background: 'rgba(255,255,255,0)',*/}

        {/*        position: 'fixed',*/}
        {/*        width: '100vw',*/}
        {/*        height: '100vh'*/}

        {/*    }}*/}

        {/*    // Controls the number of snowflakes that are created (default 150)*/}
        {/*    snowflakeCount={200}*/}
        {/*/>*/}

        {/*{fireworksDay()*/}
        {/*&& <MyFireworks/>*/}
        {/*}*/}

    </Layout>
}
