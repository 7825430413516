import React from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import AppTemp from "../AppTemp";

import WebTorrent from 'webtorrent';
import path from 'path';
import throttle from 'throttleit';
import escapeHtml from 'escape-html';

import prettierBytes from 'prettier-bytes';

import {DownloadOutlined} from '@ant-design/icons';
import moment from 'moment';
import {isMobile, isTablet, isBrowser} from "react-device-detect";
import {useLocation} from "react-router-dom";
import {useMount} from "ahooks";
import 'moment/locale/zh-cn';
import {Col, Input, Row, Button} from 'antd';
import createTorrent from 'create-torrent'

const {Search} = Input;

export function MagnetPlayer(props) {


    // var announceList = [
    //     ['udp://tracker.openbittorrent.com:80'],
    //     ['udp://tracker.internetwarriors.net:1337'],
    //     ['udp://tracker.leechers-paradise.org:6969'],
    //     ['udp://tracker.coppersurfer.tk:6969'],
    //     ['udp://exodus.desync.com:6969'],
    //     ['wss://tracker.webtorrent.io'],
    //     ['wss://tracker.btorrent.xyz'],
    //     ['wss://tracker.openwebtorrent.com'],
    //     ['wss://tracker.fastcast.nz']
    // ]
    global.WEBTORRENT_ANNOUNCE = createTorrent.announceList
        .map(function (arr) {
            return arr[0]
        })
        .filter(function (url) {
            return url.indexOf('wss://') === 0 || url.indexOf('ws://') === 0
        })

    //
    // global.WEBTORRENT_ANNOUNCE = announceList
    //     .map(function (arr) {
    //         return arr[0]
    //     })
    //     .filter(function (url) {
    //         return url.indexOf('wss://') === 0 || url.indexOf('ws://') === 0
    //     })


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    // const [torrentInfoHash, setTorrentInfoHash] = React.useState('');
    // const [torrentMagnetURI, setTorrentMagnetURI] = React.useState('');
    // const [torrentName, setTorrentName] = React.useState('');
    // const [torrentProgress, setTorrentProgress] = React.useState('');
    // const [torrentFiles, setTorrentFiles] = React.useState([]);
    // const [log, setLog] = React.useState('');
    // const [error, setError] = React.useState('');
    let name = query.get("name") || props.name;
    const [downloadInfo, setDownloadInfo] = React.useState();
    const [torrent, setTorrent] = React.useState();
    const [playName, setPlayName] = React.useState(name);


    let
        torrentId = query.get('torrentId') || "https://webtorrent.io/torrents/sintel.torrent";


    useMount(() => {
        // console.log("filmId:"+filmId);
        // if (filmId)
        // {
        downloadTorrent(torrentId);
    }, [torrentId]);


    let client = new WebTorrent()

    client.on('error', function (err) {
        console.error('ERROR: ' + err.message)
    })


    const DISALLOWED = [
        '6feb54706f41f459f819c0ae5b560a21ebfead8f'
    ]

    // console.log("hello");


    function downloadTorrentFromSearch(torrentId) {
        setPlayName("");
        downloadTorrent(torrentId);
    }


    function downloadTorrent(torrentId) {
        // alert(torrentId);
        const disallowed = DISALLOWED.some(function (infoHash) {
            return torrentId.indexOf(infoHash) >= 0
        })

        if (disallowed) {
            // setLog('File not found ' + torrentId)

        } else {

            // setLog('Downloading torrent from ' + torrentId)
            // getClient(function (err, client) {
            //     if (err) {
            //         setError(error);
            //         return;
            //     }
            //     client.add(torrentId, onTorrent)
            // })
            // alert(torrentId);
            client.add(torrentId, onTorrent);
        }
    }


    init();

    function init() {
        if (!WebTorrent.WEBRTC_SUPPORT) {
            // setError('This browser is unsupported. Please use a browser with WebRTC support.')
        }

        // For performance, create the client immediately
        // getClient(function () {
        // })

        // Seed via upload input element
        // const upload = document.querySelector('input[name=upload]')
        // if (upload) {
        //     uploadElement(upload, function (err, files) {
        //         if (err) return util.error(err)
        //         files = files.map(function (file) {
        //             return file.file
        //         })
        //         onFiles(files)
        //     })
        // }


        // Seed via drag-and-drop
        // dragDrop('body', onFiles)

        // Download via input element
        // const form = document.querySelector('form')
        // if (form) {
        //     form.addEventListener('submit', function (e) {
        //         e.preventDefault()
        //         let torrent = document.querySelector('form input[name=torrentId]').value.trim();
        //         alert(torrent)
        //         console.log("torrent:" + torrent);
        //         downloadTorrent(torrent)
        //     })
        // }

        // Download by URL hash
        onHashChange()
        window.addEventListener('hashchange', onHashChange)

        function onHashChange() {
            const hash = decodeURIComponent(window.location.hash.substring(1)).trim()
            if (hash !== '') downloadTorrent(hash)
        }

        // Register a protocol handler for "magnet:" (will prompt the user)
        if ('registerProtocolHandler' in navigator) {
            navigator.registerProtocolHandler('magnet', window.location.origin + '#%s', 'Instant.io')
        }
    }


    // function getClient() {
    //     const client = new WebTorrent();
    //     return window.client = client // for easier debugging
    //
    // }

    // const getClient = thunky(function (cb) {
    //     getRtcConfig(function (err, rtcConfig) {
    //         if (err) util.error(err)
    //         const client = new WebTorrent({
    //             tracker: {
    //                 rtcConfig: {
    //                     ...SimplePeer.config,
    //                     ...rtcConfig
    //                 }
    //             }
    //         })
    //         window.client = client // for easier debugging
    //         client.on('warning', util.warning)
    //         client.on('error', util.error)
    //         cb(null, client)
    //     })
    // });


    // function isTorrentFile(file) {
    //     const extname = path.extname(file.name).toLowerCase()
    //     return extname === '.torrent'
    // }


    // function downloadTorrentFile(file) {
    //     //util.unsafeLog('Downloading torrent from <strong>' + escapeHtml(file.name) + '</strong>')
    //
    //     // const client = new WebTorrent();
    //
    //     // getClient(function (err, client) {
    //     //     if (err) {
    //     //         setError(err);
    //     //     }
    //     //     window.client.add(file, onTorrent)
    //     // })
    //     client.add(file, onTorrent);
    // }

    // function seed(files) {
    //     if (files.length === 0) return
    //     // setLog('Seeding ' + files.length + ' files')
    //
    //     // Seed from WebTorrent
    //     // getClient(function (err, client) {
    //     //     if (err) return util.error(err)
    //     //     client.seed(files, onTorrent)
    //     // })
    //     client.seed(files, onTorrent);
    // }
    //
    //
    // function isNotTorrentFile(file) {
    //     return !isTorrentFile(file)
    // }

    // function onFiles(files) {
    //     // setLog('got files:')
    //     // files.forEach(function (file) {
    //     //     setLog(' - %s (%s bytes)', file.name, file.size)
    //     //
    //     // })
    //
    //     // .torrent file = start downloading the torrent
    //     files.filter(isTorrentFile).forEach(downloadTorrentFile)
    //
    //     // everything else = seed these files
    //     seed(files.filter(isNotTorrentFile))
    // }


    // React.useEffect(() => {
    //     // Sintel, a free, Creative Commons movie
    //     // const torrentId = 'magnet:?xt=urn:btih:08ada5a7a6183aae1e09d831df6748d566095a10&dn=Sintel&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.fastcast.nz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2F&xs=https%3A%2F%2Fwebtorrent.io%2Ftorrents%2Fsintel.torrent'
    //     let torrentId = "magnet:?xt=urn:btih:1ab4f2da62e64c85323174b77ceae2544045552a&dn=%5Bwww.domp4.cc%5D%E5%9B%BD%E5%AD%90%E7%9B%91%E6%9D%A5%E4%BA%86%E4%B8%AA%E5%A5%B3%E5%BC%9F%E5%AD%90.%E7%AC%AC2%E9%9B%86.HD1080p.mp4&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com";
    //     // const WebTorrent = require('webtorrent');
    //     const client = new WebTorrent();
    //
    //     client.on('error', err => {
    //         setLog('[+] Webtorrent error: ' + err.message);
    //     });
    //
    //     client.add(torrentId, (torrent) => {
    //         const interval = setInterval(() => {
    //             // console.log('[+] Progress: ' + (torrent.progress * 100).toFixed(1) + '%')
    //             let torrentProgress = (torrent.progress * 100).toFixed(1) + '%';
    //             setTorrentProgress(torrentProgress);
    //         }, 5000);
    //         torrent.on('done', () => {
    //             setLog('Progress: 100%');
    //             clearInterval(interval);
    //         });
    //         setTorrentInfoHash(torrent.infoHash);
    //         setTorrentMagnetURI(torrent.magnetURI);
    //         setTorrentFiles(torrent.files);
    //         setTorrentName(torrent.name);
    //
    //         // eslint-disable-next-line array-callback-return
    //         torrentFiles.map((file, i) => {
    //             file.appendTo('body');
    //         })
    //
    //     });
    // });


    function warning(warn) {
        console.log(JSON.stringify(warn));
    }

    function errorLog(error) {
        console.log(JSON.stringify(error));
    }

    // moment.locale('zh-cn');
    function onTorrent(torrent) {
        torrent.on('warning', warning)
        torrent.on('error', errorLog)
        // alert("ab")

        // const upload = document.querySelector('input[name=upload]')
        // upload.value = upload.defaultValue // reset upload element

        // const torrentFileName = path.basename(torrent.name, path.extname(torrent.name)) + '.torrent'

        // setLog('"' + torrentFileName + '" contains ' + torrent.files.length + ' files:')

        torrent.files.forEach(function (file) {
            // console.log(JSON.stringify(file));
            // setLog('&nbsp;&nbsp;- ' + escapeHtml(file.name) + ' (' + escapeHtml(prettierBytes(file.length)) + ')')
        })

        // setLog('Torrent info hash: ' + torrent.infoHash)
        setTorrent(torrent);


        // Find largest file
        let largestFile = torrent.files[0]
        for (let i = 1; i < torrent.files.length; i++) {
            if (torrent.files[i].length > largestFile.length)
                largestFile = torrent.files[i]
        }

        // Display name of the file being streamed
        // $streamedFileName.html(largestFile.name)


        // Stream the file in the browser
        // $('#output').html("");
        document.getElementById("output").innerHTML = '';
        largestFile.appendTo('#output');
        // let videoEle = document.getElementsByName("video")[0];
        // if (videoEle) {
        //
        // }
        // console.log(torrent.magnetURI)
        // console.log(torrent.infoHash)
        // console.log(torrentId)
        let playName = name;
        if (!torrentId || torrentId.indexOf(torrent.infoHash) === -1) {
            playName = largestFile.name;
        }
        setPlayName(playName);


        // let video = document.querySelector("video");

        let video = document.getElementsByTagName("video")[0];
        if (video) {
            if (isTablet) {
                video.height = 450;
                video.width = 600;
            } else if (isBrowser) {
                video.height = 510;
                video.width = 680;
            }

        }


        function updateSpeed() {
            const progress = (100 * torrent.progress).toFixed(1)

            let remaining
            if (torrent.done) {
                remaining = '完成'
            } else {
                // remaining = torrent.timeRemaining !== Infinity
                //     ? formatDistance(torrent.timeRemaining, 0, {includeSeconds: true})
                //     : 'Infinity years'
                // remaining = remaining[0].toUpperCase() + remaining.substring(1) + ' remaining.'

                remaining = moment.duration(torrent.timeRemaining / 1000, 'seconds').humanize()
                // .format("HH时mm分ss秒")
                ;
                remaining = remaining[0].toUpperCase() + remaining.substring(1)

                // .replace("minutes", "分")
                // .replace("hours", "小时")
                // .replace("An hour", "1小时")
                // + ' remaining'
                if (remaining.toLowerCase().indexOf("infinity") !== -1) {
                    remaining = "未知";
                }


            }
            let downloadInfo = {torrent, progress, remaining};
            setDownloadInfo(downloadInfo);

        }

        torrent.on('download', throttle(updateSpeed, 250))
        torrent.on('upload', throttle(updateSpeed, 250))
        setInterval(updateSpeed, 5000)
        updateSpeed()

        // torrent.files.forEach(function (file) {
        //     // append file
        //     file.appendTo(util.logElem, {
        //         maxBlobLength: 2 * 1000 * 1000 * 1000 // 2 GB
        //     }, function (err, elem) {
        //         if (err) return util.error(err)
        //     })
        //
        //     // append download link
        //     file.getBlobURL(function (err, url) {
        //         if (err) return util.error(err)
        //
        //         const a = document.createElement('a')
        //         a.target = '_blank'
        //         a.download = file.name
        //         a.href = url
        //         a.textContent = 'Download ' + file.name
        //         util.appendElemToLog(a)
        //     })
        // })


        // const downloadZip = document.createElement('a')
        // downloadZip.href = '#'
        // downloadZip.target = '_blank'
        // downloadZip.textContent = 'Download all files as zip'
        // downloadZip.addEventListener('click', function (event) {
        //     let addedFiles = 0
        //     const zipFilename = path.basename(torrent.name, path.extname(torrent.name)) + '.zip'
        //     let zip = new JSZip()
        //     event.preventDefault()
        //
        //     torrent.files.forEach(function (file) {
        //         file.getBlob(function (err, blob) {
        //             addedFiles += 1
        //             if (err) {
        //                 // util.error(err)
        //                 setError(err)
        //                 return;
        //             }
        //
        //
        //             // add file to zip
        //             zip.file(file.path, blob)
        //
        //             // start the download when all files have been added
        //             if (addedFiles === torrent.files.length) {
        //                 if (torrent.files.length > 1) {
        //                     // generate the zip relative to the torrent folder
        //                     zip = zip.folder(torrent.name)
        //                 }
        //                 zip.generateAsync({type: 'blob'})
        //                     .then(function (blob) {
        //                         const url = URL.createObjectURL(blob)
        //                         const a = document.createElement('a')
        //                         a.download = zipFilename
        //                         a.href = url
        //                         a.click()
        //                         setTimeout(function () {
        //                             URL.revokeObjectURL(url)
        //                         }, 30 * 1000)
        //                     }, util.error)
        //             }
        //         })
        //     })
        // })

        // util.appendElemToLog(downloadZip)

    }

    // const suffix = (
    //     <DownloadOutlined
    //         style={{
    //             fontSize: 16,
    //             color: '#1890ff',
    //         }}
    //     />
    // );

    // const normFile = (e: any) => {
    //     console.log('Upload event:', e);
    //     if (Array.isArray(e)) {
    //         return e;
    //     }
    //     if (e && e.fileList) {
    //         onFiles(e.fileList)
    //     }
    //
    // };

    function getTorrentFilename(torrent) {
        return path.basename(torrent.name, path.extname(torrent.name)) + '.torrent'

    }


    return (
        <AppTemp current={props.current} showFooter={false}>
            <HelmetProvider>
                <Helmet>
                    <title>{playName || "磁力播放"}</title>
                </Helmet>
            </HelmetProvider>
            <div className="mt-2">
                <div className="log"/>
                <div className="speed"/>
                {/*<div className="log"/>*/}
                {/*{log ? <p>{log}</p> : ''}*/}
                {/*{error ? <p>{error}</p> : ''}*/}

                {/*<Form>*/}
                {/*    <Form.Item label="Dragger">*/}
                {/*        <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>*/}
                {/*            <Upload.Dragger name="files" action="/upload.do">*/}
                {/*                <p className="ant-upload-drag-icon">*/}
                {/*                    <InboxOutlined/>*/}
                {/*                </p>*/}
                {/*                <p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                {/*                <p className="ant-upload-hint">Support for a single or bulk upload.</p>*/}
                {/*            </Upload.Dragger>*/}
                {/*        </Form.Item>*/}
                {/*    </Form.Item>*/}
                {/*</Form>*/}


                {/*<form>*/}
                {/*    <input name="torrentId" placeholder="magnet:" defaultValue={torrentId}/>*/}
                {/*    <button type="submit">download</button>*/}
                {/*</form>*/}

                <Row className="mt-3">
                    <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>

                        <Search className="justify-content-center mt-2 mb-2"
                                placeholder="magnet:" defaultValue={torrentId}
                                allowClear
                                onSearch={downloadTorrentFromSearch}
                            // suffix={suffix}
                        />
                    </Col>

                </Row>
                {/*<Row className="mt-3">*/}
                {/*    <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>*/}

                {downloadInfo && <div className="ml-2 mr-2 text-center">
                    <b className={"ml-1"}>连接数:</b> {downloadInfo.torrent.numPeers}
                    <b className={"ml-1"}>进度:</b> {downloadInfo.progress} %
                    <b className={"ml-1"}>下载速度:</b> {prettierBytes(downloadInfo.torrent.downloadSpeed)}/s
                    <br/>
                    <b className={"ml-1"}>上传速度:</b> {prettierBytes(downloadInfo.torrent.uploadSpeed)}/s
                    <b className={"ml-1"}>剩余时间:</b> {downloadInfo.remaining}
                </div>
                }
                {torrent && <div className="mt-2 ml-2 mr-2 text-center">
                    <Button href={escapeHtml(torrent.magnetURI)} rel="noopener noreferrer" target="_blank">磁力链接</Button>
                    <Button className={"ml-2"} href={escapeHtml(torrent.torrentFileBlobURL)} rel="noopener noreferrer"
                            target="_blank"
                            download={escapeHtml(getTorrentFilename(torrent))} icon={<DownloadOutlined/>}>下载种子</Button>


                    {/*{torrent.files && <div>{*/}
                    {/*    torrent.files.map((file, index) =>*/}
                    {/*        <div key={index}>{file.name}*/}
                    {/*            {file.href}*/}
                    {/*            {file.url}*/}
                    {/*            {console.log(file)}*/}
                    {/*        </div>*/}
                    {/*    )*/}

                    {/*}*/}
                    {/*</div>*/}

                </div>
                }

                {downloadInfo && playName && <h5 className={"text-center mt-2"}>{playName}</h5>}
                <div id="output" className="ml-2 mr-2 text-center"/>
                {/*</Col>*/}
                {/*</Row>*/}

            </div>
        </AppTemp>
    );

}

