import React from 'react';
import {Col, Input, message, Radio, Row, Tooltip} from 'antd';
import {isMobile} from "react-device-detect";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export function LenCap(props) {


    const size = isMobile ? 2 : 3;

    function getSplitArrays(array) {
        let arrayOfArrays = [];
        for (let i = 0; i < array.length; i += size) {
            arrayOfArrays.push(array.slice(i, i + size));
        }
        return arrayOfArrays;
    }


    function Temp() {
        const array = [{t: "摄氏度", v: "C", r: -272.15},
            {t: "华氏度", v: "F", r: -457.86999999999995},
            {t: "开氏度", v: "K", r: 1},
            {t: "兰氏度", v: "Ra", r: 1.8},
            {t: "列氏度", v: "Re", r: -217.71999999999997}];
        return <BaseCom array={array}/>
    }


    function setTempArray(nv) {
        let vs = [];
        vs[0] = nv;
        vs[1] = 32 + 1.8 * nv;
        vs[2] = 273.15 + nv;
        vs[3] = vs[2] * 1.8;
        vs[4] = nv * 0.8;
        return vs;
    }

    function formatENumber(fr, limit) {
        let fs = fr.toString();
        let ei = fs.indexOf("e");
        let prefix = Number(fs.substr(0, ei));
        return Number(parseFloat(prefix.toFixed(limit)) + fs.substr(ei));
    }

    function formatNumber(fr, limit) {
        let fs = fr.toString();
        let ei = fs.indexOf("e");
        if (ei === -1) {
            if (fr > 1) {
                fr =
                    parseFloat(
                    fr.toFixed(limit)
                );
            } else {
                fr = fr.toExponential(limit)
                fr = formatENumber(fr, limit);
            }
        } else {
            fr = formatENumber(fr, limit);
        }
        // let arr = new Array(limit).fill(9);//[0,0,0,0,0]
        // let frs = fr.toString();
        // if (frs.endsWith("." + arr.join(""))) {
        //     fr = (Number)(frs.substr(0, frs.indexOf("."))) + 1
        // }
        return fr;
    }

    function getVs(value, item, array) {
        // console.log("value:"+value)
        if (!value) return null;
        if (isNaN(value)) {
            message.warn("不是一个不效的数字");
            return '';
        }

        let r = item.r;
        let ct = item.t;
        let vs = [];
        let nv = Number(value);
        if ("temp" === key) {
            // console.log("value:" + value);
            // console.log("ct:" + ct);
            switch (ct) {
                case "摄氏度" :
                    vs = setTempArray(nv)
                    break;
                case "华氏度" :
                    vs = setTempArray((nv - 32) / 1.8);
                    break;
                case "开氏度" :
                    vs = setTempArray(nv - 273.15);
                    break;
                case "列氏度" :
                    vs = setTempArray(nv / 0.8);
                    break;
                default:
            }

        } else {
            for (let i = 0; i < array.length; i++) {
                let a = array[i];
                let ar = a.r;
                let at = a.t;
                if (at !== ct) {
                    const limit = 4;
                    let fr = nv * ar / r;
                    fr = formatNumber(fr, limit);
                    vs[i] = fr;
                } else {
                    vs[i] = nv;
                }

            }
        }

        return vs;
    }


    function copyText(item, v) {
        if (!v) v = '';
        return <Tooltip placement="topLeft" title={`点击可复制结果${v}`}>

            <CopyToClipboard text={v}
                             onCopy={() => v && message.success("复制成功")}>
                <span>{item.t}{item.v ? "(" + item.v + ")" : ''}</span>
            </CopyToClipboard>
        </Tooltip>

    }


    function BaseCom(props) {
        let array = props.array;
        const [vs, setVs] = React.useState();
        let arrayOfArrays = getSplitArrays(array);

        function onChangeItem(item, e) {
            let value = e.target.value;
            let vs = getVs(value, item, array)
            setVs(vs);
        }

        return <div className={"m-2"}>
            {arrayOfArrays.map((arr, index) =>
                <Row key={index} gutter={[16, 16]}>
                    {arr.map((item, ai) =>
                        <Col span={24 / size} key={item.t}>
                            <Input
                                key={item.t}
                                onChange={(e) => onChangeItem(item, e)}
                                value={vs && vs[index * size + ai]}
                                type="number"
                                addonBefore={copyText(item, vs && vs[index * size + ai]) || ''}/>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    }

    function Len() {

        const array =

            [
                {t: "公里", v: "km", r: 3.08568025e+13},
                {t: "米", v: "m", r: 3.08568025e+16},
                {t: "分米", v: "dm", r: 3.08568025e+17},
                {t: "厘米", v: "cm", r: 3.08568025e+18},
                {t: "毫米", v: "mm", r: 3.08568025e+19},
                {t: "微米", v: "um", r: 3.08568025e+22},
                {t: "纳米", v: "nm", r: 3.08568025e+25},
                {t: "皮米", v: "pm", r: 3.08568025e+28},
                {t: "光年", v: "ly", r: 3.2616},
                {t: "天文单位", v: "au", r: 206265},
                {t: "秒差距", v: "pc", r: 1},


                {t: "里", r: 3.08568025e+16 / 500},
                {t: "丈", r: 3 * 3.08568025e+15},
                {t: "尺", r: 3 * 3.08568025e+16},
                {t: "寸", r: 3 * 3.08568025e+17},
                {t: "分", r: 3 * 3.08568025e+18},
                {t: "厘", r: 3 * 3.08568025e+19},
                {t: "毫", r: 3 * 3.08568025e+20},

                {t: "海里", v: "nmi", r: 3.08568025e+16 / 1852},
                {t: "英寻", r: 3.08568025e+16 / 1.8288},
                {t: "英里", v: "mi", r: 3.08568025e+16 / 1609.344},
                {t: "弗隆", v: "fur", r: 3.08568025e+16 / 201.168},
                {t: "密耳", v: 'mil', r: 3.08568025e+16 * 39370.0787402},
                {t: "码", v: "yd", r: 3.08568025e+16 * 1.0936133},
                {t: "英尺", v: "ft", r: 3.08568025e+16 * 3.2808399},
                {t: "英寸", v: "in", r: 3.08568025e+16 * 39.3700787}
            ];


        return <BaseCom array={array}/>


    }

    function Power() {
        const array =
            [{t: "焦耳", v: "J", r: 1000},
                {t: "公斤·米", v: "kg·m", r: 102},
                {t: "米制马力·时", v: "ps·h", r: 0.0003777},
                {t: "英制马力·时", v: "hp·h", r: 0.0003725},
                {t: "千瓦·时(度)", v: "kw·h", r: 0.0002778},
                {t: "千卡", v: "kcal", r: 0.2389},
                {t: "卡", v: "cal", r: 2389},
                {t: "英热单位", v: "Btu", r: 0.9478171},
                {t: "英尺·磅", v: "ft·lb", r: 737.6},
                {t: "千焦", v: "KJ", r: 1},
            ];
        return <BaseCom array={array}/>
    }

    function Area() {
        const array = [{t: "平方公里", v: "km2", r: 1},
            {t: "公顷", v: "ha", r: 100},
            {t: "公亩", v: "are", r: 10000},
            {t: "市亩", v: "sm", r: 1500},
            {t: "平方米", v: "m2", r: 1000000},
            {t: "平方分米", v: "dm2", r: 100000000},
            {t: "平方厘米", v: "cm2", r: 10000000000},
            {t: "平方毫米", v: "mm2", r: 1000000000000},
            {t: "平方英里", v: "sq mi", r: 0.3861022},
            {t: "英亩", v: "ym", r: 247.1053815},
            {t: "平方竿", v: "sq rd", r: 39536.8610347},
            {t: "平方码", v: "sq yd", r: 1195990.0463011},
            {t: "平方英尺", v: "sq ft", r: 10763910.4167097},
            {t: "平方英寸", v: "sq in", r: 1550003100.0061998}];

        return <BaseCom array={array}/>
    }

    function MultiBase(props) {
        let arrays = props.arrays;
        let showArray = [];
        let initVss = [];

        for (let i = 0; i < arrays.length; i++) {
            let arr = arrays[i];
            let a = arr['a'];
            let arrayOfArrays = getSplitArrays(a);
            showArray.push({t: arr['t'], a: arrayOfArrays});
            initVss.push('');
        }
        const [vss, setVss] = React.useState(initVss);

        function onChangeItem(item, e) {
            let value = e.target.value;
            let vss = [];
            for (let i = 0; i < arrays.length; i++) {
                let vs = getVs(value, item, arrays[i].a);
                if (vs) {
                    vss.push(vs)
                }
            }
            setVss(vss);
        }


        function rendItem(item, ai, index, vs) {

            return <Col span={24 / size} key={item.t}>
                <Input
                    key={item.t}
                    onChange={(e) => onChangeItem(item, e)}
                    value={vs && vs[index * size + ai]}
                    type="number"
                    addonBefore={copyText(item, vs && vs[index * size + ai]) || ''}/>
            </Col>
        }


        return <div className={"m-2"}>


            {showArray.map((bigItem, bi) =>
                <div key={bi} className="mt-2">
                    <h5>{bigItem.t}</h5>
                    <hr/>
                    {/*{console.log(JSON.stringify(bigItem.a))}*/}
                    {bigItem.a.map((arr, index) =>
                        <Row key={index} gutter={[16, 16]}>
                            {arr.map((item, ai) =>
                                // <h5>{item.t}{ai}</h5>
                                rendItem(item, ai, index, vss[bi])
                            )}
                        </Row>
                    )
                    }
                </div>
            )}

        </div>
    }


    function Cap() {
        const array1 = [
            {t: "立方千米", v: "km3", r: 1},
            {t: "公石", v: "hl", r: 10000000000},
            {t: "十升", v: "dal", r: 100000000000},
            {t: "立方米", v: "m3", r: 1000000000},
            {t: "立方分米(升)", v: "(l)dm3", r: 1000000000000},
            {t: "分升", v: "dl", r: 10000000000000},
            {t: "厘升", v: "cl", r: 1e+14},
            {t: "立方厘米", v: "(ml)cm3", r: 1e+15},
            {t: "立方毫米", v: "(μL)mm3", r: 1e+18}

        ];

        const array2 = [{t: "桶", v: "", r: 8648489809.344145},
            {t: "蒲式耳", v: "", r: 28377593258.40175},
            {t: "配克", v: "pk", r: 113510373033.607},
            {t: "夸脱", v: "qt", r: 908082984268.856},
            {t: "品脱", v: "pt", r: 1816165968537.712}];

        const array3 = [{t: "桶", v: "", r: 6110256897.196882},
            {t: "蒲式耳", v: "", r: 27496156037.38597},
            {t: "加仑", v: "bal", r: 219969248299.08777},
            {t: "液量盎司", v: "fl oz", r: 35195079727854.04},
            {t: "品脱", v: "pt", r: 1759753986392.7021}]


        const array4 = [{t: "汤勺", v: "Table spoon", r: 66666666666666.67},
            {t: "调羹", v: "Tea spoon", r: 200000000000000}]

        const array5 = [{t: "汤勺", v: "Tbs", r: 67628045403686.01}
            , {t: "调羹", v: "tsp", r: 202884136211058}
            , {t: "杯", v: "fl oz", r: 4226752837730.3755}
        ];

        const array6 =
            [{t: "桶", v: "42gal", r: 6289810770.432106},
                {t: "加仑", v: "gal", r: 264172052358.14847},
                {t: "夸脱", v: "qt", r: 1056688209432.5939},
                {t: "品脱", v: "pt", r: 2113376418865.1877},
                {t: "及耳", v: "gi", r: 8453505675460.751},
                {t: "液量盎司", v: "fl oz", r: 33814022701843.004},
                {t: "液量打兰", v: "fl dr", r: 270512181614744.03},
                {t: "量滴", v: "min", r: 2077533554801234200}];

        const array7 = [{t: "亩英尺", v: '', r: 810713.1937899},
            {t: "立方码", v: '', r: 1307950619.3143923},
            {t: "立方英尺", v: '', r: 35314666721.488594},
            {t: "立方英寸", v: '', r: 61023744094732.29}];

        const arrays = [{t: '公制', a: array1}
            , {t: '美制干量', a: array2}
            , {t: '英制液量和干量', a: array3}
            , {t: '公制烹调制式', a: array4}
            , {t: '美制烹调制式', a: array5}
            , {t: '美制液量', a: array6}
            , {t: '美英同制体积计量', a: array7}
        ];


        return <MultiBase arrays={arrays}/>

    }

    function Weight() {
        const array1 = [{t: "吨", v: "t", r: 1},
            {t: "公斤(千克)", v: "kg", r: 1000},
            {t: "克", v: "g", r: 1000000},
            {t: "毫克", v: "mg", r: 1000000000}
            , {t: "微克", v: "μg", r: 1000000000000}
            , {t: "公担", v: "q", r: 10}
            , {t: "克拉", v: "ct", r: 5000000}
            , {t: "分", r: 500000000}

        ];


        const array2 = [
            {t: "市斤", r: 2000},
            {t: "担", r: 20},
            {t: "两", r: 20000},
            {t: "钱", r: 200000}];

        const array3 = [
            {t: "金衡磅", v: "lb t", r: 2679.2288807},
            {t: "金衡盎司", v: "oz t", r: 32150.7465686},
            {t: "英钱", v: "dwt", r: 643014.931372},
            {t: "金衡格令", r: 15432358.352928}];


        const array4 =
            [{t: "英制长吨", r: 0.9842065},
                {t: "美制短吨", r: 1.1023113},
                {t: "英担", v: "cwt", r: 19.68413},
                {t: "美担", v: "cwt", r: 22.0462256},
                {t: "英石", r: 157.47304},
                {t: "磅", v: "lb", r: 2204.62256},
                {t: "盎司", v: "oz", r: 35273.96096},
                {t: "打兰", v: "dr", r: 564383.37536},
                {t: "格令", r: 15432357.92}];

        const arrays = [{t: '公制', a: array1}
            , {t: '市 制', a: array2}
            , {t: '金 衡 制', a: array3}
            , {t: '常 衡 制', a: array4}
        ];
        return <MultiBase arrays={arrays}/>
    }


    function Pressure() {
        const array = [
            {t: "千帕", v: "kPa", r: 1},
            {t: "百帕", v: "hPa", r: 10},
            {t: "巴", v: "bar", r: 0.01},
            {t: "毫巴", v: "mbar", r: 10},
            {t: "帕斯卡", v: "Pa = N/m2", r: 1000},
            {t: "标准大气压", v: "atm", r: 0.0098692},

            {t: "毫米汞柱", v: "mmHg", r: 7.5006168},

            {t: "磅力/英尺2", v: "lbf/ft2", r: 20.8854351},
            {t: "磅力/英寸2", v: "lbf/in2 = PSI", r: 0.1450377},

            {t: "英吋汞柱", v: "in Hg", r: 0.2952999},

            {t: "公斤力/厘米2", v: "kgf/cm2", r: 0.0101972},
            {t: "公斤力/米2", v: "kgf/m2", r: 101.9716213},
            {t: "毫米水柱", v: "mmH2O", r: 101.972}];
        return <BaseCom array={array}/>
    }


    function WorkPower() {
        const array = [{t: "瓦", v: "W", r: 1000},
            {t: "千瓦", v: "kW", r: 1},
            {t: "英制马力", v: "HP", r: 1.3410221},
            {t: "米制马力", v: "PS", r: 1.3596216},
            {t: "公斤·米/秒", v: "kg·m/s", r: 101.9716213},
            {t: "千卡/秒", v: "kcal/s", r: 0.239},
            {t: "英热单位/秒", v: "Btu/s", r: 0.9478171},
            {t: "英尺·磅/秒", v: "ft·lb/s", r: 737.5621489},
            {t: "焦耳/秒", v: "J/s", r: 1000},
            {t: "牛顿·米/秒", v: "N·m/s", r: 1000}];
        return <BaseCom array={array}/>
    }

    function Density() {

        const array = [
            {t: "千克/立方米", v: "kg/m3", r: 1},
            {t: "千克/立方厘米", v: "kg/cm3", r: 1e-6},
            {t: "千克/立方分米", v: "kg/dm3", r: 0.001},
            {t: "克/立方厘米", v: "g/cm3", r: 0.001},
            {t: "克/立方分米", v: "g/dm3", r: 1},
            {t: "克/立方米", v: "g/m3", r: 1000},
        ];

        return <BaseCom array={array}/>
    }

    function Force() {

        const array = [
            {t: "千牛", v: "kN", r: 1},
            {t: "牛", v: "N", r: 1000},
            {t: "千克力", v: "kgf", r: 101.971621},
            {t: "克力", v: "gf", r: 101971.621},
            {t: "公吨力", v: "tf", r: 0.1019716},
            {t: "磅力", r: 224.808943},
            {t: "千磅力", v: "kip", r: 0.2248089}
        ];

        return <BaseCom array={array}/>

    }


    function Time() {

        const array = [
            {t: "年", v: "yr", r: 1},
            {t: "周", v: "week", r: 52.1428571},
            {t: "天", v: "d", r: 365},
            {t: "时", v: "h", r: 8760},
            {t: "分", v: "min", r: 525600},
            {t: "秒", v: "s", r: 31536000},
            {t: "毫秒", v: "ms", r: 31536000000},
            {t: "微秒", v: "microsecond", r: 31536000000000},
            {t: "纳秒", v: "nanosecond", r: 3.1536e+16}
        ];

        return <BaseCom array={array}/>

    }

    function Speed() {

        const array = [
            {t: "米/秒", v: "m/s", r: 1},
            {t: "千米/秒", v: "km/s", r: 0.001},
            {t: "千米/时", v: "km/h", r: 3.6},
            {t: "光速", v: "c", r: 3.3356e-9},
            {t: "马赫", v: "mach", r: 0.0029386},
            {t: "英里/时", v: "mile/h", r: 2.236936},
            {t: "英寸/秒", v: "in/s", r: 39.370079}
        ];

        return <BaseCom array={array}/>

    }


    function Storage() {
        const array = [
            {t: "字节", v: "b", r: 1.1259e+15},
            {t: "比特", v: "bit", r: 9.0072e+15},
            {t: "千字节", v: "kb", r: 1099511627776},
            {t: "兆字节", v: "mb", r: 1073741824},
            {t: "千兆字节", v: "gb", r: 1048576},
            {t: "太字节", v: "tb", r: 1024},
            {
                t: "拍字节", v: "pb", r: 1
            }
        ];

        return <BaseCom array={array}/>

    }

    function Angle() {
        const array1 = [
            {t: "圆周", r: 1},
            {t: "直角", r: 4},
            {t: "百分度", v: "gon", r: 399.99996},
            {t: "度", v: "°", r: 360},
            {t: "分", v: "'", r: 21600},
            {t: "秒", r: 1296000}

        ];

        const array2 = [
            {t: "弧度", v: 'rad', r: 6.2831855},
            {t: "毫弧度", v: 'mrad', r: 6283.18548},

        ];


        const arrays = [{t: '角度制', a: array1}
            , {t: '弧度制', a: array2}
        ];
        return <MultiBase arrays={arrays}/>
    }

    function Money() {
        const array = [
            {t: "元", r: 1},
            {t: "角", r: 10},
            {t: "分", r: 100}
        ];

        return <BaseCom array={array}/>

    }


    const labelsAndCom = {
        len: {title: "长度", component: <Len/>},
        workPower: {title: "功率", component: <WorkPower/>},
        area: {title: "面积", component: <Area/>},
        cap: {title: "体积和容量", component: <Cap/>},
        power: {title: "功、能和热量", component: <Power/>},
        press: {title: "压力", component: <Pressure/>},
        temp: {title: "温度", component: <Temp/>},
        weight: {title: "重量", component: <Weight/>},
        density: {
            title: "密度", component: <Density/>
        },
        force: {
            title: "力", component: <Force/>
        },
        time: {
            title: "时间", component: <Time/>
        },
        speed: {
            title: "速度", component: <Speed/>
        },
        storage: {
            title: "存储", component: <Storage/>
        },
        angle: {
            title: "角度", component: <Angle/>
        },

        money: {
            title: "人民币", component: <Money/>
        }


    }

    const [key, setKey] = React.useState('len');

    const handleChange = e => {
        let ek = e.target.value;
        setKey(ek);
    };

    function getComponent() {
        if (!labelsAndCom.hasOwnProperty(key)) return '';
        // console.log("key:" + key);
        return labelsAndCom[key].component;
    }

    return (
        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <Radio.Group
                defaultValue={key}
                onChange={handleChange}>
                {Object.keys(labelsAndCom).map(key =>
                    <Radio.Button className={"m-1"} key={key}
                                  value={key}>{labelsAndCom[key].title}</Radio.Button>
                )}
                {/*<Radio.Button value="default">Default</Radio.Button>*/}
                {/*<Radio.Button value="small">Small</Radio.Button>*/}
            </Radio.Group>
            <hr/>
            <div>
                {getComponent(key)}
            </div>

        </div>
    )

}
