import {BrowserView, isMobile} from "react-device-detect";
import {limitTxt} from "../util/MyStringUtil";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useQuery} from "react-query";
import AppTemp from "../AppTemp";
import {Alert, Button, message, Popover, Space, Spin} from "antd";
import {Card, CardBody, CardHeader, Media} from "reactstrap";
import {CloudTwoTone, MobileTwoTone} from "@ant-design/icons";
import MyQRCode from "../comp/MyQRCode";
import React from "react";
import {getLinkOpenTarget} from "../util/UrlUtil";

export function PanDetailBase(props) {

    let url = props.url;
    // let source = props.source;
    // let id = props.id;

    // alert(url);
    const [bad, setBad] = React.useState();


    function getSubTitle(item) {
        let subTitle = item.subTitle ? item.subTitle : '';
        let referrer = item.referrer;
        if (isMobile) {
            subTitle = limitTxt(subTitle, 20);
        }

        if (referrer && subTitle) {
            if (item.password) {
                return <CopyToClipboard className="mt-3" onCopy={onCopy}
                                        text={pan.password ? pan.password : ''}>
                    <a href={referrer}
                       target="_blank" rel="nofollow me noopener noreferrer"
                    >{subTitle}</a></CopyToClipboard>;
            }

            return <a href={referrer}
                      target="_blank" rel="nofollow me noopener noreferrer"
            >{subTitle}</a>;
        }
        return subTitle;
    }

    // let targetUrl = getTargetUrl(url);
    let fetchUrl = url;
    // if ("dali" === source) {
    //     fetchUrl = "https://apish.zhidahao.com/pan/subject/dali?key=https://dalipan.com/detail/" + detailId;
    // }


    function fetchDetail() {
        let promise = fetchData(fetchUrl);
        promise.then((result => {
                let ru = result.url;
                // alert(JSON.stringify(result));
                if (ru) {
                    let validUrl = "https://api.zhidahao.com/dupan/valid?url=" + ru;
                    fetch(validUrl)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                // alert(result.bad);
                                setBad(result.bad)
                                // setBad(true);
                            }
                            ,
                            (error) => {
                                console.log("error:" + error)
                            }
                        );
                }


            })
        )


        return promise;


    }

    async function fetchData(url) {
        const response = await fetch(url);
        return await response.json();
    }

    const {
        data: pan,
        status, error
    } = useQuery(
        ['pan', fetchUrl], fetchDetail
    );


    function getTargetUrl(url) {
        if (!isMobile) return null;
        if (!url) return null;
        // console.log("url:" + url);
        // if ("dali" === source) {
        //     return "https://dalipan.com/detail/" + detailId;
        // }
        return null;
    }

    if (status === 'loading') {
        return <AppTemp current={props.current || ''}>
            <div className="text-center mt-3">
                <Spin className={"mr-2 me-2"}/>
                {/*{console.log(url)}*/}
                {getTargetUrl(url) && <Button href={getTargetUrl(url)}
                                              rel="noopener noreferrer"
                                              target={getLinkOpenTarget()}>

                    直接访问
                </Button>
                }
            </div>

        </AppTemp>
    }

    if (status === 'error') {
        return <AppTemp current={props.current || ''}>
            <div className="text-center mt-2" style={{width: '200px'}}>
                <Alert
                    message={error.message}
                    type="error"
                    showIcon
                />
            </div>

        </AppTemp>
    }

    function onCopy() {
        message.info('复制提取码成功');
    }

    return (

        <AppTemp current={props.current || ''}>

            {pan && <div className="p-1 mt-1">
                <Card>
                    <CardHeader>
                        {/*{isMobile && <span className="float-left mr-2"*/}
                        {/*                   style={{*/}
                        {/*                       marginTop: '-0.2rem'*/}
                        {/*                   }}*/}
                        {/*                   onClick={*/}
                        {/*                       () => window.history.back()*/}
                        {/*                   }>*/}
                        {/*       <LeftCircleTwoTone/>*/}
                        {/*</span>}*/}

                        {pan.title && <span>{pan.title}</span>}

                        {isMobile && <Popover className="float-right float-end mr-2 me-2 mt-1"
                                              trigger="click"
                                              content={<MyQRCode/>}
                                              placement="top"
                                              title="手机观看">
                            <MobileTwoTone/>
                        </Popover>
                        }


                    </CardHeader>
                    <CardBody style={{marginTop: '-1.5rem', marginLeft: '-1rem'}}>
                        <Media>
                            <BrowserView>
                                <Media left middle
                                       className="pt-2 cover">
                                    {/*<Img className="cover" src={images}/>*/}

                                    <div
                                        style={{
                                            width: '10rem',
                                            height: '10rem',
                                            marginTop: '1rem',
                                            marginRight: '0.2rem'
                                        }}>
                                        <MyQRCode size={155}/>
                                    </div>
                                </Media>
                            </BrowserView>


                            <Media body className="p-2">
                                {pan.subTitle ? <div>{getSubTitle(pan)}</div> : ""}
                                {pan.size ? <div>文件大小:{pan.size}</div> : ""}
                                {pan.updateTime ? <div>分享时间:{pan.updateTime}</div> : ""}
                                {pan.password ? <div>提取码:
                                    <strong>{pan.password}</strong>
                                </div> : ""}


                                {pan.url &&
                                <Space className="mt-3">
                                    {(pan.password ?
                                        <CopyToClipboard onCopy={onCopy}
                                                         text={pan.password ? pan.password : ''}>
                                            <Button
                                                type="primary"
                                                href={pan.url}
                                                size='large'
                                                icon={<CloudTwoTone/>}
                                                target="_blank" rel="nofollow me noopener noreferrer"
                                            >访问</Button>
                                        </CopyToClipboard> : <Button
                                            type="primary"
                                            href={pan.url}
                                            size='large'
                                            icon={<CloudTwoTone/>}
                                            target="_blank" rel="nofollow me noopener noreferrer"
                                        >访问</Button>)
                                    }


                                    {(404 === pan.code || bad ?
                                        <Alert
                                            style={{width: "200px"}}
                                            message="该资源已失效"
                                            type="warning"
                                            showIcon
                                            closable/>
                                        :
                                        <Alert style={{width: "200px"}}
                                               message="该链接有效,可以访问" type="success"
                                               showIcon/>)
                                    }
                                </Space>
                                }

                            </Media>
                        </Media>
                        <Media>


                        </Media>


                    </CardBody>
                </Card>

                {pan.fileList && pan.fileList.length > 0 &&
                <Card className={"mt-2"}>

                    <CardHeader>
                        <span>目录/文件</span>
                    </CardHeader>
                    <CardBody>
                        {pan.fileList.map((file, index) => (
                            <React.Fragment key={index}>
                                |-<span>{file.name}
                                {file.size && <span>-<strong>{file.size}</strong></span>}</span><br/>
                            </React.Fragment>
                        ))
                        }
                    </CardBody>

                </Card>
                }

            </div>}

        </AppTemp>)
}
