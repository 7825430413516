import {DataTypes} from "./Types";
// import {isHttps} from "../util/UrlUtil";

// let dev = false;
// let dev = false;
// if (process.env.NODE_ENV !== 'production') {
//     dev = true;
// }

// let hostname;
// let port;
// let protocol = isHttps() ? "https" : "http";
// let cdn = false;
// let hostUrlAndPort;
let host = window.location.host;
// let myhost = "www.fuwuhao.com"
// let myhost = "www.zhidahao.com"
// if (host && host.indexOf(".com") !== -1) dev = false;


if (host.indexOf("tcloudbaseapp") !== -1) {
    host = "www.fuwuhao.com"
}

let musicUrl = "//music.zhidahao.com"
let hostUrl = process.env.REACT_APP_API;
// if (dev) {
//     // protocol = "http";
//     hostname = "192.168.31.184";
//     port = 8080;
//     hostUrl = `${protocol}://${hostname}:${port === 80 ? '' : port}`;
//
//     musicUrl = hostUrl;
//     console.log(musicUrl);
//
//     // hostUrlAndPort = hostUrl;
// } else {
//     // if (host.indexOf(".com") === -1) {
//     //     host = "www.fuwuhao.com";
//     // }
//     //    host = "api.zhidahao.com";
//     if (host.indexOf("zhidahao.com") !== -1) {
//         host = "api.zhidahao.com";
//     }
//
//     hostname = cdn ? "zdh.cdnjs.cn" : host;
//     // let hostUrlNotPort = `${protocol}://${hostname}`;
//     port = 80;
//     // let portStr = `${port === 80 ? '' : ':' + port}`;
//
//     // hostUrlAndPort = hostUrlNotPort + portStr;
//     // if (cdn) {
//     //
//     //     hostUrl = hostUrlNotPort + portStr;
//     // } else {
//     //     if (hostname.indexOf("zhidahao.com") !== -1) {
//     //         // hostUrl = protocol + "://" + host.replace("www.", "api.")
//     //
//     //     } else {
//     //         hostUrl = hostUrlNotPort + portStr + "/api";
//     //         if (hostname.indexOf(".tcloudbase.com") !== -1) {
//     //             hostUrl = "//api.zhidahao.com";
//     //         }
//     //     }
//     // }
//     // hostUrl = cdn ? hostUrlNotPort + portStr :
//     //     hostname.indexOf("zhidahao.com") !== -1 ? protocol + "://" + host.replace("www.", "api.") : hostUrlNotPort + portStr + "/api";
//
// }
// alert(hostUrlAndPort)
// alert(hostUrl)

// let profile = false;
// if (host.indexOf("192.168") !== -1 || host.indexOf('127.0.0.1') !== -1 || host.indexOf('localhost') !== -1) {
//     profile = true;
// }
//
// export const Profiles = profile;

export const RestUrls = {
    [DataTypes.API_SH]: "https://apish.zhidahao.com",
    [DataTypes.TOOL_URL]: `${hostUrl}/tool`,
    [DataTypes.MUSIC_SERVER]: `${musicUrl}`,
    [DataTypes.HOST_URL]: `${hostUrl}`,
    [DataTypes.PROJECTS]: `${hostUrl}/projects`,
    [DataTypes.VIDEO_NO_PIC]: '//imgcdn.zhidahao.com/video/tv_default_large.png',
    [DataTypes.VIDEO_PROXY]: `${hostUrl}/videos?url=`,
    [DataTypes.VIDEO_SUGGESTS]: `${hostUrl}/video/suggest`,
    [DataTypes.VIDEO_DETAIL]: `${hostUrl}/video/data`,
    [DataTypes.VIDEO_INDEX_DATA]: `${hostUrl}/video/index/data`,
    [DataTypes.VIDEO_SLIDE_DATA]: `${hostUrl}/video/slide`,
    [DataTypes.VIDEO_LC_PLAY]: `${hostUrl}/video/play/lc/`,
    [DataTypes.VIDEO_BD_PLAY_URL]: `${hostUrl}/video/play/bd/`,
    [DataTypes.VIDEO_618]: `${hostUrl}/video/618`,
    [DataTypes.VIDEO_M3U8]: `${hostUrl}/video/m3u8`,
    [DataTypes.BOOK_KEYWORD_PAGINATION]: `${hostUrl}/book`,
    [DataTypes.BOOK_BUY]: `${hostUrl}/book/buy`,

    [DataTypes.BOOK_DIRECT]: `${hostUrl}/book/direct`,

    [DataTypes.BOOK_ZLIB_DOWNLOAD]: `${hostUrl}/book/dl/zlib`,


    [DataTypes.SITE_KEYWORD_PAGINATION]: `${hostUrl}/site`,
    [DataTypes.SITE_DIRECT]: `${hostUrl}/site/direct`,
    [DataTypes.TAOBAO_KEYWORD_PAGINATION]: `${hostUrl}/taobao`,
    [DataTypes.TAOBAO_MATERIAL_KEYWORD_PAGINATION]: `${hostUrl}/taobao/material`,
    [DataTypes.TAO_AD_KEYWORD_PAGINATION]: `${hostUrl}/taobao/ad`,
    [DataTypes.SUB_KEYWORD_PAGINATION]: `${hostUrl}/sub`,
    [DataTypes.SUB_DOWNLOAD]: `${hostUrl}/sub/download`,


    [DataTypes.PAN_SEARCH]: `${hostUrl}/pan/search`,
    [DataTypes.PAN_URL]: `${hostUrl}/pan/subject`,
    [DataTypes.PAN_GYHJ]: `${hostUrl}/pan/gyhj`,


    // [DataTypes.TAOBAO_DIRECT]: `${hostUrl}/taobao/direct`,
};
