import React from "react";
import {isMobile} from "react-device-detect";
import axios from "axios";
import {getQrCodeUrl} from "../util/UrlUtil";
import {HelmetProvider} from "react-helmet-async";
import {Helmet} from "react-helmet";
// import DPlayer from 'dplayer';
import {Button, CardHeader, Col, Row} from "reactstrap";
import {loadVideoDetailLocalData} from "../data/ActionCreators";
import {DataTypes} from "../data/Types";
import {VideoLikeOther} from "./VideoLikeOther";
import AppTemp from "../AppTemp";
// import {RestUrls} from "../data/Urls";
import {Popover} from "antd";
import {LeftCircleTwoTone, MobileTwoTone} from '@ant-design/icons';
import ReactPlayer from "react-player";
import MyQRCode from "../comp/MyQRCode";
// import {ProjectConstant} from "../data/ProjectConstant";
import {getProjectTitle} from "../util/ProjectUtil";

import {useLocation} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function IframePlayer(props) {

    const prefixUrls = [
        "http://vip.ai675.com/lines?url="
        , "https://jx.618g.com/?url="
        , "http://yun.360dy.wang/?url="
        , "https://api.lhh.la/vip/?url="
        , "https://api.tv920.com/vip/?url=",
        "https://api.tv920.com/api/jiexila/?url="
    ];

    let query = useQuery();
    let su = query.get("url");
    let n = query.get("title");
    if (!n) n = "";
    let parseCat = query.get("cat");
    let index = query.get("index");
    const t = index ? " " + index : "";
    let playPrefix =
        isMobile ? prefixUrls[0] :
            prefixUrls[4];

    const localDetailData = loadVideoDetailLocalData(DataTypes.VIDEO_LOCAL_DETAIL);
    const videoDetail = localDetailData && localDetailData.payload.data;
    const parsePlayerMap = videoDetail && videoDetail.parsePlayerMap;
    const likes = videoDetail && videoDetail.video_rec;
    const parseItems = parsePlayerMap && parseCat && parsePlayerMap[parseCat];
    const sourceItem = parseItems && parseItems.find(item => item.url === su);
    let playList;
    if (parseItems && parseItems.length > 0) {
        playList = parseItems;
    }

    const [sourceUrl, setSourceUrl] = React.useState(su);
    const [playUrl, setPlayUrl] = React.useState(playPrefix + su);
    // const [playPrefix, setPlayPrefix] = React.useState(pre);
    // const [parseCat, setParseCat] = React.useState(pc);
    const [finalUrl, setFinalUrl] = React.useState();
    const [qrUrl, setQrUrl] = React.useState(window.location.href);
    const [title, setTitle] = React.useState(n + " " + t);
    // const [playList, setPlayList] = React.useState(parseItems);

    const parseUrl = (item) => {
        let sourceUrl = item.url;
        if (!sourceUrl) return;
        setSourceUrl(sourceUrl);
        setTitle(n + " " + item.title)
        let url618 = "https://apish.zhidahao.com/video/618";
        sourceUrl && axios.get(url618, {
            params: {
                url: sourceUrl
            }
        })
            .then(res => {
                let rd = res.data;
                if (rd && rd.url) {
                    setPlayUrl(rd.url);
                    let finalUrl = rd.finalUrl;
                    setFinalUrl(finalUrl);
                    if (finalUrl) {
                        initPlayer(finalUrl);
                    }
                }
            });
    };


    React.useEffect(() => {
        if (isMobile) {
            parseUrl({sourceUrl: sourceUrl, title: title})
        }
    });


    function getNextItem() {
        if (!sourceUrl || !playList || playList.length === 0) return null;
        let idx = playList.map(function (item) {
            return item.url;
        }).indexOf(sourceUrl);
        if (idx >= 0 && idx <= playList.length - 2) {
            return playList[idx + 1]; // return whatever comes next to item
        }
        return null;
    }

    function handleEnded() {
        // console.log('onEnded');
        let nextItem = getNextItem();
        // console.log("nextSource:" + nextSource);
        nextItem && parseUrl(nextItem)
    }


    const initPlayer = finalUrl => {
        // console.log(finalUrl);
        finalUrl && setFinalUrl(finalUrl);
    };
    const goLink = item => {
        let title = n + " " + item.title;
        let url = item.url;
        let param = {
            title,
            url,
            cat: parseCat

        };
        let qrUrl = getQrCodeUrl(param);
        // let qrUrl = getHostAndPath() + "?url=" + item.url;
        if (isMobile) {
            parseUrl(item);
        } else {
            setPlayUrl(playPrefix + url)
            setSourceUrl(url);
            setQrUrl(qrUrl);
            setTitle(title);
        }
    };

    let show1 = 7;
    let show2 = 5;
    return <AppTemp current={props.current || ''}>
        <HelmetProvider>
            <Helmet>
                <title>{getProjectTitle() + "-" + title}</title>
            </Helmet>
        </HelmetProvider>
        <div className="mt-1">
            <CardHeader>
        <span className="float-left mr-2"
              style={{
                  marginTop: '-0.2rem'
              }
              }
              onClick={() => window.open("/video?from=player", "_self")}
        >
        <LeftCircleTwoTone/>
            {/*<LeftCircle type="left-circle" theme="twoTone"/>*/}
        </span>
                <span>{title}</span>
                {/*<BrowserView>*/}
                <Popover className="float-right mr-2 mt-1"
                         content={<MyQRCode value={qrUrl}/>}
                         placement="top"
                         trigger={isMobile ? 'hover' : 'click'}
                         title="手机观看">
                    {/*<Icon type="mobile" theme="twoTone"/>*/}
                    <MobileTwoTone/>
                </Popover>
                {/*</BrowserView>*/}

            </CardHeader>
            {
                <Row>
                    <Col xs="12" sm={show1} md={show1}>
                        {isMobile ?
                            <ReactPlayer
                                url={finalUrl}
                                onEnded={handleEnded}
                                playing={true}
                                controls={true}
                                pip={true}
                                width='100%'
                                height='100%'
                            />
                            :

                            <iframe title={title}
                                    src={playUrl}
                                    width='100%' height={isMobile ? '210' : '420'}
                                    allowFullScreen=" allowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen=" oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"
                            />

                        }

                    </Col>

                    {sourceItem && parseItems && parseItems &&
                    <Col xs="0" sm={show2} md={show2}>
                        <div className="p-1">
                            <h5>播放列表</h5>
                            {parseItems.map(play =>
                                <Button outline color="info" className="m-1"
                                        key={`${play.url}`}
                                        active={play.url === sourceUrl}
                                        onClick={
                                            goLink.bind(this, play)
                                        }

                                >{play.title}</Button>
                            )}

                        </div>

                    </Col>
                    }
                </Row>
            }
        </div>

        {likes && likes.length && <VideoLikeOther likes={likes}/>}
    </AppTemp>


}


