import {isMobile} from "react-device-detect";

export function updateQueryStringParameter(uri, key, value) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        return uri + separator + key + "=" + value;
    }
}

export function isHttps() {
    return (document.location.protocol === 'https:');
}

export function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function startsWith(str, word) {
    return str.lastIndexOf(word, 0) === 0;
}


export function isTorrent(url) {
    return endsWith(url, ".torrent");
}


export function isMp4(url) {
    if (!isUrl(url)) return false;
    return endsWith(url, ".mp4");
}


export function isHls(url) {
    if (!isUrl(url)) return false;
    return endsWith(url, ".m3u8");
}


export function isMpd(url) {
    if (!isUrl(url)) return false;
    return endsWith(url, ".mpd");
}

export function isFlv(url) {
    // if (!isUrl(url)) return false;

    return endsWith(url, ".flv") || startsWith(url, "rtmp://");

}

export function canPlayUrl(url) {
    return isMp4(url) || isHls(url) || isFlv(url) || isMpd(url);
}


export function isMp4OrHls(url) {
    return isMp4(url) || isHls(url);
}


export function isMagnet(url) {
    return startsWith(url, "magnet:?")
}


export function isMagnetOrTorrent(url) {
    if (!url) return false;
    return isTorrent(url) || isMagnet(url);
}


export function isUrl(input) {
    if (!input) return false;
    // var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
    let regex = new RegExp(expression);
    return input.match(regex);
}


export function getHostAndPath() {
    return window.location.protocol + '//' + window.location.host + window.location.pathname;
}


export function getQrCodeUrl(params) {
    let urlParameters = getAsUriParameters(params);
    let path = window.location.protocol + '//' + window.location.host + window.location.pathname;
// console.log("urlParameters:" + urlParameters);
    return urlParameters ? path + "?" + urlParameters : path;
}


export function getLinkOpenTarget() {
    return isMobile ? "_self" : "_blank"
}

export function getAsUriParameters(data) {
    let result = '';
    for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
            let v = data[prop];
            if (v) {
                result += encodeURIComponent(prop) + '=' +
                    encodeURIComponent(v) + '&';
            }
        }
    }
    return result.substring(0, result.length - 1)
}

// export function getAsUriParameters(json) {
//
//     let result = '' +
//         Object.keys(json).map(function (key) {
//             let v = json[key];
//             // if (v === undefined || v === null) return '';
//             if(v!==undefined&&v!==null){
//             return encodeURIComponent(key) + '=' +
//                 encodeURIComponent(v);
//             }
//         }).join('&');
//
//     let len = Object.keys(json).length;
//     console.log(JSON.stringify(json))
//     console.log("len:"+len)
//     if (len === 1) {
//         result.substr(1);
//     }
//     return result;
//
//
// }


export function getUrlParameterInPage(name) {
    return getUrlParameter(window.location.href, name);
}


export function getUrlParameter(input, name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(input);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
