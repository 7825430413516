import React from "react";



function IcpFooter() {

    return (
        <a className={"ml-1"} href="https://beian.miit.gov.cn" rel="nofollow noopener noreferrer"
           target="_blank">粤ICP备20049573号</a>
    )
}

export default IcpFooter;
