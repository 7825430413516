const key = "sh_ip";

export function getIpInfo() {
    let shIp = localStorage.getItem(key)
    return shIp ? JSON.parse(shIp) : null;
}

export function setIpInfo(item) {
    item && localStorage.setItem(key, JSON.stringify(item));
}

export function getIpAddress() {
    let ipInfo =  getIpInfo();
    return ipInfo ? ipInfo['cip']:null;
}