import React
// {useState}
    from 'react'
import fetch from '../libs/fetch'

// import {useQuery} from 'react-query'
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {Button, ListGroup, ListGroupItem} from 'reactstrap';
import {
    isMobile
} from "react-device-detect";
import {useInfiniteQuery} from 'react-query'

export const SitePageLoadMore = (props) => {

    let keyword = props.keyword;
    // const [nextId] = useState(0);
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingMore,
        fetchMore,
        canFetchMore,
    } = useInfiniteQuery(
        'site_keyword' + keyword,
        (key, cursor = 0) => fetch(RestUrls[DataTypes.SITE_KEYWORD_PAGINATION]
            + "?nextId=" + cursor + "&keyword=" + encodeURIComponent(keyword) + "&pageSize=10"),
        {
            getFetchMore: (lastGroup, allGroups) => lastGroup.nextId,
        }
    );


    // let flag = data && data.length > 0;
    // // console.log(data);
    // flag = flag && (data[0].error !== null && data[0].data !== null);
    // // console.log("flag:"+flag);

    return (
        <div>
            {status === 'loading' ? (
                <p>Loading...</p>
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (
                <>
                    {/*console.log(JSON.stringify(data)*/}
                    {data !== null && data !== undefined && data.map((page, i) => (
                        <ListGroup key={i}>
                            {page.data && page.data.map(item => (
                                <ListGroupItem

                                    style={{background: 'rgba(255,255,255,0)'}}
                                    key={item.id} tag="a" target={isMobile ? "_self" : "_blank"}
                                               href={isMobile ? (item.mobileUrl ? item.mobileUrl : item.pcUrl) : item.pcUrl}>{item.name}
                                    {/*{book.year?book.year:''}*/}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    ))}
                    <div className="text-center mt-2">
                        <div >
                            <Button

                                onClick={() => fetchMore()}
                                disabled={!canFetchMore || isFetchingMore}
                                color={(!canFetchMore || isFetchingMore) ? 'secondary' : (canFetchMore ? 'primary' : 'secondary')}
                            >
                                {isFetchingMore
                                    ? '加载中'
                                    : canFetchMore
                                        ? '加载更多'
                                        : '到底了'}
                            </Button>
                        </div>

                        <div>
                            {isFetching && !isFetchingMore ? '正在获取数据' : null}
                        </div>
                    </div>
                </>)
            }
        </div>
    )
};
