import {Button, InputGroup
    // , InputGroupAddon

} from "reactstrap";
import Autosuggest from "react-autosuggest";
import React, {Component} from "react";
import "../suggest.css"
import {HideKeyboard} from '../util/HideKeyboard'
import {
    // MobileView,
    // isBrowser,
    isMobile
} from "react-device-detect";
import {DataTypes} from "../data/Types";
import {RestDataSource} from "../data/RestDataSource";
import {escapeRegexCharacters} from "../util/MyStringUtil"
import {FaTimes} from 'react-icons/fa';
import {isUrl} from "../util/UrlUtil";

let directQuery = '';

export class Suggests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: [],
        };

        this.dataSource = new RestDataSource();
        this.handleClearClick = this.handleClearClick.bind(this);
    }


    onSuggestionsFetchRequested = ({value}) => {
        // console.log("onSuggestionsFetchRequested" + value);
        if (value && !isUrl(value)) this.loadSuggestions(value);

    };
    onSuggestionSelected = (event, {


        suggestion,
        // suggestionValue, suggestionIndex, sectionIndex, method
    }) => {
        // event.persist();
        // event.stopPropagation();
        // event.preventDefault();
        // console.log("onSuggestionSelected directQuery"+this.state.directQuery);
        // console.log("onSuggestionSelected name"+suggestion.name);
        if (suggestion && directQuery !== suggestion.name) {
            this.props.getDirectData({
                title: suggestion.value, filmId: suggestion.filmId,
                year: suggestion.year,
                actor: suggestion.actor
            })
        }
    };

    onChange = (event, {newValue}) => {
        // if (newValue !== this.state.value) {
        this.setState({
            value: newValue
        });
        // }

    };


    loadSuggestions(value) {
        // let value = this.state.value;
        // console.log("loadSuggestions:" + value);
        if (!value) return;
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === '') {
            return {};
        }


        let params = {
            q: value,
            limit: 12
        };
        // console.log("value:" + value);
        let that = this;
        this.dataSource.GetData(DataTypes.VIDEO_SUGGESTS, params).then(response => {
            if (response == null || response.data == null || response.data.suggestions == null) {
                that.setState({
                    suggestions: []
                });
            } else {
                if (response.data.suggestions) {
                    that.setState({
                        isLoading: false,
                        suggestions: response.data.suggestions
                    });
                }
            }

        })

    }

    getSuggestionValue = suggestion => {
        let suggestName = suggestion.name;
        // console.log("getSuggestionValue:" + suggestName);
        // this.setState({directQuery:suggestName});
        directQuery = suggestName;
        if (suggestName && !isUrl(suggestName)) {
            this.props.getDirectData({
                title: suggestion.value,
                filmId: suggestion.filmId,
                year: suggestion.year,
                actor: suggestion.actor
            });
        }
        return suggestName;
    };

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
    }

    componentWillUnmount() {
        // document.removeEventListener("click", this._handleDocumentClick, false);
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown = (event) => {
        // console.log("keycode:" + event.keyCode);
        switch (event.keyCode) {
            // case ESCAPE_KEY:
            //     this.state.activePopover.hide();
            //     break;
            // case 38:
            //     this.getDirectData(this.state.dirctQuery);
            //     break;
            case 13:
                if (this.state.value) {
                    this.props.getDirectData({title: this.state.value});
                    HideKeyboard();
                }

                break;
            default:
                break;
        }
    };


    onSuggestionsClearRequested = () => {
        if (!this.state.value) {
            // console.log("onSuggestionsClearRequested:"+this.state.value);
            this.setState({
                suggestions: []
            });
            this.props.backNewVideo();
        }

    };

    handleClearClick() {
        this.setState({
            value: '',
        });
        // console.log("handleClearClick:"+this.state.value);
        this.props.backNewVideo();
    }

    renderSuggestion = suggestion => (
        <div>
            {suggestion.value}
        </div>
    );

    directCallback = () => {
        let directQuery = this.state.value;
        if (directQuery) {
            // if (isUrl(directQuery)) {
            //     window.open("/video/mvmIframe?title=video&url=" + directQuery);
            //
            // } else {
            this.props.getDirectData({title: directQuery});
            // }

        }

    };


    render() {

        const inputProps = {
            placeholder: '关键字 imdb',
            value: this.state.value,
            onChange: this.onChange,
            autoFocus: true,
            type: 'search'
        };
        let value = this.state.value;
        let clearButton;
        if (value && value.length >= 1) {
            clearButton = (
                <button className="btn bg-transparent" style={{marginLeft: "-50px", zIndex: "100"}}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.handleClearClick();
                        }
                        }
                >
                    <FaTimes/>
                </button>
            );
        }

        return (<InputGroup className="justify-content-center mt-2 mb-2">
                <Autosuggest
                    // multiSection={true}
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={
                        !isMobile
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    // theme={suggestTheme}
                />
                {clearButton}
                {/*<InputGroupAddon addonType="append">*/}
                    <Button color="success" onClick={this.directCallback}>{this.props.loadingText}</Button>
                {/*</InputGroupAddon>*/}
            </InputGroup>
        )
    }
}
