import React from 'react';
import AppTemp from "../AppTemp";
import {SitePageLoadMore} from './SitePageLoadMore';
import {Input} from 'antd';
import {getLinkOpenTarget, getUrlParameterInPage, isUrl} from "../util/UrlUtil";
import validator from 'validator';
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {isMobile} from "react-device-detect";
import {SearchModel} from "../comp/SearchModel";

const {Search} = Input;

export function Site(props) {



    let error = getUrlParameterInPage("error");
    let redirectValue = getUrlParameterInPage("value");

    // const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [value, setValue] = React.useState(redirectValue || '');
    const [keyword, setKeyword] = React.useState('');
    const [visible, setVisible] = React.useState(!!error || false);


    function onChange(e) {
        let keyword = e.target.value;
        setKeyword(keyword);
    }

    function onSearch(value) {
        value = value && value.replace("。", ".");
        if (!value) {
            return;
        }
        if (value) {
            if (isUrl(value) || validator.isFQDN(value)) {
                let url = value.indexOf("://") === -1 ? "http://" + value : value;
                window.open(url, getLinkOpenTarget());
                return;
            }
            setValue(value)
            window.open(RestUrls[DataTypes.SITE_DIRECT] + "?isMobile=" + isMobile + "&keyword=" + encodeURIComponent(value), getLinkOpenTarget());

        }
    }


    function handleCancel() {
        setVisible(false)
    }


    return (
        <AppTemp current={props.current}>
            <div className="container mt-3">
                <Search
                    placeholder="网站名、网址"
                    enterButton="直达"
                    size="large"
                    allowClear
                    autoFocus
                    onChange={onChange}
                    onSearch={onSearch}
                    // ref={this.onSearchInput}
                />
            </div>

            {value &&
            <SearchModel handleCancel={handleCancel} keyword={value} visible={visible}/>
            }
            <div className="container mt-2">
                {/*<PageLoadMore/>*/}
                <SitePageLoadMore keyword={keyword}/>
            </div>
        </AppTemp>


    )

}
