import React from "react";
import {
    useLocation
} from "react-router-dom";
import {PlayIframe} from "./PlayIframe";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function MvmIframe() {
    let query = useQuery();
    let qu = query.get("url");
    // if (qu.indexOf("baidu.com") !== -1) {
    //     qu = qu.replace("baidu.com", "mvm.link");
    // }
    // alert(qu);
    let url = "//z1.m1907.cn/?jx=" + qu;
    let title = query.get("title");
    let id = query.get("id");
    // console.log(url)
    // console.log(title)
    // let margin = isMobile ? '-3rem' : '0rem';
    return <PlayIframe title={title} id={id} url={url}/>
}
