import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useQuery} from "react-query";
import fetch from "../libs/fetch";
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
// import {Card, CardBody, CardImg, CardSubtitle, CardText, Col, Row} from "reactstrap";
import React from "react";
import {Carousel} from "react-responsive-carousel";
import {BrowserView, isBrowser} from "react-device-detect";


export function MovieSliderHook(props) {
    const {data, isLoading, isFetching} = useQuery(DataTypes.VIDEO_SLIDE_DATA, (() => fetch(RestUrls[DataTypes.VIDEO_SLIDE_DATA]))
        ,
        {
            staleTime: 2 * 60 * 1000,
            cacheTime: 5 * 60 * 1000
        }
    );
    let load = "";
    let refresh = "";
    const clickItem = index => {

        let ci = data[index];
        // console.log("come here:"+JSON.stringify(ci));
        let item ={filmId:ci.filmId,title:ci.alt}
        props.directQuery(item);
    };
    // let flag = data && data.length > 0;
    // flag = flag && data[0].status ? data[0].status === 200 : flag;
    // console.info(JSON.stringify(data));
    // console.log(flag);

    // function getWidth(item, index) {
    //     let width = '1920px';
    //     let u = item.href;
    //     if (u.indexOf("/tv/") && index < 3) {
    //         width = '480px'
    //     }
    //     return width;
    // }
    //
    // function getStyle(item, index) {
    //
    //     return {height: '480px', width: getWidth(item, index)};
    // }


    return (<div>{isLoading ? (
        <span>{load}</span>
    ) : data ? (data.error) ? (<span>{data.error}</span>) : (
        <>
            {isFetching ?
                <div>{refresh}</div> : null
            }

            <Carousel autoPlay showIndicators={isBrowser} infiniteLoop={true}
                      // dynamicHeight={true}
                      // width='800px'
                // centerMode={true}

                      onClickItem={clickItem}
            >
                {
                    data && data.length > 0 && data.map((item) =>
                        <div key={item.src}
                             // style={{height:"300px",width:"80%"}}
                        >
                            <img
                                // style={getStyle(item,)}
                                src={item.src} alt={item.alt}/>
                            <BrowserView><p className="legend">{item.caption}</p></BrowserView>

                        </div>
                    )
                }
            </Carousel>
        </>
    ) : null}
    </div>)
}
