import React from "react";
import MyReactPlayer from "./MyReactPlayer";
import './TvLive.css'

export class TvLiveTest extends React.Component {
    render() {
        return (
            <div >
                <MyReactPlayer/>

            </div>
        )
    }
}