import React from "react";
import {
    useLocation
} from "react-router-dom";
import AppTemp from "../AppTemp";
import {Helmet} from "react-helmet";
import {ProjectConstant} from "../data/ProjectConstant";
import {HelmetProvider} from "react-helmet-async";
// import {isHttps} from "../util/UrlUtil";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function SubDownloadPage(props) {
    let query = useQuery();
    let url = query.get("url");
    let title = query.get("title");
    if (url) {
        let li = url.indexOf("//");
        if (li !== -1) {
            url = url.substring(li);
        }
    }
    // alert(url);
    // if(url){
    //    let bo= url.indexOf('https')===-1
    //     if(isHttps()&&bo){
    //         url.replace("http://","https://");
    //     }
    // }
    //
    return <AppTemp current={props.current || ''}>
        <HelmetProvider>
            <Helmet>
                <title>{ProjectConstant.PROJECT_NAME}-{title}</title>
            </Helmet>
        </HelmetProvider>
        <iframe title={title} width="100%" height="500" src={url}/>
    </AppTemp>

}
