import {isMobile} from "react-device-detect";
import React, {useState} from "react";
import {DataTypes} from "../data/Types";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import AppTemp from "../AppTemp";
import {RestUrls} from "../data/Urls";
import Img from 'react-image'
import axios from 'axios';
import {Button, Col, message, Popover, Row, Tabs, Modal} from 'antd';


import {taobaoLink} from '../util/TaobaoUtil'
import ReadMore from "../comp/ReamMore";
// import {Icon} from 'antd';
import {DownloadOutlined, LeftCircleTwoTone, MobileTwoTone} from '@ant-design/icons';

// import QRCode from 'qrcode.react';
// import {getHostAndPath} from "../util/UrlUtil";
import MyQRCode from "../comp/MyQRCode";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import {isHttps} from "../util/UrlUtil";
// import {useMount} from "ahooks";

const ButtonGroup = Button.Group;
const {TabPane} = Tabs;

function callback(key) {
    console.log(key);
}

export function BookDetail(props) {

    let {id} = useParams();

    const bookHistoryUrl = localStorage.getItem("book_historyUrl");

    const [buy, setBuy] = useState();
    // const [loading, setLoading] = useState(false);
    const [iconLoading, setIconLoading] = useState(false);
    const [downloadFlag, setDownloadFlag] = useState(false);
    const [zlibDownloadUrl, setZlibDownloadUrl] = useState();
    const [realDownloadFlag, setRealDownloadFlag] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    async function fetchData(url) {
        const response = await fetch(url);
        const book = await response.json();
        // console.log(book);
        let bookBuy = RestUrls[DataTypes.BOOK_BUY];
        book && book.title && axios.get(bookBuy + '?keyword=' + encodeURIComponent(book.title))
            .then(function (response) {
                response && setBuy(response.data);
                // console.log(response);
            });

        return book;
    }

    function fetchBookDetail() {
        let url = RestUrls[DataTypes.BOOK_KEYWORD_PAGINATION] + "/subject/" + id;
        return fetchData(url);
    }

    // useMount(() => {
    //     // message.info('mount');
    //     // window.history.pushState(null, '', "/book");
    //
    //
    //
    //
    // });

    const {
        data: book
        // , isLoading, error, refetch, isFetching
    } = useQuery(
        ['book', {id}], fetchBookDetail
    );


    function getZlibDownloadName(title, format) {
        return format ? title + "." + format.toLowerCase() : null;
    }

    function thuderDownload(download, format) {

        let downloadName = format ? book.title + "." + format.toLowerCase() : null;
        // console.log("downloadName:"+downloadName);
        let task = {
            url: download
        };
        if (downloadName) {
            task.name = downloadName;
        }
        // eslint-disable-next-line no-undef
        thunderLink.newTask({
            downloadDir: '', // 指定当前任务的下载目录，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。若不填此项，会下载到用户默认下载目录
            tasks: [
                //     {
                //     name: downloadName, // 指定下载文件名（含扩展名）。若不填此项，将根据下载 URL 自动获取文件名
                //     url: download // 指定下载地址
                // }
                task
            ]
        });
    }


    // eslint-disable-next-line no-undef
    thunderLink();


    function shortString(act) {
        if (!act) return null;
        let maxLen = 50;
        if (isMobile) maxLen = 15;

        return act.length > maxLen ? act.substring(0, maxLen - 3) : act;
    }

    const limitKeys = ["author", "publisher"];


    let images;
    if (book) {


        // book.hasOwnProperty()
        for (const key of Object.keys(book)) {
            let val = book[key];
            if (typeof val === 'string' || val instanceof String) {
                // console.log(key);
                if (limitKeys.includes(key)) {
                    book[key] = shortString(val);
                }

                // if (val.indexOf("http") !== -1) {
                //     book[key] = val.substr(0, 15);
                // }


            }
            // if(val)
            // use val
        }


        let cdnImg = book.cdnImg;
        let newCdnImg;
        if (cdnImg) {
            newCdnImg = cdnImg.replace("//cdnjs.cn/img/", "//imgcdn.zhidahao.com/");
        } else {
            newCdnImg = book.image;
        }
        // console.log(newCdnImg)
        images = [newCdnImg, '//imgcdn.zhidahao.com/book/book-default.gif'];
    }


    function zlibDownload(event) {
        event.preventDefault();
        event.stopPropagation();
        setIconLoading(true);
        setDownloadFlag(false);
        let id = book.id;
        let url = RestUrls[DataTypes.BOOK_ZLIB_DOWNLOAD] + "/" + id;
        id && axios.get(url)
            .then(function (response) {
                // console.log(JSON.stringify(response));
                if (response && response.data) {
                    let dl = response.data.url;
                    let flag = response.data.real;
                    if (dl) {
                        // dl = isHttps() ? dl.replace("http://", "https://") : dl;
                        // window.location.href = dl;
                        setDownloadFlag(true);
                        setZlibDownloadUrl(dl);

                    }
                    setRealDownloadFlag(flag)
                }

                if (!response || !response.data) {
                    message.warning("获取数据失败");
                } else {
                    if (!response.data.url) {
                        message.warning("没找到可用的下载链接");
                    }
                }
                setIconLoading(false);


                // setLoading(false);
                // console.log(response);
            }).catch(() => {
                setIconLoading(false);
                message.warning('网络错误');
            });
    }


    return (

        <AppTemp current={props.current || ''}>
            {book &&


            <div className="p-1 mt-1">


                {/*<div className="card">*/}
                <div className="card-header">
                    {isMobile &&
                    <span className="float-left float-start mr-2 me-2"
                          style={{
                              marginTop: '-0.2rem'
                          }}
                          onClick={() =>

                              // window.history.back(-1)
                              window.open(bookHistoryUrl ? bookHistoryUrl : "/book", "_self")

                          }>
                               <LeftCircleTwoTone/>
                        </span>
                    }
                    <span>{book.title}</span>

                    <Popover className="float-right float-end mr-2 me-2 mt-1"
                             trigger="click"
                             content={<MyQRCode/>}
                             placement="top"
                             title="手机观看">
                        <MobileTwoTone/>
                    </Popover>

                </div>
                <div className="card mb-3"

                    // style={{"max-width": "540px"}}

                >

                    {/*<div className={"card-body"}>*/}
                    <Row>
                        <Col flex="160px"> <Img
                            // style={{width: 180, height: 250}}
                            //  class="img-fluid rounded-start"
                            className="cover"
                            src={images} alt={book.title}/></Col>
                        <Col flex="auto">
                            <div className="m-3">
                                {book.enTitle ? <div>原名:{book.enTitle}</div> : ""}
                                {book.subTitle ? <div>副标题:{book.subTitle}</div> : ""}
                                {book.author ? <div>作者:{book.author}</div> : ""}
                                {book.rating ? <div>评分:{book.rating}</div> : ""}
                                {book.pubDate ? <div>日期:{book.pubDate}</div> : book.year ?
                                    <div>年份:{book.year}</div> : ""}
                                {book.pages ? <div>页数:{book.pages}</div> : ""}
                                {book.translator ? <div>译者:{book.translator}</div> : ""}
                                {book.series ? <div>丛书:{book.series}</div> : ""}
                                {book.area ? <div>地区:{book.area}</div> : ""}
                                {book.publisher ? <div>出版:{book.publisher}</div> : book.producer ?
                                    <div>出品:{book.producer}</div> : ""}
                                {/*{book.producer ? <div>出品:{book.producer}</div> : ""}*/}
                                {book.framing ? <div>装订:{book.framing}</div> : ""}
                                {book.size ? <div>大小:{book.size}</div> : ""}
                                {book.language ? <div>语言:{book.language}</div> : ""}
                                {book.format ? <div>格式:{book.format}</div> : ""}
                                {book.isbn ? <div>ISBN:{book.isbn}</div> : ""}
                                {/*{book.prince ? <div>价格:{book.prince}</div> : ""}*/}
                                {book.ebookLink ?
                                    <div>电子书:<a target="_blank" rel="noopener noreferrer"
                                                href={book.ebookLink}>试读</a>
                                    </div> : ""}

                            </div>
                        </Col>
                    </Row>

                    {/*</div>*/}


                    {/*<div className="row g-0">*/}
                    {/*    <div className="col-md-2">*/}
                    {/*        <Img className="img-fluid" src={images}/>*/}

                    {/*    </div>*/}
                    {/*    <div className="col-md-10 ">*/}
                    {/*        <div className="card-body">*/}
                    {/*            {book.enTitle ? <div>原名:{book.enTitle}</div> : ""}*/}
                    {/*            {book.subTitle ? <div>副标题:{book.subTitle}</div> : ""}*/}
                    {/*            {book.author ? <div>作者:{book.author}</div> : ""}*/}
                    {/*            {book.rating ? <div>评分:{book.rating}</div> : ""}*/}
                    {/*            {book.pubDate ? <div>日期:{book.pubDate}</div> : book.year ?*/}
                    {/*                <div>年份:{book.year}</div> : ""}*/}
                    {/*            {book.pages ? <div>页数:{book.pages}</div> : ""}*/}
                    {/*            {book.translator ? <div>译者:{book.translator}</div> : ""}*/}
                    {/*            {book.series ? <div>丛书:{book.series}</div> : ""}*/}
                    {/*            {book.area ? <div>地区:{book.area}</div> : ""}*/}
                    {/*            {book.publisher ? <div>出版:{book.publisher}</div> : book.producer ?*/}
                    {/*                <div>出品:{book.producer}</div> : ""}*/}
                    {/*            /!*{book.producer ? <div>出品:{book.producer}</div> : ""}*!/*/}
                    {/*            {book.framing ? <div>装订:{book.framing}</div> : ""}*/}
                    {/*            {book.size ? <div>大小:{book.size}</div> : ""}*/}
                    {/*            {book.language ? <div>语言:{book.language}</div> : ""}*/}
                    {/*            {book.format ? <div>格式:{book.format}</div> : ""}*/}
                    {/*            {book.isbn ? <div>ISBN:{book.isbn}</div> : ""}*/}
                    {/*            /!*{book.prince ? <div>价格:{book.prince}</div> : ""}*!/*/}
                    {/*            {book.ebookLink ?*/}
                    {/*                <div>电子书:<a target="_blank" rel="noopener noreferrer"*/}
                    {/*                            href={book.ebookLink}>试读</a>*/}
                    {/*                </div> : ""}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<hr/>*/}
                    {/*<div className="card-body">*/}
                    {/*    <Media body>*/}

                    {/*{book.source === 'zlib' &&*/}



                    {/*<Button type="primary" onClick={showModal}>*/}
                    {/*    Open Modal*/}
                    {/*</Button>*/}
                    {/*<Modal title="Basic Modal"*/}
                    {/*       visible={isModalOpen}*/}
                    {/*        onCancel={handleCancel}>*/}
                    {/*    <p>Some contents...</p>*/}
                    {/*    <p>Some contents...</p>*/}
                    {/*    <p>Some contents...</p>*/}
                    {/*</Modal>*/}


                    <div className={"m-2"}>
                        <h6><strong>下载</strong></h6>
                        <Button
                            icon={<DownloadOutlined/>}
                            type="primary"
                            // size='small'
                            onClick={(e) => zlibDownload(e)}
                            loading={iconLoading}
                            // href={book.pdfDownload}
                            // target="_blank" rel="noopener noreferrer"
                        /> {
                        downloadFlag && zlibDownloadUrl &&
                        <ButtonGroup>
                            <Button
                                type="primary"
                                href={zlibDownloadUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                点这里</Button>

                            {realDownloadFlag && <CopyToClipboard
                                onCopy={() => {
                                    message.success("文件名复制成功，可粘贴")
                                }}
                                text={getZlibDownloadName(book.title, book.format) ? getZlibDownloadName(book.title, book.format) : book.title}>
                                <Button
                                    onClick={() => thuderDownload(zlibDownloadUrl, book.format)}
                                >迅雷</Button>
                            </CopyToClipboard>
                            }
                        </ButtonGroup>
                    }
                    </div>

                    {/*}*/}
                    {(book.pdfDownload || book.pubDownload) && <div className="m-2">
                        <h6><strong>下载</strong></h6>
                        <ButtonGroup>
                            {book.pdfDownload && <Button
                                href={book.pdfDownload}
                                target="_blank" rel="noopener noreferrer"
                            >PDF</Button>
                            }
                            {book.pubDownload && <Button
                                href={book.pubDownload}
                                target="_blank" rel="noopener noreferrer"
                            >ePub下载</Button>
                            }
                            {book.pdfDownload && < Button
                                onClick={() => thuderDownload(book.pdfDownload, 'pdf')}
                                target="_blank" rel="noopener noreferrer"
                            >PDF迅雷</Button>

                            }
                            {book.pubDownload && <Button
                                onClick={() => thuderDownload(book.pubDownload, 'epub')}
                                target="_blank" rel="noopener noreferrer"
                            >ePub迅雷</Button>
                            }

                        </ButtonGroup>
                    </div>
                    }

                    <div className={"m-2"}>
                        <h6><strong>代找</strong></h6>
                        <Button type="primary" onClick={showModal}>付费人工代找</Button>
                    </div>

                    <Modal title="人工代找,非诚勿扰" visible={isModalOpen} onCancel={handleCancel}  footer={null}>
                        <p>发送需求到邮箱13759995@qq.com</p>
                        <p>找到满足需求的书籍</p>
                        <p>确认付款(人工代找费用10元起)</p>
                        <p>通过社交软件或网盘发送书籍给需求方</p>
                    </Modal>




                    <div className="m-2">
                        <h6><strong>购买</strong></h6>
                        <ButtonGroup>
                            <Button
                                target="_blank"
                                href={taobaoLink(book.title)}

                            >淘宝</Button>


                            {book.dbBuyUrl && <Button
                                target="_blank"
                                href={book.dbBuyUrl}

                            >豆瓣</Button>
                            }

                            {buy &&
                            Object.keys(buy).map(key =>
                                <Button key={key}
                                        target="_blank"
                                        href={buy[key]}
                                >{key}</Button>
                            )
                            }
                        </ButtonGroup>
                    </div>


                    {(book.description || book.authorIntro) &&
                    <Tabs className="m-2" defaultActiveKey="1" onChange={callback}>
                        {book.description && <TabPane tab="图书简介" key="1">

                            <div className="m-2">
                                <div style={{'textIndent': '2rem'}}
                                >
                                    <ReadMore
                                        more="展开"
                                        less="收起"
                                        lines={5}
                                        lang={book.language}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{__html: book.description}}
                                        />
                                    </ReadMore>


                                </div>
                            </div>
                        </TabPane>
                        }
                        {book.authorIntro && <TabPane tab="作者介绍" key="2">

                            <div className="m-2">
                                <div style={{'textIndent': '2rem'}}
                                >
                                    <ReadMore
                                        more="展开"
                                        less="收起"
                                        lines={3}

                                    >
                                        <div
                                            dangerouslySetInnerHTML={{__html: book.authorIntro}}>
                                        </div>
                                    </ReadMore>
                                </div>
                            </div>
                        </TabPane>
                        }

                    </Tabs>
                    }
                    {/*    </Media>*/}
                    {/*</div>*/}
                </div>


                {/*</div>*/}

                {/*<Card>*/}
                {/*    <CardHeader>*/}
                {/*        {isMobile &&*/}
                {/*        <span className="float-left mr-2"*/}
                {/*              style={{*/}
                {/*                  marginTop: '-0.2rem'*/}
                {/*              }}*/}
                {/*              onClick={() =>*/}

                {/*                  // window.history.back(-1)*/}
                {/*                  window.open(bookHistoryUrl ? bookHistoryUrl : "/book", "_self")*/}

                {/*              }>*/}
                {/*               <LeftCircleTwoTone/>*/}
                {/*        </span>*/}
                {/*        }*/}
                {/*        <span>{book.title}</span>*/}

                {/*        <Popover className="float-right flost-end mr-2 me-2 mt-1"*/}
                {/*                 trigger="click"*/}
                {/*                 content={<MyQRCode/>}*/}
                {/*                 placement="top"*/}
                {/*                 title="手机观看">*/}
                {/*            <MobileTwoTone/>*/}
                {/*        </Popover>*/}


                {/*    </CardHeader>*/}
                {/*    <CardBody style={{marginTop: '-1.5rem', marginLeft: '-1rem'}}>*/}
                {/*        <Media>*/}
                {/*            <Media left middle*/}
                {/*                   className="pt-2 cover">*/}
                {/*                <Img className="cover" src={images}/>*/}
                {/*            </Media>*/}


                {/*            <Media body className="p-2">*/}
                {/*                {book.enTitle ? <div>原名:{book.enTitle}</div> : ""}*/}
                {/*                {book.subTitle ? <div>副标题:{book.subTitle}</div> : ""}*/}
                {/*                {book.author ? <div>作者:{book.author}</div> : ""}*/}
                {/*                {book.rating ? <div>评分:{book.rating}</div> : ""}*/}
                {/*                {book.pubDate ? <div>日期:{book.pubDate}</div> : book.year ?*/}
                {/*                    <div>年份:{book.year}</div> : ""}*/}
                {/*                {book.pages ? <div>页数:{book.pages}</div> : ""}*/}
                {/*                {book.translator ? <div>译者:{book.translator}</div> : ""}*/}
                {/*                {book.series ? <div>丛书:{book.series}</div> : ""}*/}
                {/*                {book.area ? <div>地区:{book.area}</div> : ""}*/}
                {/*                {book.publisher ? <div>出版:{book.publisher}</div> : book.producer ?*/}
                {/*                    <div>出品:{book.producer}</div> : ""}*/}
                {/*                /!*{book.producer ? <div>出品:{book.producer}</div> : ""}*!/*/}
                {/*                {book.framing ? <div>装订:{book.framing}</div> : ""}*/}
                {/*                {book.size ? <div>大小:{book.size}</div> : ""}*/}
                {/*                {book.language ? <div>语言:{book.language}</div> : ""}*/}
                {/*                {book.format ? <div>格式:{book.format}</div> : ""}*/}
                {/*                {book.isbn ? <div>ISBN:{book.isbn}</div> : ""}*/}
                {/*                /!*{book.prince ? <div>价格:{book.prince}</div> : ""}*!/*/}
                {/*                {book.ebookLink ?*/}
                {/*                    <div>电子书:<a target="_blank" rel="noopener noreferrer" href={book.ebookLink}>试读</a>*/}
                {/*                    </div> : ""}*/}

                {/*            </Media>*/}
                {/*        </Media>*/}
                {/*        <Media>*/}

                {/*            <BrowserView>*/}
                {/*                <div*/}
                {/*                    style={{width: '10rem', height: '10rem', marginTop: '1rem', marginRight: '0.2rem'}}>*/}
                {/*                    /!*<Media style={{width: '10rem', height: '10rem', marginTop: '-0.5rem'}} left object*!/*/}
                {/*                    /!*       data-src={qr}*!/*/}
                {/*                    /!*       src={qr}/>*!/*/}

                {/*                    <MyQRCode size={155}/>*/}
                {/*                </div>*/}
                {/*            </BrowserView>*/}


                {/*            <Media body className="mt-2">*/}

                {/*                /!*{book.source === 'zlib' &&*!/*/}
                {/*                <div className="mt-2">*/}
                {/*                    <Button*/}
                {/*                        icon={<DownloadOutlined/>}*/}
                {/*                        type="primary"*/}
                {/*                        // size='small'*/}
                {/*                        onClick={(e) => zlibDownload(e)}*/}
                {/*                        loading={iconLoading}*/}
                {/*                        // href={book.pdfDownload}*/}
                {/*                        // target="_blank" rel="noopener noreferrer"*/}
                {/*                    /> {*/}
                {/*                    downloadFlag && zlibDownloadUrl &&*/}
                {/*                    <ButtonGroup>*/}
                {/*                        <Button*/}
                {/*                            type="primary"*/}
                {/*                            href={zlibDownloadUrl}*/}
                {/*                            target="_blank"*/}
                {/*                            rel="noopener noreferrer"*/}
                {/*                        >*/}
                {/*                            点这里</Button>*/}

                {/*                        {realDownloadFlag && <CopyToClipboard*/}
                {/*                            onCopy={() => {*/}
                {/*                                message.success("文件名复制成功，可粘贴")*/}
                {/*                            }}*/}
                {/*                            text={getZlibDownloadName(book.title, book.format) ? getZlibDownloadName(book.title, book.format) : book.title}>*/}
                {/*                            <Button*/}
                {/*                                onClick={() => thuderDownload(zlibDownloadUrl, book.format)}*/}
                {/*                            >迅雷</Button>*/}
                {/*                        </CopyToClipboard>*/}
                {/*                        }*/}
                {/*                    </ButtonGroup>*/}
                {/*                }*/}
                {/*                </div>*/}

                {/*                /!*}*!/*/}
                {/*                {(book.pdfDownload || book.pubDownload) && <div className="mt-2">*/}
                {/*                    <h6><strong>下载</strong></h6>*/}
                {/*                    <ButtonGroup>*/}
                {/*                        {book.pdfDownload && <Button*/}
                {/*                            href={book.pdfDownload}*/}
                {/*                            target="_blank" rel="noopener noreferrer"*/}
                {/*                        >PDF</Button>*/}
                {/*                        }*/}
                {/*                        {book.pubDownload && <Button*/}
                {/*                            href={book.pubDownload}*/}
                {/*                            target="_blank" rel="noopener noreferrer"*/}
                {/*                        >ePub下载</Button>*/}
                {/*                        }*/}
                {/*                        {book.pdfDownload && < Button*/}
                {/*                            onClick={() => thuderDownload(book.pdfDownload, 'pdf')}*/}
                {/*                            target="_blank" rel="noopener noreferrer"*/}
                {/*                        >PDF迅雷</Button>*/}

                {/*                        }*/}
                {/*                        {book.pubDownload && <Button*/}
                {/*                            onClick={() => thuderDownload(book.pubDownload, 'epub')}*/}
                {/*                            target="_blank" rel="noopener noreferrer"*/}
                {/*                        >ePub迅雷</Button>*/}
                {/*                        }*/}

                {/*                    </ButtonGroup>*/}
                {/*                </div>*/}
                {/*                }*/}

                {/*                <div className="mt-2">*/}
                {/*                    <h6><strong>购买</strong></h6>*/}
                {/*                    <ButtonGroup>*/}
                {/*                        <Button*/}
                {/*                            target="_blank"*/}
                {/*                            href={taobaoLink(book.title)}*/}

                {/*                        >淘宝</Button>*/}


                {/*                        {book.dbBuyUrl && <Button*/}
                {/*                            target="_blank"*/}
                {/*                            href={book.dbBuyUrl}*/}

                {/*                        >豆瓣</Button>*/}
                {/*                        }*/}

                {/*                        {buy &&*/}
                {/*                        Object.keys(buy).map(key =>*/}
                {/*                            <Button key={key}*/}
                {/*                                    target="_blank"*/}
                {/*                                    href={buy[key]}*/}
                {/*                            >{key}</Button>*/}
                {/*                        )*/}
                {/*                        }*/}
                {/*                    </ButtonGroup>*/}
                {/*                </div>*/}


                {/*                {(book.description || book.authorIntro) &&*/}
                {/*                <Tabs defaultActiveKey="1" onChange={callback}>*/}
                {/*                    {book.description && <TabPane tab="图书简介" key="1">*/}

                {/*                        <div className="m-2">*/}
                {/*                            <div style={{'textIndent': '2rem'}}*/}
                {/*                            >*/}
                {/*                                <ReadMore*/}
                {/*                                    more="展开"*/}
                {/*                                    less="收起"*/}
                {/*                                    lines={5}*/}
                {/*                                    lang={book.language}*/}
                {/*                                >*/}
                {/*                                    <div*/}
                {/*                                        dangerouslySetInnerHTML={{__html: book.description}}*/}
                {/*                                    />*/}
                {/*                                </ReadMore>*/}


                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </TabPane>*/}
                {/*                    }*/}
                {/*                    {book.authorIntro && <TabPane tab="作者介绍" key="2">*/}

                {/*                        <div className="m-2">*/}
                {/*                            <div style={{'textIndent': '2rem'}}*/}
                {/*                            >*/}
                {/*                                <ReadMore*/}
                {/*                                    more="展开"*/}
                {/*                                    less="收起"*/}
                {/*                                    lines={3}*/}

                {/*                                >*/}
                {/*                                    <div*/}
                {/*                                        dangerouslySetInnerHTML={{__html: book.authorIntro}}>*/}
                {/*                                    </div>*/}
                {/*                                </ReadMore>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </TabPane>*/}
                {/*                    }*/}

                {/*                </Tabs>*/}
                {/*                }*/}
                {/*            </Media>*/}

                {/*        </Media>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}
            </div>}


        </AppTemp>)
}
