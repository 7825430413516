import React from 'react'
import fetch from '../libs/fetch'


import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {Button, ListGroup, ListGroupItem} from 'reactstrap';
import {
    isMobile
} from "react-device-detect";
import {getLinkOpenTarget} from "../util/UrlUtil";
// import {taobaoLink} from "../util/TaobaoUtil";
// import {useQuery} from 'react-query'
import {useInfiniteQuery} from 'react-query'

export const TaobaoPageLoadMore = (props) => {

    let keyword = props.keyword;
    let type = props.type;
    if (!type) type = 1;
    // const [nextId] = useState(0);
    const platform = isMobile ? 2 : 1;

    const {
            status,
            data,
            error,
            isFetching,
            isFetchingMore,
            fetchMore,
            canFetchMore,

        } = useInfiniteQuery(
        'taobao_keyword' + keyword,
        (key, cursor = 0) =>
            fetch(RestUrls[DataTypes.TAOBAO_KEYWORD_PAGINATION]
                + "?type=" + type + "&platform=" + platform + "&nextId=" + (cursor || 0) + "&keyword=" + encodeURIComponent(keyword) + "&pageSize=10"
            )
        ,
        {
            getFetchMore: (lastGroup, allGroups) => lastGroup.nextId,
        }
        )
    ;


    // let flag = data && data.length > 0;
    // // console.log(data);
    // flag = flag && (data[0].error !== null && data[0].data !== null);
    // console.log("flag:"+flag);

    return (
        // flag &&
        // <div>
        //     {isLoading ? (
        //             <p>加载中</p>
        //         ) :
        <div>
            {
                status === 'loading' ? (
                    <p>Loading...</p>
                ) : status === 'error' ? (
                        <span>Error: {error.message}</span>
                    ) :
                    (
                        <>
                            {/*console.log(JSON.stringify(data)*/}
                            {data !== null && data !== undefined && data.map((page, i) => (
                                <ListGroup key={i}>
                                    {page.data && page.data.map(item => (
                                        <ListGroupItem key={item.id} tag="a" target={getLinkOpenTarget()}
                                                       href={item.click ? item.click : item.url}

                                        >{item.name}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            ))}
                            <div className="text-center mt-2">
                                <div>
                                    <Button
                                        onClick={() => fetchMore()}
                                        disabled={!canFetchMore || isFetchingMore}
                                        color={(!canFetchMore || isFetchingMore) ? 'secondary' : (canFetchMore ? 'primary' : 'secondary')}
                                    >
                                        {isFetchingMore
                                            ? '加载中'
                                            : canFetchMore
                                                ? '加载更多'
                                                : '到底了'}
                                    </Button>
                                </div>

                                <div>
                                    {isFetching && !isFetchingMore ? '正在获取数据' : null}
                                </div>
                            </div>
                        </>)
            }
        </div>
    )
};