export const DataTypes = {
    HOST_URL: "host_url",
    TOOL_URL: "tool_url",
    API_SH: "api_sh",
    VIDEO_PROXY: "video_proxy",
    VIDEO_SUGGESTS: "video_suggests",
    CATEGORIES: "categories",
    VIDEO_DETAIL: "video_detail",
    VIDEO_INDEX_DATA: "video_index_data",
    VIDEO_SLIDE_DATA: "video_slide",
    VIDEO_LOCAL_DETAIL: "video_local_detail",
    VIDEO_LC_PLAY: "video_lc_play",
    VIDEO_BD_PLAY_URL: "video_bd_play_url",
    VIDEO_618: "video_618",
    VIDEO_M3U8: 'video_m3u8',
    VIDEO_NO_PIC: 'video_no_pic',
    BOOK_KEYWORD_PAGINATION: 'book_keyword_pagination',
    BOOK_ZLIB_DOWNLOAD: 'book_zlib_download',


    SITE_KEYWORD_PAGINATION: 'site_keyword_pagination',
    TAOBAO_KEYWORD_PAGINATION: 'taobao_keyword_pagination',
    TAOBAO_MATERIAL_KEYWORD_PAGINATION: 'taobao_material_keyword_pagination',
    TAO_AD_KEYWORD_PAGINATION: 'tao_ad_keyword_pagination',
    PROJECTS: "projects_page",
    BOOK_BUY: "book_buy",
    BOOK_DIRECT: "book_direct",
    SITE_DIRECT: "site_direct",
    SUB_KEYWORD_PAGINATION: 'sub_keyword_pagination',
    SUB_DOWNLOAD: 'sub_download',
    PAN_SEARCH: 'pan_search',
    PAN_DETAIL: 'pan_detail',
    PAN_URL: 'pan_url',
    PAN_GYHJ: 'pan_gyhj',
    MUSIC_SERVER: "music_server"
};


export const ActionTypes = {
    VIDEO_DETAIL_DATA_LOAD: "video_detail_data_load",
    VIDEO_SUGGESTS_DATA_LOAD: "video_suggests_data_load",
    VIDEO_INDEX_DATA_LOAD: "video_index_data_load",
    INIT_DATA_LOAD: "init_data_load",
    VIDEO_DETAIL_LOCAL_DATA_LOAD: "video_detail_local_data_load"

};
