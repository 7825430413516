import React from "react";
import {PanDetailBase} from "./PanDetailBase";
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {useLocation} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export function PanDetailUrl() {
    let query = useQuery();
    let url = query.get("url");
    // let url = getUrlParameterInPage('url');
    if (!url) return '';
    // let li = url.lastIndexOf("/");
    function getLastPath(url) {
        let li = url.lastIndexOf("/");
        if (li !== -1) {
            if (url.length > li + 1) {
                return url.substr(li + 1);
            }
        }
        return null;
    }

    let id = getLastPath(url);
    let source = "spirit";
    if (url.indexOf("dalipan.com") !== -1) {
        source = "dali";
    }
    url = RestUrls[DataTypes.PAN_URL] + "?url=" + url;
    return <PanDetailBase url={url} id={id} source={source}/>;
}
