import React from "react";
import {toast} from 'react-toastify';
import 'antd/dist/antd.css';
// import {ZhidahaoStoreDataStore} from "../data/DataStore";
import '../App.css'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {TvLiveTest} from "../tv/TvLiveTest";
import {getProjectTitle} from "../util/ProjectUtil";
import Script from 'react-load-script'
import {setIpInfo} from "../util/IpUtil";
import {AppBase} from "./AppBase";
// import {ExpressQuery} from "../tool/ExpressQuery";
// import Snowfall from 'react-snowfall'
toast.configure();

export function App(props) {


    function handleScriptCreate() {
        // setScriptLoaded(true)
    }

    function handleScriptError() {
        // setScriptError(true);
    }

    function handleScriptLoad() {
        // setScriptLoaded(true)
        // eslint-disable-next-line no-undef
        let info = returnCitySN;
        if (info) {
            setIpInfo(info);
        }
    }

    return <div
        // store={ZhidahaoStoreDataStore}
    >




        <Script
            url="https://pv.sohu.com/cityjson?ie=utf-8"
            onCreate={handleScriptCreate.bind(this)}
            onError={handleScriptError.bind(this)}
            onLoad={handleScriptLoad.bind(this)}
        />


        <HelmetProvider>
            <Helmet>
                <title>{getProjectTitle()}</title>
                <meta name="description" content="一键直达"/>
                <meta name="theme-color" content="#008f68"/>
            </Helmet>
        </HelmetProvider>


        <Router>
            <Switch>



                <Route path={"/video/:play"} exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='video'
                               {...props}
                               {...routeProps}
                           />
                       }}/>

                <Route path={"/video/:play/:type/:id"}
                       render={(routeProps) => {
                           return <AppBase
                               current='video'
                               {...props}
                               {...routeProps} />
                       }}/>




                <Route path={"/:current"} exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               {...props}
                               {...routeProps} />
                       }}/>




                {/*<Route path={"/video/play/bd/:id"}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <AppBase current='video'*/}
                {/*                          {...routeProps} />*/}
                {/*       }}/>*/}


                <Route path={"/book/subject/:id"} exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='book'
                               detail ={true}
                               {...props}
                               {...routeProps}
                           />
                       }}/>


                <Route path={"/dosgame/subject/:id"} exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='dosgame'
                               detail ={true}
                               {...props}
                               {...routeProps}
                           />
                       }}/>



                {/*<Route path={"/site"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Site*/}
                {/*               current='site'*/}

                {/*               {...props}*/}
                {/*               {...routeProps} />*/}
                {/*       }}/>*/}


                {/*<Route path={"/taobao"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Taobao*/}
                {/*               current='taobao'*/}
                {/*               {...props}*/}
                {/*               {...routeProps} />*/}
                {/*       }}/>*/}


                <Route path={"/tv/test"} exact={true}
                       render={(routeProps) => {
                           return <TvLiveTest
                               current='tv'
                               {...props}
                               {...routeProps}
                           />
                       }}/>


                {/*<Route path={"/tv"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Tv*/}
                {/*               current='tv'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}


                {/*<Route path={"/live"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <TvLive*/}
                {/*               current='live'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}


                {/*<Route path={"/sub"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Sub*/}
                {/*               current='sub'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}

                <Route path={"/sub/:type"} exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='sub'
                               {...props}
                               {...routeProps}
                           />
                       }}/>





                {/*<Route path={"/pan/resources"} exact={true}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <AppBase*/}
                {/*               current='pan'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}


                <Route path={"/pan/:resources/:id"}
                       render={(routeProps) => {
                           return <AppBase
                               current='pan'
                               {...props}
                               {...routeProps} />
                       }}/>

                <Route path={"/tool/:id/:number"}
                    // exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='tool'
                               {...props}
                               {...routeProps} />
                       }}/>

                <Route path={"/tool/:id"}
                    // exact={true}
                       render={(routeProps) => {
                           return <AppBase
                               current='tool'
                               {...props}
                               {...routeProps}
                           />
                       }}/>

                {/*<Route path={"/tool"}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Tool*/}
                {/*               current='tool'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}

                {/*<Route path={"/tool"}*/}
                {/*       render={(routeProps) => {*/}
                {/*           return <Tool*/}
                {/*               current='tool'*/}
                {/*               {...props}*/}
                {/*               {...routeProps}*/}
                {/*           />*/}
                {/*       }}/>*/}

                <Redirect to="/tool"/>
                {/*<Redirect to="/tool"/>*/}
                {/*<Redirect to="/dosgame/subject/11"/>*/}
            </Switch>
        </Router>



    </div>
}



