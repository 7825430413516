import {Button, Col, Input, List, Row} from 'antd';
import React, {useState} from 'react';
import AppTemp from "../AppTemp";
import {getLinkOpenTarget} from "../util/UrlUtil";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {SearchModel} from "../comp/SearchModel";
import {isMobile} from "react-device-detect";
// import {useMount} from "ahooks";

const {Search} = Input;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export function Book(props) {
    let current = props.current;
    let history = useHistory();
    let location = useLocation();
    let state = history.location.state;
    state = state ? state : {};


    let query = useQuery();
    let redirectError = '';
    let errorValue = '';
    let lk = '';
    if (query) {
        redirectError = query.get("error");
        errorValue = query.get("value");
        lk = query.get("keyword");
    }

    // useMount(() => {
    //     // message.info('mount');
    //     window.history.pushState(null, '', "/book");
    // });


    // console.log("redirectError:" + redirectError)
    // let state =hisLocation.state?hisLocation.state:{};
    const [keyword, setKeyword] = React.useState(state.keyword || lk || '');
    const [value] = useState(errorValue || '');
    const [visible, setVisible] = useState(!!redirectError);
    const [historyUrl, setHistoryUrl] = useState();
    const fetchBooks = React.useCallback(async (key, cursor = 0) => {
            // if (!keyword) return {data: [], hasMore: false};
            // console.log("source:" + source)
            // let dataState = history.state;
            // console.log("dataState" + JSON.stringify(dataState));
            let host = RestUrls[DataTypes.BOOK_KEYWORD_PAGINATION];


            // console.log("cursor:" + cursor)
            let hostAndPage = host + "?nextId=" + cursor;
            let restUrl = keyword ? hostAndPage + "&keyword=" + encodeURIComponent(keyword) : hostAndPage

            // let hostAndPath = location.pathname;
            // let bar = hisParam ? hostAndPath + "?" + hisParam : hostAndPath
            // // alert(bar)
            let hostAndPath = location.pathname;
            let url = keyword ? hostAndPath + "?keyword=" + encodeURIComponent(keyword) : window.location;
            // alert(url);

            window.history.pushState({keyword}, '', url);
            setHistoryUrl(url)
            // history.push(url, {keyword});
            // window.history.pushState({dataState}, "", bar);
            // window.history.pushState(dataState,"",bar);

            const {data} = await axios.get(restUrl)
            return data;
        }, [keyword, location
        ]
    );
    const [loadingFlag, setLoadingFlag] = useState(false);

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingMore,
        fetchMore,
        canFetchMore,
    } = useInfiniteQuery(
        'book_' + keyword,
        fetchBooks,
        {
            getFetchMore: (lastGroup
                           // , allGroups
            ) => lastGroup.nextId,
        }
    )

    function onChange(e) {
        let keyword = e.target.value;
        setKeyword(keyword);
    }


    function onSearch(value) {
        // console.log(value);

        value && directQuery(value);
    }

    function directQuery(value) {
        setLoadingFlag(true);
        // value && queryDirectSync(value);
        value && window.open(RestUrls[DataTypes.BOOK_DIRECT] + "?q=" + encodeURIComponent(value), getLinkOpenTarget());
        setLoadingFlag(false);
        // value&&window.location.
    }


    const handleCancel = () => {
        setVisible(false);
    };

    function addHistoryUrl() {
        // alert(historyUrl)
        // if (isMobile) {
        // console.log("hu:"+hu)
        localStorage.setItem("book_historyUrl",historyUrl)
        // }
    }

    return (

        <AppTemp current={current}>

            {/*<div*/}
            {/*    className="container mt-3"*/}
            {/*>*/}
            <Row className="mt-3">
                <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>
                    <Search
                        placeholder="书名、作者、ISBN"
                        enterButton="直达"
                        size="large"
                        allowClear
                        autoFocus
                        value={keyword}
                        onChange={onChange}
                        onSearch={onSearch}
                        loading={loadingFlag}
                        // ref={this.searchInput}
                    />
                </Col>
            </Row>
            {/*</div>*/}


            {value &&
            <SearchModel handleCancel={handleCancel} keyword={value} visible={visible}/>
            }

            <div className={"container text-center mt-2"}>
                {status === 'loading' ? (
                    <p>Loading...</p>
                ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>
                        {data.map((page, i) => (


                            <List key={i} className={"container mt-2"}
                                // header={<div>Header</div>}
                                // footer={<div>Footer</div>}
                                  size={isMobile ? 'small' : 'default'}
                                  bordered
                                  dataSource={page.data}
                                  renderItem={item => (
                                      <List.Item>
                                          <a href={`/book/subject/${item.id}`}
                                             onClick={addHistoryUrl}
                                              target={getLinkOpenTarget()}
                                          > {item.name}</a>
                                      </List.Item>
                                  )}
                            />

                        ))}


                        <div className={"container text-center mt-2 mb-2"}>

                            <Button
                                id="load_btn"
                                onClick={() => fetchMore()}
                                disabled={!canFetchMore || isFetchingMore}
                            >
                                {isFetchingMore
                                    ? '加载中...'
                                    : canFetchMore
                                        ? '加载更多'
                                        : '没有数据了'}
                            </Button>
                        </div>
                        <div>
                            {isFetching && !isFetchingMore ? 'Background Updating...' : null}
                        </div>
                    </>
                )}
            </div>

        </AppTemp>


    )
}

// function queryDirect(value) {
//     return axios.get(RestUrls[DataTypes.BOOK_DIRECT] + "?q=" + value);
// }


// const queryDirectSync = async (value) => {
//     let response = await queryDirect(value);
//     setLoadingFlag(false);
//     let data = response && response.data;
//     if (data && data.id) {
//         // window.location.href = "/book/subject/" + data.id;
//         let tempWindow = window.open(getLinkOpenTarget());
//         tempWindow.location = "/book/subject/" + data.id;
//     } else {
//         setValue(value);
//         setVisible(true);
//         // console.log("come here:"+value);
//         // notfoundToast("没有找到你要的书");
//     }
//
// };