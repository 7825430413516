export   const  ProjectConstant = {
        PROJECT_NAME: process.env.REACT_APP_WEBSITE_NAME,
        category: {
            "taobao": "淘宝",
            "book": "图书",
            "video": "影视",
            "sub": "字幕",
            "site": "网址",
            "pan": "网盘",
            "tv": "电视",
            "live": "直播",
            "tool": "工具"
        }

    }
;
