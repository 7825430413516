import React from 'react';
import {isMobile} from "react-device-detect";
import {Alert, Button, Col, Input, message, Row, Table} from 'antd';
import {change15to18, change18to15, checkIdCard, getArea, getBirthday, getSex, randomIdCard} from "../util/IdCardUtil";
import trim from "validator/es/lib/trim";
import {IdcardOutlined} from '@ant-design/icons';
import {CopyToClipboard} from "react-copy-to-clipboard";


// const IconFont = createFromIconfontCN({
//     scriptUrl: [
//         '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
//         '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
//     ],
// });

const {Search} = Input;

export function IdCard(props) {

    // let {id} = useParams();
    // alert(id)
    const [idCard, setIdCard] = React.useState();
    const [sex, setSex] = React.useState();
    const [birthday, setBirthday] = React.useState();
    const [address, setAddress] = React.useState();
    const [error, setError] = React.useState();
    const [success, setSuccess] = React.useState(false);
    const [keyword, setKeyword] = React.useState();

    // React.useEffect(() => {
    //
    // }, []);


    function showData(value) {
        setIdCard(value);
        let result = checkIdCard(value);
        if (result === 'true') {
            setSex(getSex(value));
            setAddress(getArea(value));
            setBirthday(getBirthday(value))
        } else {
            setError(result);
        }
        setSuccess(result === 'true');

    }

    function getData() {

        return [
            {
                key: '1',
                idCard: idCard,
                sex: sex,
                birthday: birthday,
                address: address
            }
        ];
    }


    function onSearch(value) {
        console.log("seach:" + value)
        value = trim(value);
        if (value) {
            showData(value);
            searchRef.current.blur();
        }
    }

    function onChange(e) {
        let keyword = e.target.value;
        setKeyword(keyword);
    }

    // let current = props.current;
    const searchRef = React.useRef();
    // console.log("statue:"+JSON.stringify(state));

    const columns = [
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            key: 'idCard',
            // render: text => <a>{text}</a>,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: '出生日期',
            dataIndex: 'birthday',
            key: 'birthday',
        }, {
            title: '发证地',
            dataIndex: 'address',
            key: 'address',
        }
    ]

    function randomGene() {
        let random = randomIdCard();
        if (random) {
            setKeyword(random);
            showData(random)
        }

    }

    return (
        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <div>
                <Row>
                    {/*<IdcardOutlined />*/}
                    <Col span={isMobile ? 22 : 12} offset={isMobile ? 1 : 6}>
                        {/*<Space>*/}
                        <Input.Group compact>
                            <Search

                                addonBefore={
                                    <Button onClick={randomGene}>
                                        随机
                                    </Button>
                                }


                                id="pan_input"
                                placeholder='请输入15位或18位身份证号'
                                enterButton
                                prefix={<IdcardOutlined/>}
                                size="large"
                                value={keyword}
                                allowClear
                                autoFocus
                                onSearch={onSearch}
                                onChange={onChange}
                                ref={searchRef}
                            />
                        </Input.Group>
                    </Col>
                </Row>
            </div>
            {
                success ?
                    <div className={"m-2"}>
                        <Table
                        dataSource={getData()} columns={columns}
                        title={() => '查询结果'}
                        pagination={false}/>

                        <div className={"m-2"}>
                            <CopyToClipboard
                                onCopy={() => {
                                    message.success("复制成功")
                                }}
                                text={idCard.length === 18 ? change18to15(idCard) : change15to18(idCard)}>
                                <Button>{idCard.length === 18 ? '15位身份证号码为:' + change18to15(idCard) : "18位身份证号码为:" + change15to18(idCard)}</Button>
                            </CopyToClipboard>
                        </div>
                    </div> :

                    idCard && <div className={"mt-2"}>
                        <Alert message={error} type="error" showIcon closable/>
                    </div>

            }
        </div>
        // </AppTemp>
    )


}

