import React from 'react';
import AppTemp from "../AppTemp";
import './taobao.css';
import {TaobaoPageLoadMore} from './TaobaoPageLoadMore';
import {TbmPageLoadMore} from './TbmPageLoadMore';
import {Button, Input, Radio, Switch} from 'antd';
import {TaoAdPageLoadMore} from "./TaoAdPageLoadMore";
import {MobileView,} from "react-device-detect";
import {getLinkOpenTarget} from "../util/UrlUtil";
import {useLocation} from "react-router-dom";

const ButtonGroup = Button.Group;

const {Search} = Input;

export function Taobao(props) {
    let location = useLocation();
    const [keyword, setKeyword] = React.useState('');
    // const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [searchType, setSearchType] = React.useState('m');
    const [placeholder, setPlaceholder] = React.useState('商品');
    const [shopType, setShopType] = React.useState(2);
    let current = props.current;
    if (!current && location) {
        const {pathname} = location;
        current = pathname && pathname.length > 1 && pathname.substr(1);
    }


    function onChange(e) {
        let keyword = e.target.value;
        if (!keyword) {
            setKeyword("")
        }
    }

    let searchRef = React.createRef();

    function onSearch(value) {
        // console.log(value);
        value && setKeyword(value);
        searchRef.current.blur();
    }

    function radioChange(e) {
        console.log(`radio checked:${e.target.value}`);
        let searchType = e.target.value;
        let hold = searchType === 'm' ? '商品' : '店铺';
        setPlaceholder(hold)
        searchType && setSearchType(searchType)
    }

    function onShopChange(checked) {
        let value = checked ? 2 : 1;
        setShopType(value);
        console.log(`switch to ${checked}`);
        console.log('setShopType:'+value);
    }

    return <AppTemp current={current}>
        <MobileView>
            <div className="container mt-3">
                <ButtonGroup>
                    <Button
                        // size='small'
                        target={getLinkOpenTarget()}
                        href="https://mo.m.taobao.com/optimus/flms-toc?pid=mm_17192274_5508989_20008734&traffic_flag=lm&scm=20140618.1.01010001.s101c6&spm=a21wq.13042888.19711645.1&union_lens=lensId%3AAPP%401590138889%400b154dc1_0f17_1723ba9e4d0_0a27%40024lJD0hHOpK9Uo0dMflvfOZ&un=a7cb386f16e28f383dd3af49e2902fce&share_crt_v=1&ut_sk=1.utdid_null_1590138889451.TaoPassword-Outside.lianmeng-app&sp_tk=77+leXhXTjFxSHRpeXHvv6U=&cpp=1&shareurl=true&short_name=h.VkIcgu0&app=chrome">今日秒杀</Button>

                    <Button target={getLinkOpenTarget()}
                        // size='small'
                            href="https://mos.m.taobao.com/taokeapp/20181111ysppcjbkg?pid=mm_17192274_5524963_56988373">品牌券</Button>
                    <Button
                        // size='small'
                        target={getLinkOpenTarget()}
                        href="https://mos.m.taobao.com/union/supercoupon?pid=mm_17192274_5524963_56988373">大额优惠券</Button>

                </ButtonGroup>
            </div>
        </MobileView>




        <div className="container mt-3" onChange={radioChange}>
            <Radio.Group defaultValue="m" buttonStyle="solid">
                <Radio.Button value="m">商品</Radio.Button>
                <Radio.Button value="s">店铺</Radio.Button>
            </Radio.Group>
            <Switch className={"ms-3"} checkedChildren="天猫" unCheckedChildren="小店" defaultChecked

                    onChange={onShopChange}
            />

        </div>

        <div className="container mt-2">
            {/*<form action="https://www.escrow.com/checkout" method="post">*/}
            {/*    <input type="hidden" name="type" value="domain_name"/>*/}
            {/*    <input type="hidden" name="non_initiator_email" value="13759995@qq.com"/>*/}
            {/*    <input type="hidden" name="non_initiator_id" value="2377174"/>*/}
            {/*    <input type="hidden" name="non_initiator_role" value="seller"/>*/}
            {/*    <input type="hidden" name="title" value="Sale of domain name fuwuhao.com"/>*/}
            {/*    <input type="hidden" name="currency" value="USD"/>*/}
            {/*    <input type="hidden" name="domain" value="fuwuhao.com"/>*/}
            {/*    <input type="hidden" name="price" value="100000"/>*/}
            {/*    <input type="hidden" name="concierge" value="false"/>*/}
            {/*    <input type="hidden" name="with_content" value="false"/>*/}
            {/*    <input type="hidden" name="inspection_period" value="2"/>*/}
            {/*    <input type="hidden" name="fee_payer" value="buyer"/>*/}
            {/*    <input type="hidden" name="return_url" value=""/>*/}
            {/*    <input type="hidden" name="button_types" value="buy_now"/>*/}
            {/*    <input type="hidden" name="auto_accept" value=""/>*/}
            {/*    <input type="hidden" name="auto_reject" value=""/>*/}
            {/*    <input type="hidden" name="item_key" value="undefined"/>*/}
            {/*    <button className="EscrowButtonSecondary" type="submit">Buy It Now</button>*/}

            {/*    <img alt="escrow" src="https://t.escrow.com/1px.gif?name=bin&price=100000&title=Sale%20of%20domain%20name%20%20fuwuhao.com&user_id=2377174" style={{display: 'none'}}/>*/}
            {/*</form>*/}





            <Search
                placeholder={placeholder}
                enterButton
                size="large"
                allowClear
                autoFocus
                onChange={onChange}
                onSearch={onSearch}
                ref={searchRef}
            />
        </div>

        {!keyword && <div className="container mt-2">
            <TaoAdPageLoadMore/>

        </div>}


        {keyword && searchType === 'm' && shopType && <div className="container mt-2">
            <TbmPageLoadMore keyword={keyword} type={shopType}/>
        </div>
        }


        {keyword && searchType === 's' && shopType && <div className="container mt-2">
            <TaobaoPageLoadMore keyword={keyword} type={shopType}/>
        </div>
        }
    </AppTemp>

}
