import React from 'react'
import fetch from '../libs/fetch'

import {useInfiniteQuery} from 'react-query'
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {
    Avatar, List,
    // , Radio, Icon
    Button as AButton
} from 'antd';

import {isMobile} from "react-device-detect";
import {Button} from 'reactstrap';
import {getLinkOpenTarget} from "../util/UrlUtil";
// import {taobaoLink} from "../util/TaobaoUtil";

export const TbmPageLoadMore = (props) => {

    let keyword = props.keyword;
    let type = props.type;
    if (!type) type = 1;
    // const [cursor] = useState(0);
    // const [page, setPage] = useState(0);
    const platform = isMobile ? 2 : 1;
    // const pageSize = isMobile ? 10 : 12;
    const pageSize = 10;


    const {
        status,
        data,
        error,
        isFetching,
        isFetchingMore,
        fetchMore,
        canFetchMore,
    } = useInfiniteQuery(
        'tbm_keyword' + keyword,
        (key, cursor = 0) =>
            fetch(RestUrls[DataTypes.TAOBAO_MATERIAL_KEYWORD_PAGINATION]
                + "?type=" + type + "&platform=" +
                platform + "&nextId=" + cursor + "&keyword=" + encodeURIComponent(keyword) + "&pageSize=" + pageSize)

        ,
        {
            // getCanFetchMore: lastPage => lastPage.nextId,
            getFetchMore: (lastGroup, allGroups) => lastGroup.nextId,
        }
    );


    // let flag = data && data.length > 0;
    // console.log(data);
    // flag = flag && (data[0].error !== null && data[0].data !== null);
    // console.log("flag:"+flag);

    return (
        // flag &&
        <div>
            {status === 'loading' ? (
                <p>Loading...</p>
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (
                <>
                    {/*console.log(JSON.stringify(data)*/}
                    {data !== null && data !== undefined && data.map((page, i) => (
                        <List key={i}
                              dataSource={page.data}
                              renderItem={item => (
                                  <List.Item key={item.id}>
                                      <List.Item.Meta
                                          avatar={
                                              <Avatar
                                                  src={item.logo}/>
                                          }
                                          title={<a target={getLinkOpenTarget()}
                                                    href={item.click}>{item.shopTitle}</a>}
                                          description={item.title}
                                      />
                                      {item.price && <div className={"mr-2"}>{item.price}元</div>}
                                      {/*<Button tag={'a'}>购买</Button>*/}
                                      {item.click && <AButton className={"m-1"} target={getLinkOpenTarget()}
                                                              href={item.click}
                                                              type="primary" shape="round"
                                          // icon="buy"
                                      >购买</AButton>
                                      }
                                  </List.Item>
                              )}
                        >
                        </List>
                    ))}
                    <div className="text-center mt-2">
                        <div>
                            <Button
                                onClick={() => fetchMore()}
                                disabled={!canFetchMore || isFetchingMore}
                                color={(!canFetchMore || isFetchingMore) ? 'secondary' : (canFetchMore ? 'primary' : 'secondary')}
                            >
                                {isFetchingMore
                                    ? '加载中.'
                                    : canFetchMore
                                        ? '加载更多'
                                        : '到底了'}
                            </Button>
                        </div>

                        <div>
                            {isFetching && !isFetchingMore ? '正在获取数据' : null}
                        </div>
                    </div>
                </>)
            }
        </div>
    )
};