import { ActionTypes} from "./Types";
import { RestDataSource } from "./RestDataSource";
import { data as initData} from "./InitData";


const dataSource = new RestDataSource();



export const loadInitData = (dataType) => ({
    type: ActionTypes.INIT_DATA_LOAD,
    payload: {
        dataType: dataType,
        data: initData[dataType]
    }
});

export const loadVideoDetailLocalData = (dataType) => ({
    type: ActionTypes.VIDEO_DETAIL_LOCAL_DATA_LOAD,
    payload: {
        dataType: dataType,
        data: localStorage.getItem(dataType)&&JSON.parse( localStorage.getItem(dataType))
    }
});






export const loadVideoSuggestsData = (dataType,params) => ({
    type: ActionTypes.VIDEO_SUGGESTS_DATA_LOAD,
    payload: dataSource.GetData(dataType,params).then(response =>
        ({ dataType,
            data: response.data,
            params
        }))
});



export const loadVideoDetailData = (dataType,params) => ({
    type: ActionTypes.VIDEO_DETAIL_DATA_LOAD,
    payload: dataSource.GetData(dataType,params).then(response =>
        ({ dataType,
            data: response.data,
            params
        }))
});
