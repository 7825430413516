import React from 'react';
import {Col, Input, Row, Table, message} from "antd";
import jsonp from 'jsonp';
import trim from "validator/es/lib/trim";
import {isMobile} from "react-device-detect";
import {getAsUriParameters} from "../util/UrlUtil";
// import $ from 'jquery'
import {MobileOutlined} from '@ant-design/icons';
import myFetch from '../libs/fetch'

const {Search} = Input;

export function PhoneQuery(props) {
    const searchRef = React.useRef();
    const [value, setValue] = React.useState();
    const [desc, setDesc] = React.useState();
    const [error, setError] = React.useState();
    const [keyword, setKeyword] = React.useState();

    const errMessage = <div>抱歉，没有找到该号码的归属地信息~ 请输入有效的电话号码（座机号码请加区号）</div>;


    function getOtherCountry(number) {

        if (number.indexOf("00") === 0) {
            number = "+" + number.substring(2);
        }
        let u = "https://apish.zhidahao.com/tool/mobile/" + number;
        myFetch(u).then(data => {

            setValue(number);
            // alert(data.message)
            if (!data || (data.message && "电话号码格式不对" === data.message)) {
                setError(errMessage);
                return;
            }
            data && data.description && setDesc(data.description);
            setError("");

            // console.log(JSON.stringify(data));
        });
    }

    function onSearch(value) {
        // console.log("come from onSearch:" + value)
        if (!value) return;
        value = trim(value);
        value = value.replace(/[^0-9]/g, '');
        let len = value.length;
        if (len < 11) {
            return message.info("无效的号码,固话请加区号");
        }
        if (len === 13 && value.indexOf("86") === 0) {
            let simpleValue = value.substr(2, 11);
            setValue(simpleValue);
            queryMobile(simpleValue);
        } else if (len === 11 || len === 12) {
            let f = value.substr(0, 1);
            if (f === '1' || f === '0') {
                setValue(value);
                queryMobile(value);
            } else {
                getOtherCountry(value);
            }

        } else if (len > 12) {
            getOtherCountry(value)
        }
        searchRef.current.blur();

    }

    function queryMobile(value) {

        let timestamp1 = (new Date()).valueOf();
        let callback = "jQuery18309976820667399982_" + timestamp1;
        let opt = {
            name: callback,
            timeout: 5000,
        }
        let type = "mobilecheck";
        let dp = {
            "callback": callback,
            "query": value,
            "_": (timestamp1 + 3) + "",
            "tpl": "1",
            "type": type,
            "src": "onebox",
            "num": "1",
            "url": type
        };
        let p = getAsUriParameters(dp);

        let url = "https://open.onebox.so.com/dataApi?" + p;
        jsonp(url, opt, (error, data) => {
            let result = null;
            if (data && data.html) {
                let html = data.html;
                // alert(html)
                let eh = document.createElement('html');
                eh.innerHTML = html;
                let ele = eh.getElementsByClassName("mh-detail");
                if (ele && ele.length > 0) {
                    // result = $(html).find("p.mh-detail").text();
                    result = ele[0].innerText;
                    if (result) {
                        result = result.replace(/\s*/g, "");
                        result &&
                        setDesc(result.replace(value, ""))

                    }
                }

            }

            if (!result) {
                getOtherCountry(value);
            } else {
                setError('');
            }

            // !result ? setError(errMessage) : setError('');

        });
    }

    function onChange(e) {
        let v1 = e.target.value;
        setKeyword(v1)
    }

    function getData() {
        return [
            {
                key: '1',
                no: value,
                area: desc,
            }
        ];
    }

    const columns = [
        {
            title: '号码',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: '归属地',
            dataIndex: 'area',
            key: 'area',
        }
    ]


    return (
        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <Row>
                <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>
                    <Input.Group compact>
                        <Search
                            prefix={<MobileOutlined/>}
                            placeholder='手机号,固话'
                            enterButton
                            size="large"
                            value={keyword}
                            allowClear
                            autoFocus
                            onSearch={onSearch}
                            onChange={onChange}
                            ref={searchRef}
                        />
                    </Input.Group>
                </Col>
            </Row>
            <div className={"m-3 text-center"}>
                {error ? error :
                    desc ? <Table
                        dataSource={getData()} columns={columns}
                        title={() => '查询结果'}
                        pagination={false}/> : ''
                }
            </div>
        </div>
    )
}
