import {MyCalendar} from "./MyCalendar";

export function fireworksDay() {
    const date = new Date(); //获取一个时间对象
    let mon = date.getMonth();
    let year = date.getFullYear();
    let day = date.getDate();
    mon = mon + 1;


    // /** 公历年月日转农历数据 返回json */
    // calendar.solar2lunar(1987,11,01);
    // /** 农历年月日转公历年月日 */
    // calendar.lunar2solar(1987,9,10);
    // year = 2022;
    // mon = 1;
    // day = 1;
    // console.log("year:" + year + ",mon:" + mon + ",day:" + day);
    let lunar = MyCalendar.solar2lunar(year, mon, day);
    // console.log("lunar:" + JSON.stringify(lunar));
    let festival = lunar.festival;
    let lunarFestival = lunar.lunarFestival;
    // console.log("lunarFestival:" + lunarFestival);
    const lunaFestivalArr = ["除夕", "春节", "元宵节", "中秋节"];
    const festivalArr = ["元旦节", "国庆节"];
    // const festivalArr = ["除夕", "春节", "元宵节", "中秋节"];
    if (lunarFestival && lunaFestivalArr.includes(lunarFestival)) {
        return true;
    }

    return !!(festival && festivalArr.includes(festival));

}
