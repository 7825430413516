import QRCode from "qrcode.react";
import React from "react";

function MyQRCode(props) {

    return (
        <QRCode
            value={props.value || window.location.href}
            size={props.size || 128}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            // level={"L"}
            level={"H"}
            includeMargin={false}
            // renderAs={"svg"}
            imageSettings={{
                src: "/logo512.png",
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
            }}
        />)
}

export default MyQRCode;