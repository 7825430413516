import React from 'react';
import {JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import {Button, Input, message} from 'antd';
import {isMobile} from "react-device-detect";
import {isJson} from "../../util/MyStringUtil";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
// import JSONFormatter from 'json-formatter-js'

const {TextArea} = Input;


export function MyJsonEditor(props) {

    const [json, setJson] = React.useState();
    const [result, setResult] = React.useState();


    function importData() {
        // console.log("json:" + json);
        // let value = e.target.value;
        // console.log("value:" + value);
        // console.log("isJson:" + isJson(json))
        if (isJson(json)) {
            // console.log("format:" + json);
            setResult(JSON.parse(json));
        }
    }

    // function format() {
    //     if (isJson(json)) {
    //         // let formatJson = JSON.stringify(json, null, "\t");
    //         // console.log("format:"+formatJson);
    //         // setJson(formatJson);
    //         // const formatter = new JSONFormatter(json);
    //         // console.log("formatter:" + formatter)
    //     }
    // }


    function change(e) {
        let value = e.target.value;
        setJson(value);
        // console.log(" change value:" + value);
    }

    function CopyCom(props) {
        return <CopyToClipboard
            className="mt-1 float-right float-end"
            text={props.text} onCopy={() => message.success("复制成功")}>
            <Button disabled={!props.text} type="primary">复制</Button>
        </CopyToClipboard>
    }

    function MyEditor(props) {


        const [final, setFinal] = React.useState();

        function handleChange(v) {
            if (isJson(v)) {
                // setFinalResult(JSON.stringify(v));
                let formatStr = JSON.stringify(v, null, "\t");
                document.getElementById("j_text").value = formatStr;
                // setJson(JSON.stringify(v));
                setFinal(formatStr)
                // console.log("handleChange:" + v)
            }
        }

        return <div className={"mt-2"}>
            <CopyCom text={final}/>
            <br/>
            <div className={"mt-4"}>
                <Editor
                    value={props.result}
                    onChange={handleChange}
                />
            </div>


        </div>
    }


    return <div className={"mt-3"}>
        <h5 className={"ml-5 ms-5"}>{props.title}</h5>
        <hr/>

        <div className={"m-1"}>


            <MyEditor result={result}/>

            <Button className="m-1 float-right" type="primary" onClick={importData}>导入编辑器</Button>
            {/*<Button className="m-1 float-right" type="primary" onClick={format}>格式化</Button>*/}
            <TextArea
                id='j_text'
                placeholder="json字符串"
                allowClear
                onChange={change}
                autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}>
            </TextArea>


        </div>
    </div>

}
