import React from 'react';
import {Col, Input, Row, message, Tooltip, Button
    // , Upload
} from "antd";
import {isMobile} from "react-device-detect";
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    // UploadOutlined,
    FileImageOutlined} from '@ant-design/icons';

const {
    // Search, T
    TextArea} = Input;

export function Image2Base64(props) {


    // const u = "https://fastmarket.oss-cn-shenzhen.aliyuncs.com/oss/static/other/1/images/baseMap_index.jpg";
    // const [url, setUrl] = React.useState(u);
    const [result, setResult] = React.useState();


    // /**
    //  *
    //  * @param img html的img标签
    //  * @param ext 图片格式
    //  * @returns {string}
    //  */
    // function getImageBase64(img, ext) {
    //     let canvas = document.createElement("canvas");   //创建canvas DOM元素，并设置其宽高和图片一样
    //     canvas.width = img.width;
    //     canvas.height = img.height;
    //     var ctx = canvas.getContext("2d");
    //     ctx.drawImage(img, 0, 0, img.width, img.height); //使用画布画图
    //     var dataURL = canvas.toDataURL("image/" + ext);  //返回的是一串Base64编码的URL并指定格式
    //     canvas = null; //释放
    //     return dataURL;
    // }


    // /**
    //  *
    //  * @param url 图片路径
    //  * @param ext 图片格式
    //  * @param callback 结果回调
    //  */
    // function getUrlBase64(url, ext, callback) {
    //     let canvas = document.createElement("canvas");   //创建canvas DOM元素
    //     let ctx = canvas.getContext("2d");
    //     let img = new Image();
    //     img.crossOrigin = 'Anonymous';
    //     img.src = url;
    //     img.onload = function () {
    //         canvas.height = 60; //指定画板的高度,自定义
    //         canvas.width = 85; //指定画板的宽度，自定义
    //         ctx.drawImage(img, 0, 0, 60, 85); //参数可自定义
    //         let dataURL = canvas.toDataURL("image/" + ext);
    //         callback.call(this, dataURL); //回掉函数获取Base64编码
    //         canvas = null;
    //     };
    // }


    // function onSearch(value) {
    //     // console.log("come from onSearch:" + value)
    //     if (!value) {
    //         // setUrl(u);
    //         value = u;
    //     }
    //     // setUrl(value);
    //     // searchRef.current.blur();
    //     // getBase64(value);
    //
    //     getUrlBase64(value, "jpg", function (base64) {
    //         console.log(base64);//base64编码值
    //     });
    //
    // }

    // function getBase64(imgUrl) {
    //     window.URL = window.URL || window.webkitURL;
    //     var xhr = new XMLHttpRequest();
    //     xhr.open("get", imgUrl, true);
    //     // 至关重要
    //     xhr.responseType = "blob";
    //     xhr.onload = function () {
    //         if (this.status === 200) {
    //             //得到一个blob对象
    //             var blob = this.response;
    //             console.log("blob", blob)
    //             // 至关重要
    //             let oFileReader = new FileReader();
    //             oFileReader.onloadend = function (e) {
    //                 // 此处拿到的已经是 base64的图片了
    //                 let base64 = e.target.result;
    //                 console.log("方式一》》》》》》》》》", base64)
    //                 setResult(base64);
    //             };
    //             oFileReader.readAsDataURL(blob);
    //             //====为了在页面显示图片，可以删除====
    //             // var img = document.createElement("img");
    //             // img.onload = function (e) {
    //             //     window.URL.revokeObjectURL(img.src); // 清除释放
    //             // };
    //             // let src = window.URL.createObjectURL(blob);
    //             // img.src = src
    //             //document.getElementById("container1").appendChild(img);
    //             //====为了在页面显示图片，可以删除====
    //
    //         }
    //     }
    //     xhr.send();
    // }


    function toBase64(file) {
        /*转换base64*/
        // let img = document.getElementById('imgfile')
        // let imgFile = new FileReader();
        // imgFile.readAsDataURL(img.files[0]);
        let imgFile = new FileReader();
        imgFile.readAsDataURL(file);
        imgFile.onload = function () {
            let imgData = this.result; //base64数据
            setResult(imgData)
            // console.log(imgData);
        }
    }

    // const fileRef = React.useRef();

    // const [fileList, setFileList] = React.useState([]);
    // const fileProps = {
    //     onRemove: file => {
    //         const index = fileList.indexOf(file);
    //         const newFileList = fileList.slice();
    //         newFileList.splice(index, 1);
    //         setFileList(newFileList);
    //         console.log("onRemove  file:" + JSON.stringify(file))
    //         return {
    //             fileList: newFileList,
    //         };
    //     },
    //     beforeUpload: file => {
    //         toBase64(file);
    //         console.log("beforeUpload  file:" + JSON.stringify(file))
    //         setFileList([...fileList, file]);
    //         return false;
    //     },
    //     fileList,
    // };

    function isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }


    function onChange(e) {
        let target = e.target;
        let file = target.files[0];
        if (!file) return;
        if (isFileImage(file)) {
            toBase64(file);
            // console.log("file:" + file.name)
        } else {
            message.warn("请选择一个图片文件");
        }

    }

    return (
        <div className={"mt-3"}>

            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <Row>
                <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>

                    <Input type={"file"}

                           prefix={<FileImageOutlined /> }
                           onChange={onChange}/>

                    {/*<Input.Group compact>*/}
                    {/*    <Search*/}
                    {/*        placeholder='图片地址'*/}
                    {/*        enterButton*/}
                    {/*        size="large"*/}
                    {/*        allowClear*/}
                    {/*        autoFocus*/}
                    {/*        onSearch={onSearch}*/}
                    {/*        ref={searchRef}*/}
                    {/*    />*/}
                    {/*</Input.Group>*/}
                </Col>

            </Row>

            {/*<Upload {...fileProps} >*/}
            {/*    <Button>*/}
            {/*        <UploadOutlined/> Select File*/}
            {/*    </Button>*/}
            {/*</Upload>*/}


            {result &&
            <div className={"m-3 text-center"}>
                <div className={"mt-1"}>

                    <CopyToClipboard className={"float-right mb-2"}
                                     text={result} onCopy={() => message.success('复制成功')}>
                        <Tooltip title={"点击可复制"}>
                            <Button type={"primary"}>复制</Button>
                        </Tooltip>
                    </CopyToClipboard>

                    <TextArea value={result}
                              autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}
                    />

                    {/*<img alt={"图片"} src={result}/>*/}

                </div>
            </div>}
        </div>
    )
}
