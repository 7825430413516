import React, {useState} from 'react';
import {Button, Checkbox, Col, Divider, message, Row} from 'antd';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {DownloadOutlined, PlayCircleOutlined} from '@ant-design/icons';
import {getLinkOpenTarget, isMagnetOrTorrent} from '../util/UrlUtil';
import {isMobile, isTablet} from "react-device-detect";


// import {
//     BrowserView,
//     MobileView
//     , isMobile,
//     TabletView, isTablet, isBrowser
// } from "react-device-detect";
// import isMobilePhone from "validator/es/lib/isMobilePhone";

const CheckboxGroup = Checkbox.Group;

// const plainOptions = ['Apple', 'Pear', 'Orange'];
// const defaultCheckedList = ['Apple', 'Orange'];

export const DownloadCopy = (props) => {

        const ds = props.downloads;
        const urls = ds.map(item => {
            return item.url;
        });
        // console.log("urls:" + JSON.stringify(urls));

        const [downloads, setDownloads] = useState(ds);
        // const ts = downloads.map(item => {
        //     return item.title;
        // });


        const [checkedList, setCheckedList] = React.useState([]);
        const [indeterminate, setIndeterminate] = React.useState(false);
        const [checkAll, setCheckAll] = React.useState(false);


        const onChange = list => {
            setCheckedList(list);
            setIndeterminate(!!list.length && list.length < ds.length);
            setCheckAll(list.length === ds.length);
        };

        const onCheckAllChange = e => {
            setCheckedList(e.target.checked ? urls : []);
            setIndeterminate(false);
            setCheckAll(e.target.checked);
        };

        function onCopy() {
            message.success('复制成功')
        }

        // let reverse = a => [...a].map(a.pop, a)

        function reverseDl() {
            setDownloads(reverseArr(downloads));

        }


        function reverseArr(input) {
            var ret = [];
            for (var i = input.length - 1; i >= 0; i--) {
                ret.push(input[i]);
            }
            return ret;
        }


        function otherCheck() {
            // setCheckedList(vs.filter(el => checkedList.indexOf(el) < 0));
            setCheckedList(urls.filter(el => !checkedList.includes(el)));
        }

        function thunderDownload() {


            let tasks = checkedList.map(url => {
                // console.log("url:" + url);
                // let index = urls.indexOf(url);
                // console.log("vs:" + JSON.stringify(urls));
                // console.log("index:" + index);
                return {name: ds[urls.indexOf(url)].title, url: url}
            });

            // let tasks = checkedList.map(url => {
            //     return {url: url}
            // });
            let taskGroupName = props.title || "下载";
            // console.log("task:" + JSON.stringify(tasks));
            // console.log("taskGroupName:" + taskGroupName);
            // eslint-disable-next-line no-undef
            thunderLink.newTask({
                taskGroupName,
                tasks
            });
        }

        function thunderDownloadOne(url) {
            // eslint-disable-next-line no-undef
            thunderLink.newTask({
                downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
                tasks: [{
                    name: '', // 指定下载文件名（含扩展名）。【若不填此项，将根据下载 URL 自动获取文件名】
                    url: url  // 指定下载地址【必填项】
                }]
            });
        }


        // let gul = isBrowser ? 8 : (isTablet ? 12 : 24);

        let mobileDisable = !checkedList || checkedList.length === 0 || checkedList.length > 1;
        let thunderDisable = !checkedList || checkedList.length === 0;
        if (isMobile || isTablet) {
            thunderDisable = mobileDisable;
        }
        return (
            <>

                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    全选
                </Checkbox>

                <Button className="ml-2  ms-2 " size="small" onClick={otherCheck}>
                    反选
                </Button>


                <Button className="ml-2 ms-2" size="small" onClick={reverseDl}>
                    倒序
                </Button>
                <CopyToClipboard className="ml-1  ms-1" onCopy={onCopy}
                                 text={checkedList.join("\n")}>
                    <Button type="primary" size="small" disabled={!checkedList || checkedList.length === 0}>
                        复制
                    </Button>
                </CopyToClipboard>

                <Button type="primary" disabled={thunderDisable} className="ml-2 ms-2" size="small"
                        onClick={thunderDownload}>
                    迅雷下载
                </Button>


                <Divider/>
                {/*<CheckboxGroup options={titles} value={checkedList} onChange={onChange}/>*/}
                <CheckboxGroup className={"mb-2"} style={{width: '100%'}} value={checkedList} onChange={onChange}>
                    {/*<MobileView>*/}
                    {/*    {downloads.map((download) =>*/}
                    {/*        <div key={download.url}>*/}
                    {/*            <Button type="primary" icon={<DownloadOutlined/>} size="small"*/}
                    {/*                    href={`${download.url}`}*/}
                    {/*                    target="_blank" rel="noopener noreferrer"*/}
                    {/*            />*/}
                    {/*            <Checkbox className={"ml-2"} value={download.url}>{download.title}</Checkbox>*/}
                    {/*        </div>)*/}
                    {/*    }*/}
                    {/*</MobileView>*/}

                    {
                        // !isMobile &&
                        <Row gutter={[{xs: 8, sm: 16, md: 24, lg: 32}, {xs: 8, sm: 16, md: 24, lg: 32}]}>
                            {downloads.map((download) =>
                                <Col key={download.url} span={{xs: 8, sm: 16, md: 24, lg: 32}}>

                                    <CopyToClipboard onCopy={onCopy} text={download.url}>
                                        <Button type="primary" icon={<DownloadOutlined/>}
                                            // href={`${download.url}`}
                                                onClick={(() => {
                                                    thunderDownloadOne(download.url)
                                                })}

                                                size={"small"}
                                                target="_blank" rel="noopener noreferrer"
                                        />
                                    </CopyToClipboard>


                                    <Checkbox
                                        className={"ml-2 ms-2"} value={download.url}>{download.title}</Checkbox>
                                    {download.url && isMagnetOrTorrent(download.url) &&
                                    <Button type="primary" className={"ml-2 ms-2 mr-2 me-2"}
                                            href={`/video/mg?torrentId=${download.url}&name=${download.title}`}
                                            size={"small"}
                                            shape="circle"
                                            icon={<PlayCircleOutlined/>}
                                            target={getLinkOpenTarget()}
                                            rel="noopener noreferrer"
                                    />}

                                </Col>)}
                        </Row>}


                </CheckboxGroup>

            </>
        );
    }
;

// <React.Fragment key={download.url}>
// </React.Fragment>
