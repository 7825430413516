export   function HideKeyboard(){
    // element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
    // element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
    // setTimeout(function() {
    //     element.blur();  //actually close the keyboard
    //     // Remove readonly attribute after keyboard is hidden.
    //     element.removeAttr('readonly');
    //     element.removeAttr('disabled');
    // }, 100);
    let field = document.createElement('input');
    field.setAttribute('type', 'text');
    document.body.appendChild(field);

    setTimeout(function () {
        field.focus();
        setTimeout(function () {
            field.setAttribute('style', 'display:none;');
        }, 50);
    }, 50);
}