import React from "react";
import 'antd/dist/antd.css';

// import {ZhidahaoStoreDataStore} from "../data/DataStore";
import '../App.css'
import {Book} from "../book/Book.js";

import {Site} from "../site/Site";
import {Video} from "../video/Video";
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {Taobao} from "../taobao/Taobao";
import {Sub} from "../sub/Sub";
import {Pan} from "../pan/Pan";
import {Tool} from "../tool/Tool"
import {getProjectTitle} from "../util/ProjectUtil";
import {BackTop, Layout, Menu, Popover} from "antd";
// import {Profiles} from "../data/Urls";
import {isMobile} from "react-device-detect";
import {ShareAltOutlined} from '@ant-design/icons';
import Share from "social-share-react";

import {BookDetail} from "../book/BookDetail";
import {Dosgame} from "../dosgame/Dosgame";

import {DosgameDetail} from "../dosgame/DosgameDetail";

// import {Player} from "../video/Player";
import {BdPageIframe} from "../video/BdPageIframe";
import {IframePlayer} from "../video/IframePlayer";
import {useHistory, useParams} from "react-router-dom";
import {LcPlayer} from "../video/LcPlayer";
import {PanDetailUrl} from "../pan/PanDetailUrl";
import {PanDetail} from "../pan/PanDetail";
import {SubDownloadPage} from "../sub/SubDownloadPage";
import {MvmIframe} from "../video/MvmIframe";
import {Music} from "../music/Music";
// import {isPro} from "../util/MyEnv";
import {MagnetPlayer} from "../video/MagnetPlayer";
import MyFireworks from "../comp/MyFireworks";
import {fireworksDay} from "../util/MyDate";
// import {MyEasyPlayer} from "../video/MyEasyPlayer";
// import {HLSPlayer} from "../video/HLSPlayer";
// import {XG} from "../video/XG";


// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }


export function AppBase(props) {


    let {current, id, play, type, resources} = useParams();
    // console.log("play1:" + play)
    // let query = useQuery();
    // let location = useLocation();
    let pc = props.current;
    if (!pc) {
        pc = current;
    }
    // alert(JSON.stringify(useParams()));
    const [currentKey, setCurrentKey] = React.useState(pc);
    const [detailId, setDetailId] = React.useState(id);
    // const [videoPlay, setVideoPlay] = React.useState(play);

    // React.useEffect(() => {
    //     store('menu_key', (key, val) => {
    //         setCurrentKey(val);
    //         console.log(val) // Processing the data obtained through the test here
    //         return val; // Return data and set store
    //     })
    // }, []);

    let categories = [
        {key: "tool", value: '工具'},
        {key: "book", value: '图书'},
        {key: "taobao", value: '淘宝'},
        {key: "site", value: '网址'},


    ];

    // if (isPro) {


    let video = {key: "video", value: '影视'};
    let music = {key: "music", value: '音乐'};
    let sub = {key: "sub", value: '字幕'};
    let pan = {key: "pan", value: '网盘'};
    // let dosgame = {key: "dosgame", value: 'dos游戏'};
    categories.push(music);
    categories.push(sub);
    categories.push(pan);
    categories.push(video);


    // categories.push(dosgame);


    // categories.push({key: "live", value: '直播'});
    // }


    let logoClassName = isMobile ? 'logo_m' : 'logo';
    let hideLogo = props.hideLogo;
    let navBarHeight = isMobile ? 48 : 64;
    let titleOrName = getProjectTitle();
    const [title] = React.useState(titleOrName);

    // !isHttps() && categories.push({key: "live", value: '直播'},);


    let history = useHistory();

    function handleClick(e) {
        // console.log('click ', e);
        let key = e.key;
        setDetailId(null)
        // console.log("key:" + key)
        setCurrentKey(key);
        window.history.pushState(null, '', '/' + key);
        history.push("/" + key);
    }


    const components = {
        "taobao": <Taobao current="taobao"/>,
        "book": <Book current='book'/>,
        "video": <Video current='video'/>,
        "music": <Video current='music'/>,
        "sub": <Sub current='sub'/>,
        "site": <Site current='site'/>,
        "pan": <Pan current='pan'/>,
        "tool": <Tool current='tool'/>,
        "dosgame": <Dosgame current='dosgame'/>
        // "book_detail": <BookDetail current='book'/>
    }

    const videoComs = {
        // "bd": <Player current="video"/>,
        "mg": <MagnetPlayer current="video"/>,
        "bdframe": <BdPageIframe current="video"/>,
        // "hls": <HLSPlayer current="video"/>,
        // "xg": <XG current="video"/>,
        // "easyPlayer": <MyEasyPlayer current="video"/>,
        "mvmIframe": <MvmIframe current="video"/>,
        "iplay": <IframePlayer current="video"/>,
        "lc": <LcPlayer current="video"/>,
        "lc1": <LcPlayer current="video"/>
    }

    function setUrlFromKey() {
        // console.log("play:" + play)
        // let url = "/" + key;
        // if ("video" === key) {
        //     if ("bd" === play) {
        //         url = url + "/bd";
        //     } else if ("bdframe" === play) {
        //         url = url + "/bdfram";
        //     } else if ("iplay" === play) {
        //         url = url + "/iplay";
        //     }
        // }
        // let search = location.search;
        // url = search ? url + search : url;
        let url = window.location.href;
        window.history.pushState(null, '', url);
        // console.log("url:" + url)
        return url;
    }

    function getComponentFromKey() {
        if (!currentKey) return components['taobao'];
        // console.log("currentKey:" + currentKey);
        // console.log("id:" + detailId);
        let url = window.location.href;
        if (('book' === currentKey) && detailId) {
            url = "/book/subject/" + detailId;
            window.history.pushState(null, '', url);
            return <BookDetail current='book'/>;
        }

        if (('dosgame' === currentKey) && detailId) {
            url = "/dosgame/subject/" + detailId;
            window.history.pushState(null, '', url);
            return <DosgameDetail current='dosgame'/>;
        }
        if ('pan' === currentKey) {
            window.history.pushState(null, '', url);
            // alert(resources)
            if ("resources" === resources) {
                // console.log("panId:"+id);
                if (id) {
                    return <PanDetail current={currentKey}/>;
                }
                return <PanDetailUrl current={currentKey}/>
            }
            return components[currentKey];
        }
        if ("sub" === currentKey) {
            if ("dl" === type) {
                window.history.pushState(null, '', url);
                return <SubDownloadPage current={currentKey}/>
            }
        }


        if ("music" === currentKey) {
            window.history.pushState(null, '', url);
            return <Music current={currentKey}/>
        }

        if ('video' === currentKey) {
            setUrlFromKey(currentKey);
            if (play) {
                // console.log("play:" + play);
                if ("play" === play) {
                    if (type && id) {
                        // console.log("type:" + type);
                        return videoComs[type]
                    }
                }
                return videoComs[play]
            }

        }
        window.history.pushState(null, '', url);
        return components[currentKey];
    }


    return <div
        // store={ZhidahaoStoreDataStore}
    >
        <HelmetProvider>
            <Helmet>
                <title>{getProjectTitle()}</title>
                <meta name="description" content="一键直达"/>
                <meta name="theme-color" content="#008f68"/>
            </Helmet>
        </HelmetProvider>


        {
            fireworksDay() &&
            current === 'taobao' && <MyFireworks/>}
        {/*<MyFireworks/>*/}
        <Layout id="app_layout">
            {/*<div className="logo" />*/}

            <Layout.Header mode="horizontal"
                           style={{position: 'fixed', zIndex: 1, width: '100%', height: navBarHeight}}>
                {!hideLogo && !isMobile && <img
                    // alt={this.state.title}
                    alt={title}
                    // className={this.logoClassName}
                    className={logoClassName}
                    src={"/logo.png"}/>
                }
                <Menu
                    theme="dark"
                    mode="horizontal"
                    style={{
                        lineHeight: navBarHeight + 'px'
                        // ,marginTop:-(navBarHeight+3),marginLeft:'8rem'}
                    }}
                    // selectedKeys={[current]}
                    selectedKeys={[currentKey]}
                    onClick={handleClick}
                    // onSelect={handleClick}

                >
                    {
                        // this.state.categories.
                        categories && categories.map(cat => <Menu.Item key={cat.key}>
                            {/*<a href={`/${cat.key}`}*/}
                            {/*    // target="_blank"*/}
                            {/*   rel="noopener noreferrer">*/}

                            {cat.value}
                            {/*</a>*/}
                        </Menu.Item>)}
                </Menu>
            </Layout.Header>
            <Layout.Content>

                {getComponentFromKey()}

            </Layout.Content>
        </Layout>

        <BackTop style={{right: '15px'}}/>

        {!isMobile && <div style={{
            textAlign: 'right',
            alignItems: 'center',

            justifyContent: 'center',
            position: 'fixed',
            // display: 'block; bottom: 30px',
            display: 'block',
            right: '15px',
            top: '50%',
            // left:50%;

        }}>
            <Popover
                trigger="click"
                content={<Share
                    // url={window.location.href}
                    // title={title}
                    wechatQrcodeLevel='H'
                    placement="bottomRight"
                    sites={['qq', 'wechat', 'weibo', 'qzone', 'douban', 'google', 'facebook', 'twitter']}
                    // disabled={
                    //     ['google', 'facebook', 'twitter']}
                />}
                wechatQrcodeSize={90}
                title="分享">
                <ShareAltOutlined/>
            </Popover>

        </div>}
    </div>
}
