import React
    from 'react'
import fetch from '../libs/fetch'
import axios from 'axios';

// import {useQuery} from 'react-query'
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {isMobile} from 'react-device-detect'
import {List, Avatar, Button as AButton} from 'antd';
// import {Icon} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {getLinkOpenTarget} from "../util/UrlUtil";
import {useInfiniteQuery} from 'react-query'
import {Button} from 'reactstrap';

export const SubPageLoadMore = (props) => {

        let keyword = props.keyword;
        let doubanId = props.doubanId;
        // let nextId = 0;
        // let cursor = 0;
        const {
            status,
            data,
            error,
            isFetching,
            isFetchingMore,
            fetchMore,
            canFetchMore,
        } = useInfiniteQuery(
            "subPageLoadMore" + keyword + doubanId,
            (key, cursor = 0) => fetch(RestUrls[DataTypes.SUB_KEYWORD_PAGINATION]
                + "?nextId=" + cursor + "&keyword=" + encodeURIComponent(keyword) +"&doubanId=" + doubanId + "&pageSize=10"
    ),
        {
            getFetchMore: (lastGroup,
                           // allGroups
            ) => lastGroup.nextId,
        }
    )
        ;


        function downloadFromId(id) {
            let url = RestUrls[DataTypes.SUB_DOWNLOAD] + "/" + id;
            axios.get(url)
                .then(function (response) {
                    let data = response && response.data;
                    if (data) {
                        let dv = data.value;
                        let key = data.key;
                        if (dv) {
                            window.location.href = dv;
                        } else {
                            window.location.href = key;
                        }
                    }

                    // handle success
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }

        function onClick(item) {
            let download = item.download;
            let id = item.id;
            // console.log("download:" + download);
            // console.log("id:" + id);
            if (download) {
                let goUrl = download.indexOf("zmk.pw") !== -1 ? "/sub/dl?url=" + download + "&title=" + item.title : download;
                window.open(goUrl, "_blank");
            } else {
                downloadFromId(id);
            }
        }

        function getDescription(item) {
            let description = "";
            let version = item.version;
            if (version) description = description + "版本:" + version;
            let pub = item.team ? item.team : item.pubUser;
            if (pub) description = description + " " + pub;
            return description;
        }

        function getTitle(item) {
            if (!item) return '';
            if (!item.doubanId) return item.title;
            let href = "/video?q=" + encodeURIComponent(item.title) + "&doubanTitle=" + item.doubanId;
            return <a style={{color: "blue"}} href={href} target={getLinkOpenTarget()}>{item.title}</a>;
        }

        return (
            // flag &&
            // <div>
            <div>
                {status === 'loading' ? (
                    <p>Loading...</p>
                ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>
                        {data !== null && data !== undefined && data.map((page, i) => (
                            <List key={i}
                                  dataSource={page.data}
                                  // itemLayout="horizontal"
                                  itemLayout="vertical"

                                // loadMore={loadMore}
                                  renderItem={item => (
                                      <List.Item key={item.id}

                                                 actions={
                                                     [
                                                         <AButton type="link" onClick={onClick.bind(this, item)}
                                                                  key="list-download">
                                                             <DownloadOutlined/>
                                                         </AButton>,
                                                     ]
                                                 }

                                      >
                                          <div
                                          >
                                              <List.Item.Meta
                                                  avatar={
                                                      !isMobile && item.image && <Avatar
                                                          src={item.image}/>
                                                  }

                                                  title={getTitle(item)}
                                                  description={getDescription(item)}
                                              />


                                              <div>
                                                  <span className="m-1">{item.language}</span>
                                                  <span className="m-1">{item.format}</span>
                                                  <span className="m-1">{item.size}</span>
                                              </div>
                                          </div>
                                      </List.Item>
                                  )}
                            >
                            </List>


                        ))}
                        <div className="text-center mt-2 mb-2">
                            <div>
                                <Button
                                    onClick={() => fetchMore()}
                                    disabled={!canFetchMore || isFetchingMore}
                                    color={(!canFetchMore || isFetchingMore) ? 'secondary' : (canFetchMore ? 'primary' : 'secondary')}
                                >
                                    {isFetchingMore
                                        ? '加载中'
                                        : canFetchMore
                                            ? '加载更多'
                                            : '到底了'}
                                </Button>
                            </div>

                            <div>
                                {isFetching && !isFetchingMore ? '正在获取数据' : null}
                            </div>
                        </div>
                    </>)
                }
            </div>
        )
    }
;




