import AppTemp from "../AppTemp";
import {HelmetProvider} from "react-helmet-async";
import {Helmet} from "react-helmet";
import {ProjectConstant} from "../data/ProjectConstant";
import {Card, CardHeader} from "reactstrap";
import {LeftCircleTwoTone, MobileTwoTone} from "@ant-design/icons";
import {Popover} from "antd";
import MyQRCode from "../comp/MyQRCode";
import {
    isMobile
    // , isTablet
} from "react-device-detect";
import React from "react";

export function PlayIframe(props) {


    return <AppTemp current={props.current || ''} showFooter={false}>
        <HelmetProvider>
            <Helmet>
                <title>{ProjectConstant.PROJECT_NAME}-{props.title}</title>
            </Helmet>
        </HelmetProvider>

        <Card style={{position: 'fixed', zIndex: 1, width: '100%', height: 48, marginLeft: '-0.3rem'}}>
            <CardHeader>


                <span className="float-left mr-2"
                      style={{
                          marginTop: '-0.2rem'
                      }
                      }
                      onClick={() =>
                          // window.history.back()
                          window.open("/video?filmId=" + props.id, "_self")
                      }
                >
                    {/*<Icon type="left-circle" theme="twoTone"/>*/}
                    <LeftCircleTwoTone/>
                </span>

                <span>{props.title}</span>

                {/*<BrowserView>*/}
                <Popover className="float-right mr-2 mt-1"
                         content={<MyQRCode/>}
                         trigger={isMobile ? 'hover' : 'click'}
                         title="手机观看">

                    {/*<Icon type="mobile" theme="twoTone"/>*/}
                    <MobileTwoTone/>
                </Popover>
                {/*</BrowserView>*/}
            </CardHeader>

        </Card>


        <iframe width="100%"
                height={isMobile ? 400 : 700}
                className="mt-1"
                title={props.title} src={props.url}
                allowFullScreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
        />

    </AppTemp>
}
