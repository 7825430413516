import React, {useState} from 'react';
import {Collapse, Button,Card
    // , Col
}
    from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { toast } from 'react-toastify';
import {
    // BrowserView,
    // MobileView,
    // isBrowser,
    isMobile
} from "react-device-detect";


export const DownloadList = (props) => {
    const collapseInit = isMobile && props.downloads.length > 10;
    const initText = collapseInit?'展开':'收起';
    const [collapse, setCollapse] = useState(!collapseInit);
    // let [copied] = useState(false);
    const [status, setStatus] = useState(initText);
    const [downloads] = useState(props.downloads);
    // const onEntering = () => {
    //     setStatus('正在展开');
    // };
    const onEntered = () => {
        // let flag = (downloads.length > 5);
        // console.log("collapse:"+collapse);
        // setCollapse({collapse : flag});
        setStatus('收起');
    };
    // const onExiting = () => setStatus('正在关闭');

    const onExited = () => setStatus('展开');


    const toggle = () => {
        setCollapse(!collapse);
        setStatus(collapse?'展开':'收起');
    };

    const downlaodTypes = {magnet:'磁力',ed2k: '电驴',all: '所有'};

    return (
        <Card >

            <div style={{marginBottom: '1rem'}}>
                <Button color="primary" className="m-1" onClick={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    toggle()
                  }
                }>{status}下载</Button>
                    <React.Fragment>
                        {
                            Object.keys(downlaodTypes).map(key =>
                                <DownloadTypeAll key={key} downloads={downloads} label={`复制${downlaodTypes[key]}`} type={key}/>
                            )
                        }

                        {/*<DownloadTypeAll downloads={downloads} label='复制电驴' type='ed2k'/>*/}
                        {/*<DownloadTypeAll downloads={downloads} label='复制所有' type='all'/>*/}
                        {/*<DownloadTypeAll downloads={downloads} typeLable='复制度盘' type='baidu'/>*/}
                    </React.Fragment>



            </div>
            {/*<h5>Current state: {status}</h5>*/}
            <Collapse
                isOpen={collapse}
                // onEntering={onEntering}
                onEntered={onEntered}
                // onExiting={onExiting}
                onExited={onExited}
            >
                {
                    downloads.map((download, index) =>
                        <CopyToClipboard key={`${download.title}_${index}`}
                                         text={download.type === 'baidu' ? download.password : download.url}
                                         onCopy={()=>toast(download.type==='baidu'?"点击打开，粘贴密码":"复制成功")}
                        >
                            <Button className="m-1" color="info" outline size={isMobile?'sm':''} href={`${download.url}`}
                                    target="_blank" rel="noopener noreferrer"
                                // className="list-group-item list-group-item-action"
                            >
                                {download.title}</Button>
                        </CopyToClipboard>
                    )
                }
            </Collapse>
        </Card>
    );
};

function DownloadTypeAll(props) {
    const type = props.type;
    const label = props.label;
    const downloads = props.downloads;
    let filterDownloads;
    // console.log("type:"+type);
    if (type === 'all') {
        filterDownloads = downloads;
    } else {
        filterDownloads = downloads && downloads.filter(item => item.type === type);
    }
   function onCopy(){
        toast(label+"成功",2000);
    }

    return filterDownloads && filterDownloads.length > 0 &&
        <CopyToClipboard className="m-1" onCopy = {onCopy}
                         text={filterDownloads.map((item) => item.url).join("\n")}>
        <Button color="info"
            // onClick={copyMagnet}
        >{label}</Button>
    </CopyToClipboard>


}

