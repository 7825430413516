import React from 'react';
import AppTemp from "../AppTemp";
import {SubPageLoadMore} from './SubPageLoadMore';
import {Input} from 'antd';
import {getUrlParameter} from "../util/UrlUtil";
import {useLocation} from "react-router-dom";

export function Sub(props) {
    // const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [keyword, setKeyword] = React.useState('');
    const [doubanId, setDoubanId] = React.useState('');

    let location = useLocation();
    console.log("location:" + JSON.stringify(location));

    React.useEffect(() => {
        let href = window.location.href;
        setKeyword(getUrlParameter(href, "keyword"),)
        setDoubanId(getUrlParameter(href, "doubanId"),)
    }, []);


    function onChange(e) {
        let keyword = e.target.value;
        setKeyword(keyword)

    }

    // function onSearch(value) {
    //     // console.log(value);
    //     value && setKeyword(value)
    // }

    return <AppTemp current={props.current}>
        <div className="container mt-2">
            <Input
                placeholder="关键字"
                size="large"
                allowClear
                onChange={onChange}
            />
        </div>
        <div className="container mt-2">
            <SubPageLoadMore keyword={keyword} doubanId={doubanId}/>
        </div>

    </AppTemp>


}
