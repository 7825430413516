import React from 'react';
import {Suggests} from "./Suggests";
import {DataTypes} from "../data/Types";
import {VideoDetail} from "./VideoDetail";
import {RestUrls} from "../data/Urls";
import axios from "axios";
import '../video.css';
import {canPlayUrl, getAsUriParameters, getLinkOpenTarget, isMagnetOrTorrent, isUrl} from "../util/UrlUtil";
import {Spinner} from 'reactstrap';
// import {MovieSlider} from "./MovieSlider";
import {MovieSliderHook} from "./MovieSliderHook";
import {BrowserView} from "react-device-detect";
import {loadVideoDetailLocalData} from "../data/ActionCreators"
// import { message} from 'antd';
// import {Layout} from "antd";
import AppTemp from "../AppTemp";
import {IndexDataVideo} from './IndexDataVideo';
import {SearchModel} from "../comp/SearchModel";
import {useLocation} from "react-router-dom";
import {useMount} from "ahooks";
// import {createBrowserHistory} from 'history';
// const myHistory = createBrowserHistory();
// import { useHistory } from "react-router-dom";


export function Video(props) {
    // let myHistory = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    //localVideoDetail || {}
    let query = useQuery();
    let from = query.get("from");
    const fromFlag = from === "player";
    const localDetailData = fromFlag && loadVideoDetailLocalData(DataTypes.VIDEO_LOCAL_DETAIL);
    let localVideoDetail = localDetailData && localDetailData.payload.data;
    const [videoDetail, setVideoDetail] = React.useState();
    // const [suggestions, setSuggestions] = React.useState([]);
    const [qrSourceUrl, setQrSourceUrl] = React.useState(window.location.href);
    const [loadingVideoFlag, setLoadingVideoFlag] = React.useState(false);
    const [showVideoDetail, setShowVideoDetail] = React.useState(!!localVideoDetail);
    const [showNewVideo, setShowNewVideo] = React.useState(!localVideoDetail);
    const [value, setValue] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [title, setTitle] = React.useState('');

    // let loc = useLocation();


    const changeShowStatus = () => {
        if (!videoDetail) {
            setShowNewVideo(true);
            setShowVideoDetail(false);
        }
    };


    // console.log("loc:"+JSON.stringify(loc));
    const qt = query.get("q");
    const url = query.get("url");
    const year = query.get("year");
    const filmId = query.get("filmId");
    const doubanTitle = query.get("doubanTitle");

    // console.log("qt:" + qt);
    // console.log("filmId:" + filmId)


    const getDirectData = React.useCallback(item => {
        let directQuery = item.title;
        // console.log("item:" + JSON.stringify(item));

        // console.log("getDirectData:" + directQuery);
        let flag = !directQuery && !item.filmId;
        if (flag) return;

        if (isMagnetOrTorrent(directQuery)) {
            window.open("/video/mg?torrentId=" + directQuery, getLinkOpenTarget());
            return;
        }

        let xg = "/video/xg?url=";
        if (directQuery && canPlayUrl(directQuery)) {
            window.open(xg + directQuery, getLinkOpenTarget());
            return;
        }

        if (isUrl(directQuery)) {
            let arr = ["iqiyi.com", "youku.com", "v.qq.com", "mgtv.com"];

            for (let el of arr) {
                let flag = directQuery.indexOf(el) !== -1;
                if (flag) {
                    window.open(directQuery, getLinkOpenTarget());
                    // break;
                    return;
                }
            }


            let u = RestUrls[DataTypes.VIDEO_M3U8] + "/from?url=" + directQuery
            fetch(u)
                .then(response => response.json())
                .then(data => {
                    if (!data || !data.url) {
                        window.open(directQuery, getLinkOpenTarget());
                        return;
                    }
                    if (data.url) {
                        let u = xg + data.url;
                        if (data.title) {
                            u = u + "&name=" + data.title;
                        }
                        window.open(u, getLinkOpenTarget());
                    }
                    // setChangeFromOther(false);
                });

            return;

        }

        directQuery = directQuery && directQuery.trim();
        // window.localStorage.setItem(DataTypes.VIDEO_LOCAL_DETAIL, null);
        setLoadingVideoFlag(true);
        // console.log("loadingVideoFlag:"+loadingVideoFlag)
        setShowNewVideo(false);
        setShowVideoDetail(false);
        const url = RestUrls[DataTypes.VIDEO_DETAIL];
        // console.log(url);
        // let that = this;
        let params = {
            q: directQuery,
        };
        if (item.year) params.year = item.year;
        if (item.url) params.url = item.url;
        if (item.doubanTitle) params.doubanTitle = item.doubanTitle;
        if (item.filmId) params.filmId = item.filmId;

        let urlParameters = getAsUriParameters(params);
        // console.log("urlParameters:" + urlParameters);
        // console.log("params:"+JSON.stringify(params));


        let qu = urlParameters && window.location.protocol + '//' + window.location.host + window.location.pathname + "?" + urlParameters;

        setQrSourceUrl(qu);
        window.history.pushState({}, '', qu);
        // console.log("url:" + qu);
        axios.get(url, {
            params: params
        }).then(res => {
            setLoadingVideoFlag(false);
            let detail = res && res.data;
            let visible = !res || !res.data || !res.data.title;

            detail = visible ? null : detail;
            // console.log(JSON.stringify(detail))
            let t = detail && detail.title;
            let qt = t ? t : title;
            // if (!visible) {
            //     localStorage.setItem(DataTypes.VIDEO_LOCAL_DETAIL, JSON.stringify(res.data));
            //
            // }
            setTitle(qt);

            // console.log(JSON.stringify(detail))
            // console.log("video_detail:" + JSON.stringify(videoDetail))
            setVideoDetail(detail);
            // setTimeout(console.log("newDetail:" + JSON.stringify(videoDetail)),2000);
            // console.log("new video_detail:" + JSON.stringify(videoDetail))
            // console.log("show new video1:" + showNewVideo)
            setShowNewVideo(false);
            // console.log("show new video2:" + showNewVideo)
            // console.log("show video detail1:" + showVideoDetail)
            setShowVideoDetail(true);
            // console.log("show video detail2:" + showVideoDetail)
            // console.log("directQuery:" + directQuery)
            setValue(directQuery);
            setVisible(visible);

            // console.log("showNewVideoDetail:" + showNewVideo);
            // console.log("showVideoDetail:" + showVideoDetail);

            // if(visible){
            //     // message.warn("抱歉，没有你要找的影片")
            // }


        }).catch(function (error) {
            // console.log()
            console.log(JSON.stringify(error));

            setLoadingVideoFlag(false);
        })
    }, [title]);


    const queryItem = {
        title: qt,
        url: url,
        filmId: filmId,
        doubanTitle,
        year: year
    };

    window.addEventListener('popstate', function (event) {
        // Log the state data to the console;
        let location = event.target.window.location;
        if (location) {
            let search = location.search;
            let path = location.pathname;
            // console.log(event.target.window.location);
            if ("/video" === path) {
                if (!search) {
                    setShowNewVideo(true);
                    setShowVideoDetail(false);
                } else {
                    getDirectDataItemQuery()
                }


            }
        }


    });


    // React.useEffect(function setupListener() {
    //     // function historyEvent() {
    //     //     console.log('Do resize stuff')
    //         // let location = event.target.window.location;
    //         // if (location) {
    //         //     let search = location.search;
    //         //     let path = location.pathname;
    //         //     // console.log(event.target.window.location);
    //         //     if ("/video" === path && !search) {
    //         //         setShowNewVideo(true);
    //         //         setShowVideoDetail(false);
    //         //     }
    //         // }
    //
    //     // }
    //     // window.addEventListener('popstate', historyEvent)
    //
    //     const item = {
    //         title: qt,
    //         url: url,
    //         filmId: filmId,
    //         doubanTitle,
    //         year: year
    //     };
    //
    //
    //     getDirectData(item);
    //
    //
    //
    //     // return function cleanupListener() {
    //     //     window.removeEventListener('popstate', historyEvent)
    //     //     // window.removeEventListener('popstate');
    //     //
    //     //
    //     // }
    //
    //
    // });


    function getDirectDataItemQuery() {

        getDirectData(queryItem);
        // }
    }

    useMount(() => {
        // console.log("filmId:"+filmId);
        // if (filmId)
        // {
        getDirectDataItemQuery();
    }, [qt, filmId]);

    // React.useEffect(() => {
    //     if (qt) {
    //         const item = {
    //             title: qt,
    //             url: url,
    //             filmId: filmId,
    //             doubanTitle,
    //             year: year
    //         };
    //         getDirectData(item);
    //     }
    //
    //     // document.addEventListener("click", this._handleDocumentClick, false);
    // }, []);
    // qt, url, filmId, doubanTitle, year, getDirectData

// componentWillUnmount() {
//     // document.removeEventListener("click", this._handleDocumentClick, false);
//     // document.removeEventListener("keydown", this._handleKeyDown);
// }

    const directQuery = (item) => {
        let newItem = {title: item.title, year: item.year, filmId: item.filmId, url: item.url, actor: item.actor};
        getDirectData(newItem);

    };

    return (
        <AppTemp current={props.current || ''}>
            <div
                // style={{position: 'fixed', zIndex: 1, width: '100%'}}
            >
                <Suggests
                    backNewVideo={changeShowStatus}
                    getDirectData={getDirectData}
                    loadingText={loadingVideoFlag ? "加载中" : "直达"}
                />
            </div>

            {value &&
            <SearchModel handleCancel={() => setVisible(false)} keyword={value}
                         visible={visible}/>
            }

            <div
                style={{marginTop: '1rem'}}
            >
                {showNewVideo && <BrowserView>
                    <MovieSliderHook directQuery={function (item) {
                        getDirectData(item)
                    }}/>

                </BrowserView>
                }

                {
                    showNewVideo &&
                    <IndexDataVideo
                        directQuery={function (item) {
                            getDirectData({filmId: item.filmId, title: item.title, year: item.year, url: item.url})
                        }}
                    />
                }
                {/*{console.log(loadingVideoFlag)}*/}

                {

                    loadingVideoFlag ? <div className="text-center loading">
                        <Spinner style={{width: '3rem', height: '3rem'}} type="grow"/>
                    </div> : videoDetail && videoDetail.title && showVideoDetail &&
                        <VideoDetail videoDetail={videoDetail}
                                     qrSourceUrl={qrSourceUrl}
                                     backNewVideo={changeShowStatus}
                                     directQuery={directQuery}
                        />



                }
            </div>



        </AppTemp>
    );

}





