import React, {useState} from 'react';
import {
    Collapse, Button,
    Card
    // , Col
}
    from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {toast} from 'react-toastify';
import {
    isMobile
} from "react-device-detect";


export const PbDownloadList = (props) => {
    const dSize = props.downloads.length;
    const collapseInit = (isMobile && dSize > 10) || dSize > 15;
    const [collapse, setCollapse] = useState(!collapseInit);
    const [status, setStatus] = useState(collapseInit ? '展开' : '收起');
    const [downloads] = useState(props.downloads);
    const onEntered = () => {
        setStatus('收起');
    };
    const onExited = () => setStatus('展开');
    const toggle = () => {
        setCollapse(!collapse);
        setStatus(collapse ? '展开' : '收起');
    };
    return (
        <Card>
            <div style={{marginBottom: '1rem'}}>
                <Button color="primary" className="m-1" onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    toggle()
                }
                }>{status}下载</Button>
            </div>
            <Collapse
                isOpen={collapse}
                onEntered={onEntered}
                onExited={onExited}
            >
                {
                    downloads.map((download, index) =>
                        <CopyToClipboard key={`${download.title}_${index}`}
                                         text={download.type === 'baidu' ? download.password : download.url}
                                         onCopy={() => toast(download.type === 'baidu' ? "点击打开，粘贴密码" : "复制成功")}>
                            <Button className="m-1" color="info" outline size={isMobile ? 'sm' : ''}
                                    href={`${download.url}`}
                                    target="_blank" rel="noopener noreferrer"
                            >
                                {download.title}</Button>
                        </CopyToClipboard>
                    )
                }
            </Collapse>
        </Card>
    );
};


