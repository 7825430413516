import React, {useState} from 'react';
import {VideoLike} from "./VideoLike";
import {getAsUriParameters, getLinkOpenTarget} from "../util/UrlUtil";

export const VideoLikeOther = (props) => {
    const [likes] = useState(props.likes);

    const jumpToLink = item => {
        const year = item.year;
        const url = item.url;
        const title = item.title;
        const filmId = item.filmId;

        // let link = "/video?q=" + title;
        // if (year) link = link + "&year=" + year;
        // if (filmId) link = link + "&filmId=" + filmId;
        // if (url) link = link + "&url=" + url;
        // alert(link);
        let params = {
            q: title,
            year: year,
            url: url,
            filmId: filmId
        };

        let urlParameters = getAsUriParameters(params);
        let jump = urlParameters?"/video?"+urlParameters:"/video";
        window.open(jump, getLinkOpenTarget());
        // , "_blank");
    };

    return <VideoLike likes={likes} directQuery={jumpToLink}/>


};

