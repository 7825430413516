import React from 'react';
import {Col, Input, message, Row} from "antd";
import jsonp from 'jsonp';
import trim from "validator/es/lib/trim";
import {isMobile} from "react-device-detect";
import isIp from 'validator/lib/isIP'
import {getAsUriParameters} from "../util/UrlUtil";
import Script from 'react-load-script'
import {getIpAddress, setIpInfo} from "../util/IpUtil";

const {Search} = Input;

export function IpQuery(props) {
    const searchRef = React.useRef();
    const [value, setValue] = React.useState();
    const [info, setInfo] = React.useState();
    const [first, setFirst] = React.useState(true);

    function onSearch(value) {
        // console.log("come from onSearch:" + value)
        if (!value) return;
        value = trim(value);
        setValue(value);
        if (!isIp(value)) {
            message.warning('输入的ip不对');
            return;
        }
        setFirst(false);
        queryIp(value);
        searchRef.current.blur();

    }

    let ipAddress = getIpAddress();
    React.useEffect(() => {
        // alert(ipAddress)
        if (ipAddress) {
            // console.log("come from:" + ipAddress);
            queryIp(ipAddress);
        }

    }, [ipAddress]);

    function queryIp(value) {
        let timestamp1 = (new Date()).valueOf();
        let callback = "jQuery110205464838398809442_" + timestamp1;
        let opt = {
            name: callback,
            timeout: 5000,
        }

        let dp = {
            "query": value,
            "t": timestamp1 + "",
            "_": (timestamp1 + 3) + "",
            "cb": callback,
            "resource_id": "6006",
            "ie": "utf8",
            "tn": "baidu",
            "format": "json",
            "oe": "gbk"

        };
        opt.param = getAsUriParameters(dp);
        let url = "https://sp0.baidu.com/8aQDcjqpAAV3otqbppnN2DJv/api.php";
        // let url = "https://restapi.amap.com/v3/ip?key=0113a13c88697dcea6a445584d535837";
        jsonp(url, opt, (error, data) => {
            // alert("result:" + data);
            if (data && data.data) {
                let da = data.data;
                if (Object.prototype.toString.call(da) === '[object Array]') {
                    if (da.length > 0) {
                        setInfo(da[0]);
                    }
                }
            }
        });
    }

    function onChange(e) {
        let v1 = e.target.value;
        setValue(v1);
    }

    // const [scriptLoaded, setScriptLoaded] = React.useState();
    // const [scriptError, setScriptError] = React.useState();


    function handleScriptCreate() {
        // setScriptLoaded(true)
    }

    function handleScriptError() {
        // setScriptError(true);
    }

    function handleScriptLoad() {
        // setScriptLoaded(true)
        // eslint-disable-next-line no-undef
        let info = returnCitySN;
        if (info) {
            setIpInfo(info);
            let ip = info['cip'];
            if (ip && isIp(ip)) {
                setFirst(true);
                queryIp(ip);
            }
        }
    }

    return (
        <div className={"mt-3"}>

            {
                !ipAddress && <Script
                    url="https://pv.sohu.com/cityjson?ie=utf-8"
                    onCreate={handleScriptCreate.bind(this)}
                    onError={handleScriptError.bind(this)}
                    onLoad={handleScriptLoad.bind(this)}
                />
            }
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <Row>
                <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>
                    <Input.Group compact>
                        <Search
                            placeholder='ip地址'
                            enterButton
                            size="large"
                            value={value}
                            allowClear
                            autoFocus
                            onSearch={onSearch}
                            onChange={onChange}
                            ref={searchRef}
                        />
                    </Input.Group>
                </Col>
            </Row>

            {info &&
            <div className={"m-3 text-center"}>
                <span>您{!first ? '查' : ''}的ip是[{info.origip}]</span><span
                className={"ml-2"}>来自 {info.location}</span>
            </div>}
        </div>
    )
}
