import React from "react";
import {
    useLocation
} from "react-router-dom";
import AppTemp from "../AppTemp";
import {HelmetProvider} from "react-helmet-async";
import {Helmet} from "react-helmet";
import {ProjectConstant} from "../data/ProjectConstant";
import {Card, CardHeader} from "reactstrap";
import {Popover} from "antd";
import {LeftCircleTwoTone, MobileTwoTone} from '@ant-design/icons';
import MyQRCode from "../comp/MyQRCode";
import {
    isMobile
    // , MobileView
} from "react-device-detect";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function BdPageIframe(props) {
    let query = useQuery();
    let qu = query.get("url");
    if (qu.indexOf("baidu.com") !== -1) {
        qu = qu.replace("baidu.com", "bde4.com");
    }
    // alert(qu);
    let url = "https:" + qu;
    let title = query.get("title");
    // console.log(qu)
    // let margin = isMobile ? '-3rem' : '0rem';
    return <div>
        <AppTemp current={props.current || ''}>
            <HelmetProvider>
                <Helmet>
                    <title>{ProjectConstant.PROJECT_NAME}-{title}</title>
                </Helmet>
            </HelmetProvider>

            <Card style={{position: 'fixed', zIndex: 1, width: '100%', height: 48, marginLeft: '-0.3rem'}}>
                <CardHeader>


                <span className="float-left mr-2"
                      style={{
                          marginTop: '-0.2rem'
                      }
                      }
                      onClick={() => window.open("/video?from=player", "_self")}
                >
                    {/*<Icon type="left-circle" theme="twoTone"/>*/}
                    <LeftCircleTwoTone/>
                </span>

                    <span>{title}</span>

                    {/*<BrowserView>*/}
                    <Popover className="float-right mr-2 mt-1"
                             content={<MyQRCode/>}
                             trigger={isMobile ? 'hover' : 'click'}
                             title="手机观看">

                        {/*<Icon type="mobile" theme="twoTone"/>*/}
                        <MobileTwoTone/>
                    </Popover>
                    {/*</BrowserView>*/}
                </CardHeader>

            </Card>


            <iframe width="100%" height="800" title={title} src={url}
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
            />

        </AppTemp>

    </div>
}
