import React from 'react';
import {Col, Input, Row, message, Tooltip, Button} from "antd";
import {isMobile} from "react-device-detect";
import {upCalc} from "../util/MoneyUtil";
import CopyToClipboard from 'react-copy-to-clipboard';

const {Search} = Input;

export function Number2Chinese(props) {
    const searchRef = React.useRef();
    const [value, setValue] = React.useState();
    const [number, setNumber] = React.useState();

    function onSearch(number) {
        // console.log("come from onSearch:" + value)
        setNumber(number)
        searchRef.current.blur();
    }

    React.useEffect(() => {
        if (number === undefined || number === '') {
            setValue("");
            return;
        }
        if (isNaN(number)) return;
        setValue(upCalc(number));
    }, [number]);

    function onChange(e) {
        let v1 = e.target.value;
        setNumber(v1);
    }


    return (
        <div className={"mt-3"}>

            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>
            <Row>
                <Col span={isMobile ? 18 : 12} offset={isMobile ? 3 : 6}>
                    <Input.Group compact>
                        <Search
                            placeholder='数字'
                            enterButton
                            size="large"
                            value={number}
                            type='number'
                            allowClear
                            autoFocus
                            onSearch={onSearch}
                            onChange={onChange}
                            ref={searchRef}
                        />
                    </Input.Group>
                </Col>
            </Row>

            {value &&
            <div className={"m-3 text-center"}>
                <span>{value}</span>
                <div className={"mt-1"}>
                    <CopyToClipboard text={value} onCopy={() => message.success('复制成功')}>
                        <Tooltip title={"点击可复制"}>
                            <Button type={"primary"}>复制</Button>
                        </Tooltip>
                    </CopyToClipboard>

                </div>
            </div>}
        </div>
    )
}
