import React from 'react';

import {Button, Input, message, Radio, Space} from 'antd';

import {isMobile} from "react-device-detect";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {Wubi86, Wubi98} from "../data/WubiData";
import {isChinese} from "../util/MyStringUtil";

const {
    TextArea
    // , Search
} = Input;

// const {Option} = Select;
// Object.filter = (obj, predicate) =>
//     Object.fromEntries(Object.entries(obj).filter(predicate));

export function Hanzi2Wubi(props) {


    const [content, setContent] = React.useState();
    const [result, setResult] = React.useState();
    const [mode, setMode] = React.useState('86');


    function getValueFromKey(object, source) {
        let k = Object.keys(object).filter(key => key === source);
        return object.hasOwnProperty(k) ? object[k] : '';


    }

    const tran = React.useCallback(() => {
        if (!content) {
            setResult("");
            return;
        }
        // if (!isAllChinese(content)) {
        //     // message.info("不是汉字");
        //     return;
        // }
        let Wubi = "86" === mode ? Wubi86 : Wubi98;

        let cs = content.split("");


        let resultArray = cs.map(c =>
            isChinese(c) ? getValueFromKey(Wubi,c) : ''
        )
        // console.log("resultArray:"+JSON.stringify(resultArray))
        let result = resultArray.join(" ");
        setResult(result);
        // console.log("result:" + result+"**");
        // console.log("filtered:" + JSON.stringify(''));


    }, [content, mode]);


    React.useEffect(() => {

        tran()
    }, [tran]);


    function textChange(e) {
        let value = e.target.value;
        setContent(value);
    }


    const textAreaRef = React.useRef();


    function handleChange(e) {
        let mode = e.target.value;
        setMode(mode);
        // console.log("value:" + mode)
    }

    return (

        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>

            <div
                className={"m-2"}
            >


                {/*<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className={"mt-3"}>*/}


                {/*    <Col className="gutter-row" span={12}>*/}
                        <Radio.Group
                            defaultValue={"86"}
                            onChange={handleChange}>
                            <Radio.Button value="86">86五笔</Radio.Button>
                            <Radio.Button value="98">98五笔</Radio.Button>
                        </Radio.Group>
                        <TextArea
                            ref={textAreaRef}
                            className={"mt-3 mb-3"}
                            placeholder="输入要转换的汉字"
                            value={content}
                            onChange={textChange}
                            autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}/>
                    {/*</Col>*/}
                    {/*<Col className="gutter-row" span={12}>*/}

                        <Space>
                            <Button disabled={!content}

                                    onClick={() => {
                                        setContent('');
                                        textAreaRef.current.focus();
                                    }}
                                    type="primary"
                            >清空</Button>


                            <CopyToClipboard

                                text={result} onCopy={() => message.success('复制成功')}>
                                <Button
                                    disabled={result === undefined || result === null || result === ''}
                                    type="primary"
                                >复制</Button>
                            </CopyToClipboard>

                        </Space>

                        <TextArea
                            className={"mt-3"}
                            placeholder="转换的结果"
                            value={result}
                            autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}/>


                {/*    </Col>*/}
                {/*</Row>*/}

            </div>

        </div>

    )
}
