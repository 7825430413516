import React from 'react';
import {CardHeader} from 'reactstrap';
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {VideoLikeOther} from "./VideoLikeOther";
import {loadVideoDetailLocalData} from "../data/ActionCreators";

import AppTemp from "../AppTemp";
import {getHostAndPath} from "../util/UrlUtil";

import {DataTypes} from "../data/Types";

import {Button, Col, Popover, Row} from 'antd';
import {LeftCircleTwoTone, MobileTwoTone} from '@ant-design/icons';
import ReactPlayer from "react-player";
import MyQRCode from "../comp/MyQRCode";
import {ProjectConstant} from "../data/ProjectConstant";
import {isMobile} from "react-device-detect";
import {
    useLocation, useParams
} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function LcPlayer(props) {
    let query = useQuery();
    let lcCat = query.get("cat");
    let t = query.get("title");
    const localDetailData = loadVideoDetailLocalData(DataTypes.VIDEO_LOCAL_DETAIL);
    const videoDetail = localDetailData && localDetailData.payload.data;
    const likes = videoDetail && videoDetail.video_rec;
    const lcPlayerMap = videoDetail && videoDetail.lcPlayerMap;
    const playList = lcPlayerMap && lcCat && lcPlayerMap[lcCat];
    const {id} = useParams();
    // const [videoDetail, setVideoDetail] = React.useState(vd);
    const [mid, setMid] = React.useState(id);
    const [playUrl, setPlayUrl] = React.useState();
    const [qrUrl, setQrUrl] = React.useState(window.location.href);
    const [title, setTitle] = React.useState(t || '');
    // const [display, setDisplay] = React.useState('inline');
    // const [cat, setCat] = React.useState();

    function getNextMid(mid, arr) {
        if (!mid || !arr || arr.length === 0) return null;
        let idx = arr.map(function (item) {
            return item.mid;
        }).indexOf(mid);

        if (idx >= 0 && idx <= arr.length - 2) { // check index is in array bounds
            return arr[idx + 1].mid; // return whatever comes next to item
        }
        return null;
    }


    function playOther(item) {
        play(item.mid)
    }


    React.useEffect(() => {
        mid && play(mid);
    });

    function handleEnded() {
        // console.log('onEnded');
        let nextMid = getNextMid(mid, playList);
        // console.log("nextMid:" + nextMid);
        nextMid && play(nextMid);

    }


    function play(mid) {
        let u = "https://apish.zhidahao.com/video/play/lc/" + mid;
        // let u = RestUrls[DataTypes.VIDEO_LC_PLAY] + mid
        fetch(u)    // url请求地址，options请求参数
            .then(response => {
                //网络请求成功,执行该回调函数,得到响应对象,通过response可以获取请求的数据
                //将Promise对象转换成json对象后return，给下一步的.then处
                return response.json()
                //或 return response.text()
            })
            .then(data => {
                let playUrl = data && data.finalPlayUrl;
                let path = getHostAndPath();
                let newPath = path.replace(mid, '');
                let t = data.title || title;
                let qrUrl = newPath + mid + "?title=" + t + "&cat=" + lcCat;
                if(playUrl){
                    setPlayUrl(playUrl);
                    setTitle(t);
                    setMid(mid);
                    setQrUrl(qrUrl);
                }
            })
            .catch(error => {
                console.log(error)
            })

    }


    // const name = videoDetail && videoDetail.title;

    function PlayButtons(props) {
        const playList = props.playList;
        return playList && playList.length > 0 && playList.map((item, index) =>
            <Button key={`${item.mid}_${index}`}
                    value={`${item.mid}`}
                    type={item.mid === mid ? 'primary' : ''}
                // size="small"
                    className='m-1'
                    onClick={playOther.bind(this, item)}
            >{item.title}</Button>)
    }


    return (
        <AppTemp current={props.current}>
            <HelmetProvider>
                <Helmet>
                    <title>{ProjectConstant.PROJECT_NAME}-{title}</title>
                </Helmet>
            </HelmetProvider>


            <div className="mt-1">
                <CardHeader className="mt-1">
                       <span className="float-left mr-2"
                             style={{
                                 marginTop: '-0.2rem'
                             }
                             }
                             onClick={() => window.open("/video?from=player", "_self")}
                       >
                    {/*<Icon type="left-circle" theme="twoTone"/>*/}
                           <LeftCircleTwoTone/>

                </span>
                    <span>{title}</span>
                    {/*<BrowserView>*/}
                    <Popover className="float-right mr-2 mt-1"
                             content={<MyQRCode value={qrUrl}/>}
                             placement="top"
                             trigger={isMobile ? 'hover' : 'click'}
                             title="手机观看">
                        {/*<Icon type="mobile" theme="twoTone"/>*/}
                        <MobileTwoTone/>
                    </Popover>
                    {/*</BrowserView>*/}

                </CardHeader>
                <div className="mt-1">
                    {
                        // display === 'inline' ?
                        <Row>
                            <Col xs={24} md={16} ms={16}>
                                <div className='p-1'>
                                    <ReactPlayer
                                        className='react-player'
                                        onEnded={handleEnded}
                                        url={playUrl}
                                        playing={true}
                                        controls={true}
                                        pip={true}
                                        width='100%'
                                        height='100%'
                                    />

                                    {/*<div id="dplayer"/>*/}
                                </div>
                            </Col>
                            {
                                playList && playList.length > 0 &&
                                <Col xs={24} md={8} ms={8}>
                                    <div className='p-1'>
                                        <h5>播放列表</h5>
                                        <PlayButtons playList={playList}/>
                                    </div>
                                </Col>
                            }

                        </Row>
                    }
                </div>
            </div>

            {likes && likes.length > 0 && <VideoLikeOther likes={likes}/>}

        </AppTemp>
    );
}


