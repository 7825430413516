/**
 * 身份证15位编码规则：dddddd yymmdd xx p
 * dddddd：地区码
 * yymmdd: 出生年月日
 * xx: 顺序类编码，无法确定
 * p: 性别，奇数为男，偶数为女
 * <p />
 * 身份证18位编码规则：dddddd yyyymmdd xxx y
 * dddddd：地区码
 * yyyymmdd: 出生年月日
 * xxx:顺序类编码，无法确定，奇数为男，偶数为女
 * y: 校验码，该位数值可通过前17位计算获得
 * <p />
 * 18位号码加权因子为(从右到左) wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2,1 ]
 * 验证位 Y = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ]
 * 校验位计算公式：Y_P = mod( ∑(Ai×wi),11 )
 * i为身份证号码从右往左数的 2...18 位; Y_P为校验码所在校验码数组位置
 *
 */
import {IDCARD_CONSTANT} from "../data/IdCardArea";

function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 通过身份证得到性别
 * @param idCard 正确的15/18位身份证号码
 * @return 女、男
 */
// 男女ID
let sexMap = {0: "女", 1: "男"};

export function getSex(idCard) {
    if (idCard.length === 15) {
        return sexMap[idCard.substring(14, 15) % 2];
    } else if (idCard.length === 18) {
        return sexMap[idCard.substring(14, 17) % 2];
    } else {
        //不是15或者18,null
        return null;
    }
}

export function getBirthday(idCard) {
    let birthdayStr;

    if (15 === idCard.length) {
        birthdayStr = idCard.charAt(6) + idCard.charAt(7);

        if (parseInt(birthdayStr) < 10) {
            birthdayStr = '20' + birthdayStr;
        } else {
            birthdayStr = '19' + birthdayStr;
        }
        birthdayStr = birthdayStr + '-' + idCard.charAt(8) + idCard.charAt(9) + '-' + idCard.charAt(10) + idCard.charAt(11);
    } else if (18 === idCard.length) {
        birthdayStr = idCard.charAt(6) + idCard.charAt(7) + idCard.charAt(8) + idCard.charAt(9) + '-' + idCard.charAt(10) + idCard.charAt(11) + '-' + idCard.charAt(12) + idCard.charAt(13);
    }
    return birthdayStr;
}


/**
 * 验证身份证号码中的生日是否是有效生日
 * @param idCard 身份证字符串
 * @return
 */
function checkBrith(idCard) {
    let result = true;
    if (15 === idCard.length) {
        let year = idCard.substring(6, 8);
        let month = idCard.substring(8, 10);
        let day = idCard.substring(10, 12);
        let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
        // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
        if (temp_date.getYear() !== parseFloat(year) || temp_date.getMonth() !== parseFloat(month) - 1 || temp_date.getDate() !== parseFloat(day)) {
            result = false;
        }
    } else if (18 === idCard.length) {
        let year = idCard.substring(6, 10);
        let month = idCard.substring(10, 12);
        let day = idCard.substring(12, 14);
        let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
        // 这里用getFullYear()获取年份，避免千年虫问题
        if (temp_date.getFullYear() !== parseFloat(year) || temp_date.getMonth() !== parseFloat(month) - 1
            || temp_date.getDate() !== parseFloat(day)) {
            result = false;
        }
    } else {
        result = false;
    }

    return result;
}

const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

function checkParity(card) {
    //15位转18位
    // card = changeFivteenToEighteen(card);
    card = trim(card.replace(/ /g, ""));
    let len = card.length;
    if (len !== 18) return false;


    let cardTemp = 0;
    for (let i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i];
    }
    let numStr = arrCh[cardTemp % 11];
    return numStr === card.substr(17, 1);
}


export function getArea(idCard) {
    let areaStr = idCard.substr(0, 6);
    return IDCARD_CONSTANT[areaStr];
}

/**
 * 验证身份证的地区码
 * @param {Object} idCard 身份证字符串
 */
function checkArea(idCard) {
    return getArea(idCard) !== null;
}

//错误信息
let status = ["true", "身份证号码位数不对!", "身份证号码出生日期超出范围或含有非法字符!", "身份证号码校验错误!", "身份证地区非法!"];

/**
 * 验证ID，正确返回“true”，错误则返回错误信息
 * @param {Object} idCard
 */
export function checkIdCard(idCard) {
    if (!idCard) return '身份证为空';
    //去掉首尾空格

    idCard = trim(idCard.replace(/ /g, ""));
    // console.log("checkParity:"+checkParity(idCard))
    if (idCard.length === 15 || idCard.length === 18) {
        if (!checkArea(idCard)) {
            return status[4];
        } else if (!checkBrith(idCard)) {
            return status[2];
        } else if (idCard.length === 18 && !checkParity(idCard)) {

            return status[3];
        } else {
            return status[0];
        }
    } else {
        //不是15或者18，位数不对
        return status[1];
    }
}

// const generateRandomDOB = (): string => {
//     const random = getRandomDate(new Date('1950-02-12T01:57:45.271Z'), new Date('2001-02-12T01:57:45.271Z'))
//     return random.toISOString();
// }

function getRandomDate(from, to) {
    const fromTime = from.getTime();
    const toTime = to.getTime();
    return new Date(fromTime + Math.random() * (toTime - fromTime));
}

function getThreeRandom() {
    return Math.floor(Math.random() * 10) + "" + Math.floor(Math.random() * 10)
        + "" + Math.floor(Math.random() * 10);
}

export function randomIdCard() {
    const keys = Object.keys(IDCARD_CONSTANT);
    let randomKey = keys[keys.length * Math.random() << 0];
    // let randomValue = IDCARD_CONSTANT[randomKey];
    let date = getRandomDate(new Date("1900-01-01"), new Date());
    let month = date.getMonth() + 1;
    let monthStr = String(month);
    if (monthStr.length === 1) {
        monthStr = "0" + monthStr;
    }
    let myDate = date.getDate();
    let myDateStr = String(myDate);
    if (myDateStr.length === 1) {
        myDateStr = "0" + myDateStr;
    }
    let birthday = date.getFullYear() + monthStr + myDateStr;
    let tr = getThreeRandom();
    // console.log("birthday:" + birthday);
    // console.log("tr:" + tr)
    let cardTemp = 0, i;
    let i17 = randomKey + birthday + tr
    for (i = 0; i < 17; i++) {
        cardTemp += i17.substr(i, 1) * arrInt[i];
    }
    let lastNum = arrCh[cardTemp % 11];
    // console.log("lastNum:"+lastNum)
    // console.log("i17:"+i17)
    return i17 + lastNum;

}

export function change15to18(card) {
    if (card.length === 15) {
        let cardTemp = 0, i;
        card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
        for (i = 0; i < 17; i++) {
            cardTemp += card.substr(i, 1) * arrInt[i];
        }
        card += arrCh[cardTemp % 11];
        return card;
    }
    return card;
}

export function change18to15(card) {
    if (card.length === 18) {
        return card.substr(0, 6) + card.substr(8, 9);
    }
    return card;
}



