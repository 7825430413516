import React, {useState} from 'react'
import fetch from '../libs/fetch'

// import {useQuery} from 'react-query'
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
// import {Button, ListGroup, ListGroupItem} from 'reactstrap';

import {
    isMobile
} from "react-device-detect";
import {getLinkOpenTarget} from "../util/UrlUtil";
import {Button as AButton,Avatar, List} from "antd";
import {Button} from 'reactstrap';
// import {taobaoLink} from "../util/TaobaoUtil";
import {useInfiniteQuery} from 'react-query'
// import useIntersectionObserver from '../hook/useIntersectionObserver'
// import axios from 'axios'

export const TaoAdPageLoadMore = (props) => {
    const [nextId] = useState(0);
    let type = isMobile;
    // const key = 'tao_ad_keyword';
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingMore,
        fetchMore,
        canFetchMore,

    } = useInfiniteQuery(
        // key,
        // [key, {nextId, type}],
        // ({nextId} = {}) => fetch(RestUrls[DataTypes.TAO_AD_KEYWORD_PAGINATION]
        //     + "?mobile=" + type + "&nextId=" + (nextId || 0) + "&pageSize=10"),
        // {
        //     paginated: true,
        //     getCanFetchMore: lastPage => lastPage.nextId,
        // }


        'tao_ad_keyword',
        (key, cursor = 0) =>
            fetch(RestUrls[DataTypes.TAO_AD_KEYWORD_PAGINATION]
                + "?mobile=" + type + "&nextId=" + (nextId || 0) + "&pageSize=10")

        ,
        {
            // getCanFetchMore: lastPage => lastPage.nextId,
            getFetchMore: (lastGroup, allGroups) => lastGroup.nextId,
        }
    );


    // let flag = data && data.length > 0;
    // // console.log(data);
    // flag = flag && (data[0].error !== null && data[0].data !== null);

    // console.log("flag:"+flag);

    function getUrl(item) {
        return isMobile ? item.url : item.pcUrl || item.click;
    }

    return (


        // flag &&
        <div>
            {/*{isLoading ? (*/}
            {/*        <p>加载中</p>*/}
            {/*    ) :*/}
            {/*    (*/}

            {/*<h1>Infinite Loading</h1>*/}
            {status === 'loading' ? (
                <p>Loading...</p>
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (

                <>
                    {/*console.log(JSON.stringify(data)*/}
                    {data !== null && data !== undefined && data.map((page, i) => (
                        <List key={i}
                              dataSource={page.data}
                              renderItem={item => (
                                  <List.Item key={item.id}>
                                      <List.Item.Meta
                                          avatar={
                                              <Avatar
                                                  src={item.logo}/>
                                          }
                                          title={<a target={getLinkOpenTarget()}
                                                    href={getUrl(item)}>{item.name}</a>}

                                          description={item.desc ? item.desc : ''}
                                      />
                                      {/*{item.price && <div className={"mr-2"}>{item.price}元</div>}*/}
                                      {/*<Button tag={'a'}>购买</Button>*/}
                                      {item.click && <AButton className={"m-1"} target={getLinkOpenTarget()}
                                                             href={getUrl(item)}
                                                             type="primary" shape="round"
                                          // icon="buy"
                                      >购买</AButton>
                                      }
                                  </List.Item>
                              )}
                        >
                        </List>


                    ))}

                    <div className="text-center mt-2">
                        <div>
                            <Button
                                onClick={() => fetchMore()}
                                disabled={!canFetchMore || isFetchingMore}
                                color={(!canFetchMore || isFetchingMore)?'secondary':(canFetchMore?'primary':'secondary')}
                            >
                                {isFetchingMore
                                    ? '加载中'
                                    : canFetchMore
                                        ? '加载更多'
                                        : '到底了'}
                            </Button>
                        </div>

                        <div>
                            {isFetching && !isFetchingMore ? '正在获取数据' : null}
                        </div>
                    </div>
                </>)
            }
        </div>
    )
};