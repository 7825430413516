export function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

export function limitTxt(txt, length) {
    if (!txt || txt.length === 0) return '';
    let txtLength = txt.length;
    return txt.substr(0, Math.min(length, txtLength));
}


export function isChinese(temp) {
    let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    return reg.test(temp);
}

export function isAllChinese(temp) {
    let reg = new RegExp("^[\\u4E00-\\u9FFF]+$", "g");
    return reg.test(temp);
}



export function  strObj2Json(input){
    return  input.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
        return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
    });
}


export function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    return typeof item === "object" && item !== null;


}
