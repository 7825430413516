import React from 'react';

import {Button, Checkbox, Col, Form, Input, message, Radio, Row, Space, Spin} from 'antd';

import {CopyOutlined, DownloadOutlined, EyeOutlined, FileZipOutlined} from '@ant-design/icons';
import {DataTypes} from "../data/Types";
import {RestUrls} from "../data/Urls";
import {CopyToClipboard} from "react-copy-to-clipboard";
// import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {Modal} from 'react-bootstrap'
import {useMount} from "ahooks";
// import {CodeMirror} from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/ambiance.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/clike/clike';
// import $ from 'jquery';
import {UnControlled as CodeMirror} from 'react-codemirror2';
import store from 'store';
import {clone} from "../util/ObjectUtil";
import {getUrlParameter, isUrl} from "../util/UrlUtil";
import jsonp from 'jsonp';
import GenerateSchema from 'generate-schema';

import {isJson} from "../util/MyStringUtil";
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
// import Ajv from 'ajv';


// const ajv = new Ajv();

// import '../autorefresh.ext';


const {
    Search
} = Input;

// const {Option} = Select;


const jsonPlace = "{\n" +
    "  \"type\":\"object\",\n" +
    "  \"properties\": {\n" +
    "    \"foo\": {\n" +
    "      \"type\": \"string\"\n" +
    "    },\n" +
    "    \"bar\": {\n" +
    "      \"type\": \"integer\"\n" +
    "    },\n" +
    "    \"baz\": {\n" +
    "      \"type\": \"boolean\"\n" +
    "    }\n" +
    "  }\n" +
    "}"


export function Json2Pojo(props) {

    let defaultTargetpackage = "com.example";
    let defaultSourcetype = "jsonschema";
    let defaultClassname = "Example";
    let defaultAnno = "jackson2";
    let defaultDelimiters = "- _";


    let checks = [
        {name: "generatebuilders", value: false, label: "Generate builder methods"},
        {name: "useprimitives", value: false, label: "Use primitive types"}
        , {name: "uselongintegers", value: false, label: "Use long integers"}
        , {name: "usedoublenumbers", value: true, label: "Use double numbers"}
        , {name: "usejodadates", value: false, label: "Use Joda dates"}

        , {name: "includeaccessors", value: true, label: "Include getters and setters"}

        , {name: "includeconstructors", value: false, label: "Include constructors"}
        , {name: "includehashcodeandequals", value: false, label: "Include hashCode and equals"}

        , {name: "includetostring", value: false, label: "Include toString"}
        , {name: "includejsr303annotations", value: false, label: "Include JSR-303 annotations"}
        , {name: "includeadditionalproperties", value: true, label: "Allow additional properties"}
        , {name: "serializable", value: false, label: "Make classes serializable"}
        , {name: "parcelable", value: false, label: "Make classes parcelable"}
        , {name: "initializecollections", value: false, label: "Initialize collections"}

    ];
    const checkListLabel = "pojo_checks";
    let defaultCheckList = store.get(checkListLabel) || ["uselongintegers", "includeaccessors", "includeadditionalproperties"];
    const [checkList, setCheckList] = React.useState(defaultCheckList);

    // let defaultCheckList = ["uselongintegers", "includeaccessors", "includeadditionalproperties"];


    const [loading, setLoading] = React.useState(false);
    const [targetpackage, setTargetpackage] = React.useState(defaultTargetpackage);
    const [classname, setClassname] = React.useState(defaultClassname);
    const [sourcetype, setSourcetype] = React.useState(defaultSourcetype);
    const [annotationstyle, setAnnotationstyle] = React.useState(defaultAnno);
    const [propertyworddelimiters, setPropertyworddelimiters] = React.useState(defaultDelimiters);
    const [schema, setSchema] = React.useState(jsonPlace);

    const [detail, setDetail] = React.useState({
        targetpackage,
        classname,
        sourcetype,
        annotationstyle,
        propertyworddelimiters,
        schema: jsonPlace,
        uselongintegers: true,
        includeaccessors: true,
        includeadditionalproperties: true

    });


    const [previewResult, setPreviewResult] = React.useState();
    const [downloadResult, setDownloadResult] = React.useState();
    const [downloadFilename, setDownloadFilename] = React.useState();
    const [show, setShow] = React.useState(false);
    const [sourceCode, setSourceCode] = React.useState(false);

    const storeLabel = "json2pojo";


    // // let myCodeMirror;
    useMount(() => {

        // console.log("checkList:" + checkList);
        let condition = store.get(storeLabel);
        if (condition) {
            setDetail(condition);
            setTargetpackage(condition['targetpackage']);
            setClassname(condition['classname']);
            setSourcetype(condition['sourcetype']);
            setAnnotationstyle(condition['annotationstyle']);
            setPropertyworddelimiters(condition['propertyworddelimiters']);
        }

        if (checkList) {
            for (let i = 0; i < checkList.length; i++) {
                let name = checkList[i];
                detail[name] = true;
                // let checked = checkList[i].value;
                // if (checked) {
                //     detail[name] = checkList[i].value;
                // } else {
                //     delete detail[name];
                // }
            }
        }
        // console.log("schema:" + schema);

    });


    function storeCondition() {
        let storeObj = clone(detail);
        storeObj.schema = '';
        // console.log("storeList:" + checkList);
        store.set(storeLabel, storeObj);
        store.set(checkListLabel, checkList);
    }

    // const API_HOST = RestUrls[[DataTypes.HOST_URL]];
    //
    const API_HOST = RestUrls[[DataTypes.API_SH]];


    function preview() {
        // console.log(JSON.stringify(detail));
        // console.log("schema:" + schema)
        if (detail.schema === null || detail.schema === '') {
            detail.schema = jsonPlace;
        }
        // resetSourceType();
        setLoading(true);
        // const detail = {}
        storeCondition();
        let formBody = getFormBody();
        let url = API_HOST + "/tool/json2pojo/generator/preview";
        // console.log(url)
        // console.log(formBody);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                let result = data.data;
                if (result) {
                    // console.log(JSON.stringify(data));
                    // result = result.replace("\n","<br/>");
                    setPreviewResult(result)
                    setShow(true)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('There was an error!', error);
            });

    }


    function onChange(e) {
        let target = e.target;
        let checked = target.checked;
        let name = target.name;

        // checkList.push()
        let index = checkList.indexOf(name);
        console.log("index:" + index);
        if (checked) {
            if (index === -1) {
                checkList.push(name);
            }
        } else {
            if (index > -1) {
                checkList.splice(index, 1);
            }
        }


        console.log("name:" + name + ',checked = ' + checked);
        console.log("checkList:" + checkList);

        setCheckList(checkList);

        // for (let i = 0; i < checkMap.length; i++) {
        //     let n = checks[i].name;
        //     if (n === name) {
        //         checkMap[i].value = checked;
        //     }
        // }
        //
        // setCheckMap(checkMap);
        // console.log("new checkMap:" + JSON.stringify(checkMap));
        // checkMap[name]
        // let someProperty = {...this.state.someProperty}
        if (checked) {
            setDetail({
                ...detail,
                [target.name]: true
            });
        } else {
            delete detail[target.name];
            setDetail(detail);
        }

    }

    function getFormBody() {
        let formBody = [];
        for (let property in detail) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(detail[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        return formBody.join("&");
    }


    // function resetSourceType() {
    //     let testSchema = detail.schema;
    //     console.log(testSchema)
    //     let jsonStr = strObj2Json(testSchema);
    //     console.log(typeof jsonStr)
    //     console.log(jsonStr)
    //     if (isJson(jsonStr)) {
    //         // if (validate) {
    //         const validate = ajv.compile(JSON.parse(jsonStr));
    //         if (validate) {
    //
    //         }
    //         console.log("jsonche:" + validate)
    //     }
    //     // if (validate) {
    //     //     detail.sourcetype = "jsonschema"
    //     //     setSourcetype(detail.sourcetype);
    //     // }
    //     // }
    //
    // }

    function generate() {
        if (detail.schema === null || detail.schema === '') {
            detail.schema = jsonPlace;
        }
        // resetSourceType();
        setLoading(true);
        storeCondition();
        setLoading(true);
        let url = API_HOST + "/tool/json2pojo/generator";
        let formBody = getFormBody();
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        }).then(response => response.json())
            .then(data => {
                setLoading(false);
                let result = data.data;
                if (result) {
                    // console.log(JSON.stringify(data, null, "\t"));
                    setDownloadResult(result)
                    setDownloadFilename(classname + "-sources.zip");
                }
            }).catch(error => {
            setLoading(false);
            console.error('There was an error!', error);
        });


    }


    function packageChange(e) {
        let target = e.target;
        let value = target.value;
        if (value) {
            setTargetpackage(value)
            setDetail({
                ...detail,
                'targetpackage': value
            });
        }

        // console.log("packageChange:" + target.value);

    }

    function classnameChange(e) {
        let target = e.target;

        let value = target.value;
        if (value) {
            setClassname(value);
            setDetail({
                ...detail,
                'classname': value
            });
        }

        console.log("classnameChange:" + target.value);

    }

    function delimitersChange(e) {
        let target = e.target;
        let value = target.value;
        if (value) {
            setPropertyworddelimiters(value);
            setDetail({
                ...detail,
                'propertyworddelimiters': value
            });
        }

        console.log("delimitersChange:" + target.value);

    }


    function sourceTypeChange(e) {
        let target = e.target;
        let value = target.value;
        if (value) {
            setSourcetype(value);
            setDetail({
                ...detail,
                'sourcetype': value
            });

        }


    }


    function annotationstyleChange(e) {
        let target = e.target;
        let value = target.value;
        if (value) {
            setAnnotationstyle(value)
            setDetail({
                ...detail,
                'annotationstyle': value
            });
        }

        console.log("annotationstyleChange:" + target.value);

    }

    //
    // function jsonChange(e) {
    //     let target = e.target;
    //     let value = target.value;
    //     if (value) {
    //         setSchema(value);
    //         setDetail({
    //             ...detail,
    //             'schema': value
    //         });
    //     }
    //
    //
    //     // console.log("jsonChange:" + value);
    // }


    function onCopy() {
        message.success('复制成功')
    }


    // $('#myModal').on('shown.bs.modal', function () {
    //     $('#myInput').trigger('focus')
    // })


    // function handleShow() {
    //     setShow(true);
    //     myCodeMirror.refresh();
    // }

    let mirrorInstance = null;
    let dialogInstance = null;

    function onShow() {

        setTimeout(() => {
            // mirrorInstance.setSize(null, 800);
            if (dialogInstance) {
                dialogInstance.setSize("100%", "100%");
                dialogInstance.refresh();
            }
        }, 1);

    }


    const widthRef = React.useRef(null);


    // const getWidth = () => window.innerWidth
    //     || document.documentElement.clientWidth
    //     || document.body.clientWidth;
    // function setMirrorWidth() {
    //     let width = widthRef.current ? widthRef.current.offsetWidth : 0;
    //     // console.log('width', width);
    //     if (width > 0 && mirrorInstance) {
    //         mirrorInstance.setSize(width + 200, 750);
    //         // mirrorInstance.refresh();
    //     }
    // }

    React.useEffect(() => {

        let width = widthRef.current ? widthRef.current.offsetWidth : 0;
        // console.log('width', width);
        if (width > 0 && mirrorInstance) {
            mirrorInstance.setSize(width, 750);
            // mirrorInstance.refresh();
        }

        // setMirrorWidth();
        // function setMirrorWidth(){
        //     let width = widthRef.current ? widthRef.current.offsetWidth : 0;
        //     // console.log('width', width);
        //     if (width > 0 && mirrorInstance) {
        //         mirrorInstance.setSize(width + 200, 750);
        //         // mirrorInstance.refresh();
        //     }
        // }


        // let timeoutId = null;
        // const resizeListener = () => {
        //     // prevent execution of previous setTimeout
        //     clearTimeout(timeoutId);
        //     // change width from the state object after 150 milliseconds
        //
        //
        //     timeoutId = setTimeout(() => setMirrorWidth(), 150);
        // };
        // // set resize listener
        // window.addEventListener('resize', resizeListener);
        //
        // // clean up function
        // return () => {
        //     // remove resize listener
        //     window.removeEventListener('resize', resizeListener);
        // }
    }, [mirrorInstance]);


    const handleClose = () => setShow(false)

    const defaultUrl = "";
    // const defaultUrl = "https://api.web.360kan.com/v1/detail?cat=2&id=QLJpb07lTzLoNH&callback=__jp2";

    // const [url, setUrl] = React.useState(defaultUrl);


    function isSchema() {
        let ds = detail.schema;
        // console.log("ds:"+ds)
        // console.log("schema:"+schema)
        if (!ds) return;
        if (isJson(ds)) {
            let dos = JSON.parse(ds);
            if (dos.hasOwnProperty("$schema")) {
                return true;
            }
        }
        return false;


    }

    function onSearch(url) {
        // alert(url);
        if (!isUrl(url)) return;
        // console.log("url:" + url);
        let callback = getUrlParameter(url, "callback");
        // console.log("callback:"+callback)
        let opts = {
            "param ": {},
            "timeout:": 5000,
            "prefix": "__jp",
            "name": callback

        }
        if (callback) {
            jsonp(url, opts, function (err, data) {
                if (!err) {
                    if (data && isJson(data)) {
                        let formatJson = JSON.stringify(data, null, "\t");
                        // let notFormat = JSON.stringify(data);
                        // console.log(formatJson);
                        setSchema(formatJson);
                        // setSchema(data);
                        setSourcetype("json");
                        setDetail({
                            ...detail,
                            'schema': formatJson,
                            'sourcetype': 'json'
                        });

                    }
                } else {
                    console.log(err);
                }

            });

        } else {
            let newUrl = RestUrls[[DataTypes.HOST_URL]] + "/tool/content?url=" + encodeURIComponent(url);

            fetch(newUrl).then(response => response.json())
                .then(result => {
                    if (result && result.data) {
                        let data = result.data;
                        let schema = data;
                        let newSourceType = "yaml";
                        if (isJson(data)) {
                            // let notFormat = JSON.stringify(data);
                            // console.log(formatJson);
                            schema = JSON.stringify(JSON.parse(data), null, "\t");
                            newSourceType = "json";
                        }
                        setSchema(schema);
                        setSourcetype(newSourceType);
                        setDetail({
                            ...detail,
                            'schema': schema,
                            'sourcetype': newSourceType
                        });

                    }
                }).catch(error => {
                // setLoading(false);
                console.error('There was an error!', error);
                message.error("获取数据打败");
            });


        }

    }

    function isJavaScriptMode() {

        return schema && isJson(schema);
    }

    // function searchChange(e) {
    //     let val = e.target.value;
    //     setUrl(val)
    //     // console.log(val)
    //     // setUrl(url)
    // }

    function mode() {

        return isJavaScriptMode() ? 'javascript' : 'xml';
    }

    let jsonschema = "jsonschema";

    function geneFromJson() {
        if (!detail || !detail.schema || !isJson(detail.schema)) return;
        let schema = detail.schema;
        if (schema) {
            let newSchema = GenerateSchema.json("https://www.zhidahao.com", JSON.parse(schema));
            if (newSchema) {
                if (newSchema.hasOwnProperty('title')) delete newSchema['title'];
                let schemaStr = JSON.stringify(newSchema);
                let formatJson = JSON.stringify(newSchema, null, "\t");
                setSchema(formatJson);
                setSourcetype(jsonschema)
                setDetail({
                    ...detail,
                    'schema': schemaStr,
                    'sourcetype': jsonschema
                });
            }
        }


    }

    // const [cursor, setCursor] = React.useState({
    //     line: 1,
    //     ch: 0
    // });


    function disableSchemaButton() {
        if (isSchema()) return true;
        if (detail.schema === jsonschema && schema === jsonPlace) return true;
        return !isJson(detail.schema);

    }

    return (

        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>


            <div
                className={"m-2"}
            >
                <Row gutter={[16, 16]}>
                    <Col flex={5}>

                        <Search
                            placeholder={"生成源码的url链接"}
                            allowClear
                            defaultValue={defaultUrl}
                            enterButton="提交"
                            // value={url}
                            size="large"
                            onSearch={onSearch}
                            // onChange={searchChange}

                        />

                        <div ref={widthRef} style={{"width": "100%"}}/>


                        <Space direction="horizontal" className={"mt-2 mb-2"}>


                            <Button type="primary" disabled={disableSchemaButton()}
                                    onClick={geneFromJson}>JsonSchema</Button>
                            <CopyToClipboard onCopy={onCopy}
                                             text={sourceCode || jsonPlace || ''}>
                                <Button type="primary" icon={<CopyOutlined/>}>复制源码</Button>
                            </CopyToClipboard>
                            <Button type="primary" icon={<EyeOutlined/>} onClick={preview}>POJO预览</Button>
                            <Button type="primary" onClick={generate} icon={<FileZipOutlined/>}>Zip</Button>

                            {/*{detail && isJson(detail.schema) &&*/}
                            {/*<Button type="primary" className={"float-end"} disabled={isSchema()} onClick={geneFromJson}>JsonSchema</Button>}*/}


                            {
                                downloadResult && <Button

                                    type="primary"

                                    title={downloadFilename}
                                    download={downloadFilename}
                                    href={"data:application/zip;base64," + downloadResult}
                                    icon={<DownloadOutlined/>}>{downloadFilename}</Button>
                            }


                            {loading && <Spin className={"ms-5"}/>}
                        </Space>


                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-md-6 offset-md-1">

                                    {/*{isJsonSchema ?*/}
                                    <CodeMirror
                                        value={schema}

                                        // cursor={cursor}

                                        options={{
                                            mode: mode(),
                                            autoRefresh: true,
                                            // autofocus: true,
                                            theme: 'material',
                                            autoScroll: false,
                                            autoCursor: false
                                        }}


                                        // onCursor={(editor, data) => {
                                        //     let ch = data.ch;
                                        //     let newCursor = {line: data.line, ch: ch + 1};
                                        //     setCursor(newCursor)
                                        //     console.log(data);
                                        // }}


                                        onChange={(editor, data, value) => {
                                            // console.log("value:" + value);
                                            // setSchema(value);
                                            setSourceCode(value);

                                            setDetail({
                                                ...detail,
                                                'schema': value
                                            });
                                        }}


                                        editorDidMount={editor => {
                                            mirrorInstance = editor;
                                        }
                                        }
                                    />


                                </div>
                            </div>
                        </div>


                    </Col>
                    <Col flex={5}>
                        <Space direction="vertical">
                            <Input addonBefore="包名" defaultValue={defaultTargetpackage} value={targetpackage}
                                   onChange={packageChange}/>
                            <Input addonBefore="类名" defaultValue={defaultClassname} value={classname}
                                   onChange={classnameChange}/>
                            <Input addonBefore="属性分隔符" defaultValue={defaultDelimiters}
                                   value={propertyworddelimiters}

                                   onChange={delimitersChange}/>

                            <Form.Item label="源码类型">
                                <Radio.Group defaultValue={defaultSourcetype} value={sourcetype}
                                             onChange={sourceTypeChange}
                                             size="small"
                                             style={{marginTop: 16}}>
                                    <Radio.Button value="jsonschema">JSON Schema</Radio.Button>
                                    <Radio.Button value="json">JSON</Radio.Button>
                                    <Radio.Button value="yamlschema">YAML Schema</Radio.Button>
                                    <Radio.Button value="yaml">YAML</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item label="注解类型">
                                <Radio.Group defaultValue={defaultAnno}
                                             onChange={annotationstyleChange}
                                             value={annotationstyle} size="small"
                                             style={{marginTop: 16}}>
                                    <Radio.Button value="jackson2">Jackson 2.x</Radio.Button>
                                    <Radio.Button value="gson">Gson</Radio.Button>
                                    <Radio.Button value="moshi1">Moshi</Radio.Button>
                                    <Radio.Button value="none">None</Radio.Button>
                                </Radio.Group>
                            </Form.Item>


                            {
                                checks.map((item, index) =>
                                    <Checkbox key={index} name={item.name}
                                        // checked={item.value}
                                        // value={item.value}
                                              defaultChecked={defaultCheckList.includes(item.name)}
                                              onChange={onChange}>
                                        {item.label}
                                    </Checkbox>
                                )


                            }

                        </Space>

                    </Col>
                </Row>


            </div>
            <Modal
                show={show}
                onHide={handleClose}
                onShow={onShow}
                fullscreen={true}
                // backdrop="static"
                // size="xl"

                // scrollable={true}
                // keyboard={false}

            >
                <Modal.Header closeButton>
                    <Modal.Title>预览</Modal.Title>
                    <CopyToClipboard className="ms-4 mt-1" onCopy={onCopy} key={"CopyToClipboard"}
                                     text={previewResult}>
                        <Button
                            type="primary"
                            icon={<CopyOutlined/>}
                        >复制
                        </Button>
                    </CopyToClipboard>
                </Modal.Header>
                <Modal.Body>
                    <CodeMirror
                        value={previewResult}

                        options={{
                            mode: 'text/x-java',
                            lineNumbers: true,
                            autoRefresh: true,
                            indentWithTabs: true,
                            smartIndent: true,

                            lineWrapping: true,
                            autofocus: true,
                            theme: "ambiance",
                            // theme: 'material',
                            autoScroll: false,
                            matchBrackets: true


                        }}
                        editorDidMount={editor => {
                            dialogInstance = editor;
                        }
                        }


                    />

                    {/*{previewResult}*/}

                </Modal.Body>


                {/*<Modal.Footer>*/}
                {/*    <Button variant="secondary" onClick={handleClose}>*/}
                {/*        Close*/}
                {/*    </Button>*/}
                {/*    <Button variant="primary" onClick={handleClose}>*/}
                {/*        Save Changes*/}
                {/*    </Button>*/}
                {/*</Modal.Footer>*/}

            </Modal>


        </div>

    )


}


