import React from 'react';

import {Button, Input} from 'antd';
import {isMobile} from "react-device-detect";
import {getLinkOpenTarget} from "../util/UrlUtil";
import {trim} from "../util/MyStringUtil";

const {TextArea} = Input;


// function TranIframe(props) {
//
//     return <iframe src={props.url}
//                    width="100%" height="800" title={props.title}
//                    frameBorder="0"/>
// }

export function Tran(props) {

    const [content, setContent] = React.useState();
    // const [currKey,setCurrKey] = React.useState();


    const items = [
        "词酷在线翻译",
        "金山词霸",
        "Google在线翻译",
        "有道词典",
        "百度词典",
        "cnki翻译助手"
    ]


    function getUrlFromKey(key) {

        if ("词酷在线翻译" === key) {
            let u = "//dict.naver.com/linedict/zhendict/dict.html#/cnen/home";
            return content ? "//dict.naver.com/linedict/zhendict/dict.html#/cnen/search?query=" + encodeURIComponent(content) : u;
        }


        if ("金山词霸" === key) {
            let u = "//www.iciba.com/word";
            return content ? u + "?w=" + encodeURIComponent(content) : u;
        }
        if ("Google在线翻译" === key) {
            let u = "//translate.google.cn";
            return content ? u + "/#en/zh-CN/" + encodeURIComponent(content) : u;
        }
        if ("有道词典" === key) {
            let u = "//dict.youdao.com/search";
            return content ? u + "?ue=utf8&q=" + encodeURIComponent(content) : u;
        }
        if ("百度词典" === key) {
            let u = "//dict.baidu.com";
            return content ? u + "/s?wd=" + encodeURIComponent(content) : u;
        }

        if ("cnki翻译助手" === key) {
            let u = "//dict_result.aspx?searchword=";
            return content ? u + encodeURIComponent(content) : u;
        }
        return "#";


    }

    function onChange(e) {
        let value = e.target.value;
        value = trim(value)
        setContent(value)
    }


    return (

        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>

            <h6 className={"ms-5"}>
                目前支持中文←→英语、日语←→英语、韩语←→英语、德语←→英语、英语←→西班牙语、英语←→意大利语、英语←→荷兰语、英语←→葡萄牙语、英语←→俄语、西班牙语←→法语、法语←→意大利语、法语←→荷兰语、法语←→葡萄牙语的互译。
            </h6>


            <div
                className={"m-2"}
            >
                {items.map(key =>
                    <Button key={key}
                            className={"m-1"} type="primary"
                            target={getLinkOpenTarget()}

                            href={getUrlFromKey(key)}
                            // onClick={()=> setCurrKey(key)}
                    >
                        {key}
                    </Button>
                )
                }

                <TextArea
                    className={"m-1"}
                    placeholder="输入你想要翻译的内容"
                    value={content}
                    onChange={onChange}
                    autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}/>
            </div>

            {/*{currKey && <TranIframe url={getUrlFromKey(currKey)} title={currKey}/>}*/}
        </div>

    )


}


