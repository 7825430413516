import AppTemp from "../AppTemp";
import React from "react";
import {
    Menu, Layout,
} from 'antd';
import {IdCard} from "./IdCard";
import {SimplifiedTraditional} from "./SimplifiedTraditional";

import {isMobile} from "react-device-detect";
import {getProjectTitle} from "../util/ProjectUtil";
import {useHistory, useParams} from "react-router-dom";
// import Icon, {createFromIconfontCN} from '@ant-design/icons';
import {IpQuery} from "./IpQuery";
import {Tran} from "./Tran"
import {PhoneQuery} from "./PhoneQuery";
import {ExpressQuery} from "./ExpressQuery";
import {ExchangeQuery} from "./ExchangeQuery";
import {Hanzi2Pinyin} from "./Hanzi2Pinyin";
import {LenCap} from "./LenCap";
import {Hanzi2Wubi} from "./Hanzi2Wubi";
import {MyJsonEditor} from "./coder/MyJsonEditor";
import {Number2Chinese} from './Numbe2Chinese'
import {Image2Base64} from './Image2Base64'
import {Json2Pojo} from "./Json2Pojo";

// const IconFont = createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });


const {
    // Header, Footer,
    Sider, Content
} = Layout;


export function Tool(props) {

    let {id} = useParams();

    const [key, setKey] = React.useState("IdCard");
    const [components, setComponents] = React.useState();
    const [keys, setKeys] = React.useState();
    // const keys = ['IpQuery', 'PhoneQuery', 'ExpressQuery', 'ExchangeQuery', 'IdCard', 'Tran', 'SimplifiedTraditional', 'Hanzi2Pinyin'];


    // const kv = {'IpQuery':IpQuery};
    React.useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let components = {

                'ExchangeQuery': {
                    title: '汇率查询', 'component': <ExchangeQuery
                        title="汇率查询"
                    />
                },

                'IpQuery': {
                    title: 'IP查询', 'component': <IpQuery
                        title="IP查询"
                    />
                },
                'PhoneQuery': {
                    title: '手机归属地', 'component': <PhoneQuery
                        title="手机归属地"
                    />
                },

                'Tran': {title: '在线翻译', 'component': <Tran title="在线翻译"/>}
                ,
                'SimplifiedTraditional': {
                    title: '简繁转换', 'component': <SimplifiedTraditional
                        title="简繁转换"
                    />
                },
                'Hanzi2Pinyin': {
                    title: '汉字转拼音', 'component': <Hanzi2Pinyin
                        title="汉字转拼音"
                    />
                },

                'Hanzi2Wubi': {
                    title: '汉字转五笔', 'component': <Hanzi2Wubi
                        title="汉字转五笔"
                    />
                },


                'LenCap': {
                    title: '度量衡', 'component': <LenCap
                        title="度量衡转换"
                    />
                },

                'ExpressQuery': {
                    title: '快递查询', 'component': <ExpressQuery
                        title="快递查询"
                    />
                },
                'IdCard': {
                    title: '身份证验证', 'component': <IdCard
                        title="身份证验证"
                    />
                }

                ,
                'Number2Chinese': {
                    title: '人民币大写', 'component': <Number2Chinese
                        title="人民币大写"
                    />
                },

                'Image2Base64': {
                    title: '图片转Base64', 'component': <Image2Base64
                        title="图片转Base64"
                    />
                }


                // ,
                // 'JsonEditor': {
                //     title: 'json编辑器', 'component': <MyJsonEditor
                //         title="json编辑器"
                //     />
                // }
            };

            if (!isMobile) {
                components['JsonEditor'] = {
                    title: 'json编辑器', 'component': <MyJsonEditor
                        title="json编辑器"
                    />
                };


                components['Json2Pojo'] = {
                    title: 'Json转Pojo', 'component': <Json2Pojo
                        title="Json转Pojo"
                    />
                };


            }

            setComponents(components);
            let ks = Object.keys(components);
            setKeys(ks);
            setKey(id || ks[0]);
        }
        return () => {
            isMounted = false
        };
    }, [id]);


    function getComponent(key) {
        if (!key) return '';
        if (!keys || keys.length === 0) return '';
        for (let i = 0; i < keys.length; i++) {
            let ck = keys[i];
            if (ck === key) {
                return components[key].component;
            }
        }
        return '';
    }


    let history = useHistory();

    function itemClick(e) {
        // console.log(JSON.stringify(item))
        setKey(e.key);
        history.push("/tool/" + e.key);
        // console.log('click ', e);
        // console.log(e.key)
    }


    return <Layout>
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
                // console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
        >
            <div className={"ms-4"}>
                <img
                    // alt={this.state.title}
                    alt={getProjectTitle(props.current)}
                    // className={this.logoClassName}
                    className={isMobile ? 'logo_m' : 'logo'}
                    src={"/logo.png"}/>
            </div>

            <Menu theme="dark" mode="inline" defaultSelectedKeys={['IpQuery']}
                  selectedKeys={[key]}
                  onClick={itemClick}
            >


                {
                    keys && keys.length > 0 && keys.map(key =>
                        <Menu.Item key={key}>
                            {components[key].title}
                        </Menu.Item>
                    )
                }


                {/*<Menu.Item key="PhoneQuery"
                    icon={    <GlobalOutlined/>
                     >*/}
                {/*    手机号归属地*/}
                {/*</Menu.Item>*/}
            </Menu>
        </Sider>

        {/*<Content>*/}
        <Layout>
            <Content>
                <AppTemp current={props.current} hideLogo={true} showFooter={true}>

                    {getComponent(key)}
                </AppTemp>
            </Content>
        </Layout>
    </Layout>
}


