import {useQuery} from "react-query";
import fetch from "../libs/fetch";
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {Card, CardBody, CardImg, CardSubtitle, CardText, Col, Row} from "reactstrap";
import React from "react";
// import Img from "react-image";

export function IndexDataVideo(props) {
    const {data, isLoading, isFetching} = useQuery('IndexDataVideo', (() => fetch(RestUrls[DataTypes.VIDEO_INDEX_DATA]))
        ,
        {
            staleTime: 2 * 60 * 1000,
            cacheTime: 5 * 60 * 1000
        }
    );
    let load = "加载中";
    let refresh = "";
    // if (data) {
    //     console.log("data:" + JSON.stringify(data));
    //     console.log("error:" + error);
    //     console.log(Object.keys(data));
    // }
    function getLimit(desc) {
        if (!desc) return '';
        return desc.length > 12 ? desc.substring(0, 12) : desc;
    }

    function replaceMp4er(image) {
        // let images
        if (!image) return '';
        // image =   image.replace(".com",".d");
        return image.replace('bde4.com/', 'mp4er.cc/');
        //  images = [image, RestUrls[DataTypes.VIDEO_NO_PIC]];
        //   return images;

    }


// function isMovie(key) {
//     return key.indexOf("电影") !== -1;
// }
//
// function getTip(item,key){
//     if(isMovie(key)) return item.score;
//     return item.tip;
// }

    function getAreaAndYear(item) {
        let year = item.year;
        let area = item.area;
        return (area ? area : '') + (year ? year : '');
    }

    function imgError(e) {
        let target = e.target;
        let errorSrc = target.src;
        let flag = errorSrc != null && errorSrc.indexOf("zhidahao.com") !== -1;
        if (!flag) {
            target.src = RestUrls[DataTypes.VIDEO_NO_PIC];
        }

    }


// const flag = data && Object.keys(data) && Object.keys(data).length > 0;
    return (<div>
        {isLoading ? (
                <span>{load}</span>
            )
            : data ? (data.error) ? (<span>{data.error}</span>) : (
                <>
                    {isFetching ? <div>{refresh}</div> : null}

                    <div className="mt-3">
                        {
                            Object.keys(data).map(key =>
                                <div key={key} className="m-2">
                                    <h5 className="m-2">{key}</h5>
                                    <Row>
                                        {
                                            key && data[key].map((item, index) =>
                                                <Col key={item.title + index} xs="6" sm="4" md="2">
                                                    <Card key={item.title + index}
                                                          className='m-1'
                                                          onClick={props.directQuery.bind(this, item)}
                                                    >
                                                        <div className='g-playicon'>


                                                            <CardImg top
                                                                     // className="cover"
                                                                     width="100%"
                                                                     src={replaceMp4er(item.image)} alt={item.title}

                                                                     onError={imgError}

                                                            />


                                                            {item.category ? <span
                                                                className='video-category'><small>{item.category}</small></span> : ''}


                                                            {item.score ? <span
                                                                className='rate'><small>{item.score}</small></span> : ''}


                                                            {getAreaAndYear(item) ? <span
                                                                className='w-newfigure-hint-left'><small>{getAreaAndYear(item)}</small></span> : ''}


                                                            {item.tip ? <span
                                                                className='w-newfigure-hint'><small>{item.tip}</small></span> : ''}


                                                        </div>
                                                        <CardBody>
                                                            {/*<CardTitle>   {item.title}</CardTitle>*/}
                                                            <CardSubtitle>{item.title}</CardSubtitle>
                                                            {item.desc &&
                                                            <CardText><small>{getLimit(item.desc)}</small></CardText>}

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </div>
                            )
                        }</div>

                </>
            ) : null} </div>)
// ) : ''
}
