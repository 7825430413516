import React from 'react';
import {Modal} from 'antd';
import {List} from 'antd';
import {getLinkOpenTarget} from "../util/UrlUtil";

export function SearchModel(props) {


    let keyword = props.keyword;
    const baidu = "https://www.baidu.com/s?wd=" + keyword + "&ie=utf-8";
    const so = "https://www.so.com/s?ie=utf-8&q=" + keyword;
    const google = "https://www.google.com/search?q=" + keyword + "&sourceid=chrome&ie=UTF-8";
    const sogou = "https://www.sogou.com/web?query=" + keyword + "&ie=utf8";
    const bing = "https://cn.bing.com/search?q=" + keyword;
    const yahoo = "https://search.yahoo.com/search?p=" + keyword + "&ei=UTF-8";
    const data = [
        {title: '百度', href: baidu},
        {title: '360', href: so},
        {title: '搜狗', href: sogou},
        {title: '必应', href: bing},
        {title: '谷歌', href: google},
        {title: '雅虎', href: yahoo}
    ];


    return (
        <div>
            <Modal
                title="没找到"
                visible={props.visible}
                // onOk={this.handleOk}
                onCancel={props.handleCancel}
                footer={null}
            >
                <List
                    bordered
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <a target={getLinkOpenTarget()} href={item.href}> {item.title}</a>
                        </List.Item>
                    )}
                />

            </Modal>
        </div>
    );
    
}