import {ProjectConstant} from "../data/ProjectConstant";


export function getProjectTitle(current) {
    // let name = process.env.REACT_APP_WEBSITE_NAME;
    // let host = window.location.host;
    // if (host != null && host.indexOf("fuwuhao") !== -1) {
    //     name = "服务号"
    // }

    // if (current) {
    //     const category = ProjectConstant.category;
    //     let cname = category[current];
    //
    //     name = cname ? name + "-" + cname : name;
    //     name = "个人资料储存";
    // }
    return process.env.REACT_APP_WEBSITE_NAME;
}
