import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import Truncate from 'react-truncate';

class ReadMore extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            expanded: false,
            truncated: false
        };

        this.handleTruncate = this.handleTruncate.bind(this);
        this.toggleLines = this.toggleLines.bind(this);
    }

    handleTruncate(truncated) {
        if (this.state.truncated !== truncated) {
            this.setState({
                truncated
            });
        }
    }

    toggleLines(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        const {
            children,
            more,
            less,
            lines,
            lang
        } = this.props;

        const {
            expanded,
            truncated
        } = this.state;

        function separator(language) {
            if (language === "English" || language === 'english') {
                return " ";
            }
            return "";
        }
        return (
            <div>
                <Truncate
                    separator={separator(lang)}
                    lines={ expanded?null:lines}
                    ellipsis={(
                        <span>...<Button type="link" onClick={this.toggleLines}>{more}</Button>
                        </span>
                    )}
                    onTruncate={this.handleTruncate}
                >
                    {children}
                </Truncate>
                {!truncated && expanded && (
                    <span>
                         <Button type="link" onClick={this.toggleLines}>{less}</Button>
                    </span>
                )}
            </div>
        );
    }
}

ReadMore.defaultProps = {
    lines: 3,
    more: 'Read more',
    less: 'Show less',
    lang: 'zh'
};
ReadMore.propTypes = {
    children: PropTypes.node.isRequired,
    lines: PropTypes.number,
    less: PropTypes.string,
    more: PropTypes.string,
    lang: PropTypes.string
};

export default ReadMore;