import React from 'react';

import {Button} from 'react-bootstrap';

import {Card as AntCard, Col, message, Popover, Rate, Row, Tabs as AntTabs, Tooltip} from 'antd';
import {isMobile} from "react-device-detect";
import {DownloadCopy} from "./DownloadCopy";
import {DownloadList} from "./DownloadList";
import {PbDownloadList} from "./PbDownloadList";
import {VideoLike} from "./VideoLike";
import 'react-tabs/style/react-tabs.css';
import {getLinkOpenTarget} from "../util/UrlUtil";

import {LeftCircleTwoTone, MobileTwoTone} from '@ant-design/icons';

import Truncate from "react-truncate";
import MyQRCode from "../comp/MyQRCode";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Img from 'react-image'
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";

// import {Base64} from 'js-base64';

export function VideoDetail(props) {

    let video = props.videoDetail;
    // const [videoDetail, setVideoDetail] = React.useState();
    // const [qrUrl, setQrUrl] = React.useState(null);
    // const [simplePlayers, setSimplePlayers] = React.useState(video.simplePlayers);
    // const [lcOtherPlayers, setLcOtherPlayers] = React.useState(video.lcOtherPlayers);
    // const [filmDownloads, setFilmDownloads] = React.useState(video.filmDownloads);
    const filmDownloads = video.filmDownloads;
    // const [cover, setCover] = React.useState(video.cover);
    // componentDidMount() {
    //     window.scrollTo(0, 0)
    // }
//     EDIT: React v16.8+


    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function adapterActor(txt, length) {
        if (!txt || txt.length === 0) return '';
        let txtLength = txt.length;
        return txt.substr(0, Math.min(length, txtLength));
    }


    let flag = !!video;
    if (!flag || !video.title) return <div>抱歉，没有找到</div>;
    // let simplePlayers = video.simplePlayers;
    let domp4Downloads = video.domp4Downloads;
    // const title = video.title;
    let sourcePlayerMap = video.sourcePlayerMap;
    if (sourcePlayerMap != null && sourcePlayerMap.hasOwnProperty("高清")) {
        sourcePlayerMap.delete("高清");
    }
    setBdPlay(video);

    let filmStatus = video.filmStatus;
    let act = adapterActor(video.act, isMobile ? 28 : 150);
    let desc = video.desc;
    // let cover = video.cover;
    let dScore = video.doubanScore;
    // const lcPlayerMap = video.lcPlayerMap;
    const parsePlayerMap = video.parsePlayerMap;
    parsePlayerMap && localStorage.setItem("parse_player_map", JSON.stringify(parsePlayerMap));
    let pbDownloads = video.pbDownloads;
    let downloadFlag = filmDownloads && filmDownloads.length;
    let domp4Flag = domp4Downloads && domp4Downloads.length;
    let tw80sDownloads = video.tw80sDownloads;
    let tw80sDownloadsFlag = tw80sDownloads && tw80sDownloads.length > 0;
    let pbDowloadFlag = pbDownloads && video.pbDownloads.length;
    let likes = video.video_rec;
    let imdbTitle = video.imdbTitle;
    let doubanId = video.doubanId;
    let pianyuanMap = video.pianyuanMap;
    if (pianyuanMap) {
        if (!Object.keys(pianyuanMap).length) pianyuanMap = null;
    }

    let m4Map = video.m4Map;
    if (m4Map) {
        if (!Object.keys(m4Map).length) m4Map = null;
    }

    function replaceUrl(url) {
        if (url.indexOf("//mp4er.com") !== -1 || url.indexOf("//www.mp4er.com") !== -1) {

            return url.replace("mp4er.com", "baidu.com");
        }

        if (url.indexOf("//mvm.com") !== -1 || url.indexOf("//www.mvm.com") !== -1) {

            return url.replace("mvm.com", "baidu.com");
        }

        return url
    }

    const catMap = {

        "qq": "腾讯",
        "qiyi": "爱奇艺",
        "m1905": "电影网",
        "youku": "优酷",
        "sohu": "搜狐",
        "leshi": "乐视",
        "tudou": "土豆",
        "imgo": "芒果TV",
        "pptv": "PP视频",
        "fengxing": "风行",
        "cntv": "CNTV",
        "y3600": "热播网",
        "bd": "高请",
        "hanju": "韩剧网",
        "kumi": "酷米",
        "xunlei": "看看",
        "taomi": "淘米",
        "huashu": "华数TV",
        "bilibili": "哔哩哔哩",
        "zgltv": "中国蓝TV",
        "funshion": "风行",
        "huanxi": "欢喜",
        "yingshi360": "360影视"
    }


    function getCatLabel(name) {

        return catMap[name] || name;
    }


    let images;
    if (video) {
        images = [video.cover, RestUrls[DataTypes.VIDEO_NO_PIC]];
    }


    function shortAct(act) {
        if (!act) return null;
        let maxLen = 50;
        if (isMobile) maxLen = 15;

        return act.length > maxLen ? act.substring(0, maxLen - 3) : act;
    }

    function copy() {
        // alert("a")
        // copyToClipboard(txt)
        message.success("复制成功");
        // alert("a");
    }

    return flag &&
        <div className="p-1">
            <div className="card-header">
                               <span className="float-start me-2"
                                     style={{
                                         marginTop: '-0.2rem'
                                     }
                                     }
                                     onClick={() =>
                                         window.open("/video", "_self")
                                         // window.history.back()
                                     }
                               >
                    <LeftCircleTwoTone
                        // type="left-circle"
                        //                 theme="twoTone"
                    />
                </span>
                {/*{video.title}*/}

                <CopyToClipboard text={video.title}
                                 onCopy={copy}>
                    <Tooltip placement="topRight" title="可复制标题">
                        <span> {video.title}</span></Tooltip>
                </CopyToClipboard>
                {/*    <AntButton type="link" onClick={copy}> {video.title}</AntButton>*/}


                <Popover className="float-end me-2 mt-1"
                         trigger="click"
                         content={<MyQRCode/>}
                         placement="top"
                         title="手机观看">
                    <MobileTwoTone/>
                </Popover>


            </div>
            <div className="card mb-3">
                <Row>
                    <Col flex="160px"> <Img
                        // style={{width: 180, height: 250}}
                        //  class="img-fluid rounded-start"
                        className="cover"
                        src={images} alt={video.title}/></Col>
                    <Col flex="auto">
                        <div className="ms-3">
                            {/*<h5 className="card-title">Card title</h5>*/}
                            {video.tag ? <div>类型:{video.tag}</div> : ""}
                            {video.type ? <div>分类:{video.type}</div> : ""}
                            {video.dir ? <div>导演:{video.dir}</div> : ""}
                            {video.area ? <div>地区:{video.area}</div> : ""}
                            {video.year ? <div>年份:{video.year}</div> : ""}
                            {doubanId ? <div>豆瓣:<a rel="noopener noreferrer" target="_blank"
                                                   href={`https://movie.douban.com/subject/${doubanId}/`}>前往豆瓣</a>
                            </div> : ""}
                            {dScore ? <div>分数:
                                <Rate allowHalf defaultValue={dScore * 5 / 10} disabled={true}/>
                            </div> : ""}
                            {filmStatus ? <div>状态:{filmStatus}</div> : ""}
                            {imdbTitle ? <div>IMDB:<a rel="noopener noreferrer" target="_blank"
                                                      href={`https://www.imdb.com/title/${imdbTitle}`}>前往IMDB</a>
                            </div> : ""}

                            {video.act ? <Truncate separator=''>
                                <div>演员: {shortAct(act)}</div>
                            </Truncate> : ""}
                            {video.subLink && <div>字幕:<a rel="noopener noreferrer" target={getLinkOpenTarget()}

                                                         href={video.subLink}>查找</a>
                            </div>
                            }
                        </div>
                    </Col>
                </Row>


                {desc &&
                <div className={"m-2 p-2"}>
                    <h6>影视简介</h6>
                    <div
                        style={{'textIndent': '2rem'}}
                        dangerouslySetInnerHTML={{__html: desc}}
                    />

                </div>
                }
            </div>


            {
                sourcePlayerMap && <AntTabs>
                    {Object.keys(sourcePlayerMap).map((name, index) =>
                        <AntTabs.TabPane tab={getCatLabel(name)} key={index}>
                            {sourcePlayerMap[name].map((item, index) =>
                                <Button key={index} target={getLinkOpenTarget()}
                                        href={replaceUrl(item.url)}
                                        outline color="info"
                                        className="m-1"
                                >{item.title}</Button>)
                            }
                        </AntTabs.TabPane>
                    )
                    }
                </AntTabs>
            }


            {/*{firstSource &&*/
            }
            {/*<Card className="p-1 mt-1">*/
            }
            {/*    /!*<CardTitle>vip解析</CardTitle>*!/*/
            }
            {/*    <AntTabs>*/
            }
            {/*        <AntTabs.TabPane tab={'VIP解析'} key={'vip1'}>*/
            }
            {/*            {firstSource.map(item =>*/
            }
            {/*                <Button key={item.url}*/
            }
            {/*                        onClick={() => goPlay(item)}*/
            }
            {/*                        outline color="info"*/
            }
            {/*                    // href={getPlayUrl(video, item)}*/
            }
            {/*                        className="m-1"*/
            }
            {/*                    // target={getLinkOpenTarget()}*/
            }
            {/*                >{item.title}</Button>*/
            }
            {/*            )*/
            }
            {/*            }*/
            }
            {/*        </AntTabs.TabPane>)*/
            }

            {/*    </AntTabs>*/
            }
            {/*</Card>*/
            }
            {/*}*/
            }


            {/*{sourcePlayerMap && <AntTabs>*/
            }
            {/*    {Object.keys(sourcePlayerMap).map((name, index) =>*/
            }
            {/*        <AntTabs.TabPane tab={name} key={index}>*/
            }
            {/*            {sourcePlayerMap[name].map((item, index) =>*/
            }
            {/*                <Button key={index} target={getLinkOpenTarget()}*/
            }
            {/*                        href={replaceUrl(item.url)}*/
            }
            {/*                        outline color="info"*/
            }
            {/*                        className="m-1"*/
            }
            {/*                >{item.title}</Button>)*/
            }
            {/*            }*/
            }
            {/*        </AntTabs.TabPane>*/
            }
            {/*    )*/
            }
            {/*    }*/
            }
            {/*</AntTabs>*/
            }
            {/*}*/
            }


            {/*{parsePlayerMap &&*/
            }
            {/*<Card className="p-1 mt-1">*/
            }
            {/*    <CardTitle>免费解析</CardTitle>*/
            }
            {/*    <AntTabs>*/
            }
            {/*        {Object.keys(parsePlayerMap).map((name, index) =>*/
            }
            {/*            <AntTabs.TabPane tab={name} key={index}>*/
            }
            {/*                {parsePlayerMap[name].map(item =>*/
            }
            {/*                    <Button key={item.url}*/
            }
            {/*                            href={'/video/iplay?url=' + item.url + "&title=" + video.title + "&cat=" + name + "&index=" + item.title}*/
            }
            {/*                            outline color="info"*/
            }
            {/*                            className="m-1"*/
            }
            {/*                            target={getLinkOpenTarget()}*/
            }
            {/*                    >{item.title}</Button>*/
            }
            {/*                )*/
            }
            {/*                }*/
            }
            {/*            </AntTabs.TabPane>*/
            }
            {/*        )*/
            }
            {/*        }*/
            }
            {/*    </AntTabs>*/
            }
            {/*</Card>*/
            }
            {/*}*/
            }
            {/*{parsePlayerMap &&*/
            }
            {/*<Card className="p-1 mt-1">*/
            }
            {/*    <CardTitle>vip解析</CardTitle>*/
            }
            {/*    <AntTabs>*/
            }
            {/*        {Object.keys(parsePlayerMap).map((name, index) =>*/
            }
            {/*            <AntTabs.TabPane tab={name} key={index}>*/
            }
            {/*                {parsePlayerMap[name].map(item =>*/
            }
            {/*                    <Button key={item.url}*/
            }

            {/*                            href={'/video/mvmIframe?title=' + video.title + ' ' + item.title + '&url=' + item.url.replace("mp4er.com", "baidu.com").replace("https://", "//").replace("http://", "//")}*/
            }
            {/*                            outline color="info"*/
            }
            {/*                            className="m-1"*/
            }
            {/*                            target={getLinkOpenTarget()}*/
            }
            {/*                    >{item.title}</Button>*/
            }
            {/*                )*/
            }
            {/*                }*/
            }
            {/*            </AntTabs.TabPane>*/
            }
            {/*        )*/
            }
            {/*        }*/
            }

            {/*    </AntTabs>*/
            }
            {/*</Card>*/
            }
            {/*}*/
            }


            {/*    (!parsePlayerMap || isMobile) &&*/
            }
            {/*{lcPlayerMap &&*/
            }
            {/*<AntTabs>*/
            }
            {/*    {Object.keys(lcPlayerMap).map((name, index) =>*/
            }
            {/*        <AntTabs.TabPane tab={name} key={index}>*/
            }
            {/*            {lcPlayerMap[name].map(item =>*/
            }
            {/*                <Button key={item.url} target={getLinkOpenTarget()}*/
            }
            {/*                        href={item.url + "?title=" + title + item.title + "&cat=" + name}*/
            }
            {/*                        outline color="info"*/
            }
            {/*                        className="m-1"*/
            }
            {/*                >{item.title}</Button>)*/
            }
            {/*            }*/
            }
            {/*        </AntTabs.TabPane>*/
            }
            {/*    )*/
            }
            {/*    }*/
            }
            {/*</AntTabs>*/
            }
            {/*}*/
            }


            {
                (downloadFlag || pbDowloadFlag) && <AntTabs defaultActiveKey="1">
                    {downloadFlag &&
                    <AntTabs.TabPane tab="高清下载" key="1">
                        <DownloadList downloads={filmDownloads}/>
                    </AntTabs.TabPane>}
                    {pbDowloadFlag && <AntTabs.TabPane tab="普通下载" key="2">
                        <PbDownloadList downloads={pbDownloads}/>
                    </AntTabs.TabPane>}
                </AntTabs>
            }
            {
                domp4Flag && <AntCard title="下载地址">
                    <DownloadCopy downloads={domp4Downloads} title={video.title}/>

                </AntCard>
            }


            {/*{domp4Flag && <div className="mt-2 ms-1 p-1">*/
            }
            {/*    <h6>下载地址</h6>*/
            }
            {/*    <DownloadList downloads={domp4Downloads}/>*/
            }
            {/*</div>*/
            }
            {/*}*/
            }


            {
                m4Map &&
                <div className="mt-2 ms-1 p-1">
                    <h6>下载地址1</h6>
                    <AntTabs>
                        {Object.keys(m4Map).map((name, index) =>
                            <AntTabs.TabPane tab={name} key={index}>
                                {m4Map[name].map((item, indexJ) =>
                                    <CopyToClipboard key={`${item.title}_${indexJ}`}
                                                     text={item.linkType === 'baidu' ? item.password : item.url}
                                                     onCopy={() => message.success(item.linkType === 'baidu' ? "点击打开，粘贴密码" : "复制成功")}
                                    >
                                        <Button key={item.url}
                                                outline color="info"
                                                href={item.url}
                                                target={item.linkType === 'baidu' ? '_blank' : '_self'}
                                                className="m-1"
                                        ><span>{item.title}</span> {item.quality ?
                                            <span className='ms-1'>{item.quality}</span> : ''}
                                            {item.password ?
                                                <span className='ms-1'>密码:{item.password}</span> : ''}
                                        </Button>
                                    </CopyToClipboard>
                                )
                                }
                            </AntTabs.TabPane>
                        )
                        }
                    </AntTabs>
                </div>
            }

            {/*{tw80sDownloadsFlag && <div className="mt-2 ms-1 p-1">*/
            }
            {/*    <h6>下载地址2</h6>*/
            }
            {/*    <DownloadCopy downloads={tw80sDownloads} title={video.title}/>*/
            }
            {/*</div>*/
            }
            {/*}*/
            }

            {
                tw80sDownloadsFlag && <AntCard title="下载地址2">
                    <DownloadCopy downloads={tw80sDownloads} title={video.title}/>
                </AntCard>
            }


            {
                pianyuanMap &&
                <div className="mt-2 ms-1 p-1">
                    <h6>其它下载源</h6>
                    <AntTabs>
                        {Object.keys(pianyuanMap).map((name, index) =>
                            <AntTabs.TabPane tab={name} key={`pianyuan_${name}_${index}`}>
                                {pianyuanMap[name].map((item, indexJ) =>
                                    <CopyToClipboard key={`${item.title}_${indexJ}`} text={item.magnet}
                                                     onCopy={() => message.success("复制成功")}
                                    >
                                        <Button key={item.magnet}
                                                outline color="info"
                                                href={item.magnet}
                                                className="m-1"
                                        ><span>{item.version || item.title}</span> <span
                                            className="mt-1">{item.size}</span></Button>
                                    </CopyToClipboard>
                                )
                                }
                            </AntTabs.TabPane>
                        )
                        }
                    </AntTabs>
                </div>
            }


            {
                likes && likes.length > 0 && <VideoLike likes={likes} directQuery={props.directQuery}/>
            }
        </div>
}

function setBdPlay(video) {
    // let filmDownloads = video.filmDownloads;
    // const title = video.title;
    // let sourcePlayerMap = video.sourcePlayerMap;
    let bdUrl = video.bdUrl;
    // let basePlayBd;
    if (bdUrl) {
        let ei = bdUrl.lastIndexOf("/");
        let bi = bdUrl.lastIndexOf("://");
        if (ei !== -1 && bi !== -1) {
            // basePlayBd = bdUrl.substring(bi + 1, ei) + "/play";
        }
    }

    // let number = video.onlineCount;
    // let playFilter = filmDownloads &&
    //     filmDownloads.filter(item => item.url.indexOf("ed2k") === 0);
    // if (!playFilter) {
    //     playFilter = filmDownloads && filmDownloads.filter(item => item.url.indexOf("magnet") === 0);
    // }

    // if (number === null || number === 0 || number === undefined) {
    //     number = playFilter ? playFilter.length : 0;
    // }
    // let bdId = video.bdId;
    // let bds = [];
    // if (number && bdId) {
    //     for (let i = 0; i < number; i++) {
    //         let t = i + 1;
    //         let bd = {
    //             url: '/video/bd?url=' + basePlayBd + '/' + bdId + "-" + i + ".htm&title=" + title + " " + t,
    //             title: t
    //         };
    //         bds.push(bd);
    //     }
    // }
    // if (bds.length > 0) {
    //     if (sourcePlayerMap) {
    //         sourcePlayerMap['高清'] = bds;
    //     }
    // } else if (video.bdUrl) {
    //     if (sourcePlayerMap) {
    //         sourcePlayerMap['高清'] = [{
    //             title: video.title,
    //             url: '/video/bd?url=' + basePlayBd + '/' + bdId + "-0.htm&title=" + title
    //         }];
    //     }
    //
    // }
}

