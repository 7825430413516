import Axios from "axios";
import { RestUrls } from "./Urls";
export class RestDataSource {
    GetData = (dataType,params) =>
        this.SendRequest("get", RestUrls[dataType],params);

    // GetVideoSuggestsData = (url,params) =>
    //     this.SendRequest("get", url,params);

    SendRequest = (method, url, params, data) =>
        Axios.request({ method, url, params, data });
}