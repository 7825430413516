import {Card, CardBody, CardImg, CardSubtitle, Col, Row} from "reactstrap";
import React, {useState} from 'react';
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";


export const VideoLike = (props) => {
    const [likes] = useState(props.likes);

    function imgError(e) {
        // console.log(item);
        // console.log(e.target);
        let target = e.target;
        let errorSrc = target.src;
        let flag = errorSrc != null && errorSrc.indexOf("zhidahao.com") !== -1;
        if (!flag) {
            target.src = RestUrls[DataTypes.VIDEO_NO_PIC];
        }

    }

    // function getLink(item) {
    //     // let encode = encodeURIComponent(item.title);
    //     // window.location = `/video?q=${encode}&filmId=${item.filmId}`;
    //     window.location = `/video?filmId=${item.filmId}`;
    // }

    // onClick={props.directQuery.bind(this, item)}
    return <div className="mt-1"><h5 className="m-2">相关视频</h5><Row>
        {/*{*/}
        {/*    likes.map((item, index) =>*/}
        {/*        <Col key={item.title + index} xs="6" sm="4" md="2">*/}
        {/*            <Card key={item.cover} className='m-1'*/}
        {/*                  onClick={() => getLink(item)}*/}
        {/*            >*/}
        {/*                <CardImg top width="100%" src={item.cover} alt={item.title}*/}

        {/*                         onError={imgError}*/}

        {/*                />*/}
        {/*                <CardBody>*/}
        {/*                    <CardSubtitle>{item.title}</CardSubtitle>*/}
        {/*                </CardBody>*/}
        {/*            </Card>*/}
        {/*        </Col>*/}
        {/*    )*/}
        {/*}*/}
        {
            likes.map((item, index) =>
                <Col key={item.title + index} xs="6" sm="4" md="2">
                    <Card key={item.cover} className='m-1'
                          onClick={() => props.directQuery(item)}
                    >
                        <CardImg top width="100%" src={item.cover} alt={item.title}

                                 onError={imgError}

                        />
                        <CardBody>
                            <CardSubtitle>{item.title}</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
            )
        }


    </Row>
    </div>


};

