import React from 'react';
import {
    // AutoComplete,
    Button, Col, Input, message, Result, Row, Timeline, Select
    , Card, Tooltip
} from "antd";
import trim from "validator/es/lib/trim";
import {isMobile} from "react-device-detect";
import myFetch from "../libs/fetch"
import store from 'store';
import {getLinkOpenTarget} from "../util/UrlUtil";
import {useParams} from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";
import jsonp from "jsonp";
import lscache from "lscache";
import {SearchOutlined, PhoneTwoTone} from '@ant-design/icons';

const {Search} = Input;
const {Meta} = Card;
const {
    Option
    // , OptGroup
} = Select;

const coms = {
    "_auto": "自动", "ems": "EMS", "shentong": "申通快递", "shunfeng": "顺丰速运",
    "yuantong": "圆通速递", "yunda": "韵达速递", "zhongtong": "中通快递", "tiantian": "天天快递",
    "huitongkuaidi": "百世快递", "zhaijisong": "宅急送", "quanfengkuaidi": "全峰快递"
    , "debangwuliu": "德邦快递", "youzhengguonei": "邮政包裹信件", "ztky": "中铁物流",
    "aae": "AAE全球专递", "annengwuliu": "安能物流", "aramex": "Aramex", "baifudongfang": "百福东方",
    "bpost": "比利时邮政", "canpost": "加拿大邮政", "datianwuliu": "大田物流", "dhl": "DHL中国件", "dhlen": "DHL全球件",
    "emsguoji": "EMS国际件", "fanyukuaidi": "凡宇快递", "fedex": "FedEx国际件", "fedexcn": "Fedex",
    "fedexus": "FedEx美国件", "guotongkuaidi": "国通快递", "hengluwuliu": "恒路物流", "japanposten": "日本邮政",
    "jd": "京东快递", "jiajiwuliu": "佳吉快运", "jialidatong": "嘉里大通", "jiayiwuliu": "佳怡物流",
    "jiayunmeiwuliu": "加运美", "jinguangsudikuaijian": "京广速递", "kangliwuliu": "康力物流",
    "koreapost": "韩国邮政", "kuaijiesudi": "快捷快递", "kuayue": "跨越速运",
    "lianhaowuliu": "联昊通", "minbangsudi": "民邦速递", "minghangkuaidi": "民航快递", "mingliangwuliu": "明亮物流",
    "ocs": "OCS", "quanchenkuaidi": "全晨快递", "quanyikuaidi": "全一快递", "rrs": "日日顺物流", "rufengda": "如风达",
    "shenganwuliu": "圣安物流", "shenghuiwuliu": "盛辉物流", "suer": "速尔快递", "suijiawuliu": "穗佳物流",
    "tiandihuayu": "天地华宇", "tnt": "TNT", "ups": "UPS", "usps": "USPS", "vancl": "凡客配送",
    "wanxiangwuliu": "万象物流", "xinfengwuliu": "信丰物流", "yinjiesudi": "银捷速递",
    "youshuwuliu": "优速物流", "ytkd": "运通中港快递", "yuanchengwuliu": "远成物流",
    "zengyisudi": "增益速递", "baishiwuliu": "百世快运"
};

export function ExpressQuery(props) {

    let {number} = useParams();

    // console.log("number:"+number)

    const expressLabel = "express_init";

    const [keyword, setKeyword] = React.useState(number);
    const [finalUrl, setFinalUrl] = React.useState();
    const [info, setInfo] = React.useState();
    const [error, setError] = React.useState();
    const [loading, setLoading] = React.useState();
    const [support, setSupport] = React.useState();
    const [expressUrl, setExpressUrl] = React.useState();
    const expressNumberKey = "express_numbers";
    const [searchHistory, setSearchHistory] = React.useState();
    const [value, setValue] = React.useState(number || '');
    const [com, setCom] = React.useState('_auto');
    const [company, setCompany] = React.useState();
    const searchRef = React.useRef();

    function dealData(data, value) {
        // let errorMessage = "获取数据失败,重试或稍后再试"
        setLoading(false);
        if (!data || !data.data || !data.data.info || data.msg) {
            queryFromServer(value)

            return;
        }
        let dd = data.data;
        let msg = data.msg;
        let info = dd.info;
        setInfo(info);
        setError(msg);
        let iContext = info.context;
        let com = dd.com ? dd.com : '_auto';
        setCom(com)
        let comp = dd ? dd.company : null;
        setCompany(comp);
        if (iContext) {
            addSearchHistory(expressNumberKey, value, com);
            lscache.set("express_" + value, data, 10);
        }
    }


    function queryJsonp(value, com) {

        value = trim(value);
        if (!value) return;

        if (value.toLowerCase().indexOf('sf') === 0) {
            setSupport(false);
            let site;
            if (!isMobile) {
                site = "https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/" + value;
            } else {
                site = "https://www.sf-express.com/mobile/cn/sc/index.html";
            }
            setError("暂不支持顺丰快递查询");
            setExpressUrl(site);
            setInfo(null)
        }

        if (!finalUrl) {
            return;
        }
        setExpressUrl(null)
        setLoading(true);
        let timestamp1 = (new Date()).valueOf();
        let callback = "jQuery110205464838398809442_" + timestamp1;
        let opt = {
            name: callback,
            timeout: 5000,
        }
        // if (!com) setCom("_auto");
        if (!com || "_auto" === com) {
            let comUrl = "https://apish.zhidahao.com/tool/express/com/" + value;
            console.log("comUrl:" + comUrl)
            myFetch(comUrl).then(data => {

                    if (data && data.finalUrl) {
                        lscache.set(expressLabel, data.finalUrl, 1);
                        setFinalUrl(data.finalUrl);
                    }
                    let com = "_auto";
                    if (data && data.com) {
                        com = data.com;
                    }
                    // alert(data.com)
                    setCom(com);
                    let u = finalUrl + "&cb=" + callback + "&nu=" + value + "&_=" + (timestamp1 + 3) + "&com=" + com;
                    // console.log("U:" + u);
                    jsonp(u, opt, (error, data) => {
                        // alert("result:" + data);
                        if (data) {
                            // let da = data.data;
                            // alert(JSON.stringify(da));
                            dealData(data, value);
                        }
                    });
                }
            )
        } else {
            console.log(" fetch from cache and com")
            let u = finalUrl + "&cb=" + callback + "&nu=" + value + "&_=" + timestamp1 + "&com=" + com;
            jsonp(u, opt, (error, data) => {
                if (data) {
                    dealData(data, value);
                }
            });

        }


    }

    function init() {
        let finalUrl = lscache.get(expressLabel);
        if (finalUrl) {
            // console.log("get from cache:" + finalUrl)
            setFinalUrl(finalUrl);
        } else {
            let iu = "https://apish.zhidahao.com/tool/express/init";
            myFetch(iu).then(data => {
                    if (data && data.finalUrl) {
                        lscache.set(expressLabel, data.finalUrl, 1);
                        // console.log("get from net :" + data.finalUrl)
                        setFinalUrl(data.finalUrl);
                    }
                }
            )
        }
    }


    function queryFromServer(value) {
        if (!value) return;
        if (value.toLowerCase().indexOf('sf') === 0) {
            setSupport(false);
            let site;
            if (!isMobile) {
                site = "https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/" + value;
            } else {
                site = "https://www.sf-express.com/mobile/cn/sc/index.html";
            }
            setError("暂不支持顺丰快递查询");
            setExpressUrl(site);
            // setCompany(null);
            setInfo(null)
            return;
        }
        setExpressUrl(null)
        setCompany(null);
        setInfo(null)
        setLoading(true);
        let url = "https://apish.zhidahao.com/tool/express/" + value;
        if (value.toLowerCase().indexOf('yt') === 0) {
            url = url + "?com=yuantong"
        }
        myFetch(url).then(data => {
            let errorMessage = "获取数据失败,请选择对的物流公司重试或稍后再试"
            setLoading(false);
            if (!data) {
                setError(errorMessage)
                setInfo(null)
                return;
            }

            let dd = data.data;
            if (!dd) {
                setError(errorMessage)
                return;
            }
            let com = dd.com ? dd.com : '_auto';
            setCom(com);
            if (!dd.info) {
                setError(errorMessage)
                return;
            }
            let uLinks = dd.intervene_url_link;
            let errorCode = data.error_code;
            let comp = dd.company;
            if (comp) {
                setCompany(comp);
                if (comp.url) {
                    setExpressUrl(dd.company.url)
                }
            }
            if (uLinks && uLinks.url) {
                setExpressUrl(uLinks.url);
            }
            let msg = data.msg;
            let statusCode = data.status;
            if ("11" === errorCode && "-3" === statusCode) {
                setSupport(false);
            }
            if (msg) {
                setError(msg);
                return;
            }

            let info = dd.info;
            setInfo(info);
            setError(msg);
            let iContext = info.context;

            if (iContext) {
                addSearchHistory(expressNumberKey, value, com);
                lscache.set("express_" + value, data, 10);
            }
        }).catch(error1 => {
            setLoading(false);
            // message.error(JSON.stringify(error1));
        });

    }


    React.useEffect(() => {
        lscache.flushExpired();
        setSearchHistory(store.get(expressNumberKey));
        init();
        // fetchBusinesses()
    }, [
        // fetchBusinesses
    ]);


    function onChange(e) {
        let v1 = e.target.value;
        setKeyword(v1);
    }

    let limit = isMobile ? 10 : 6;

    function addSearchHistory(key, value, com) {
        if (!value) return;
        let oldArr = store.get(key);
        if (oldArr) {
            for (let i = 0; i < oldArr.length; i++) {
                let number = oldArr[i].number;
                if (number) {
                    if (number.toUpperCase() === value.toUpperCase()) {
                        oldArr.splice(i, 1); // 如果数据组存在该元素，则把该元素删除
                        break;
                    }
                }
            }

            if (oldArr.length === limit) {
                oldArr.pop();
            }
            oldArr.unshift({number: value, com: com}); // 再添加到第一个位置
            store.set(key, oldArr);
        } else {
            let newArr = [];
            newArr.unshift({number: value, com: com});
            store.set(key, newArr);
        }
        setSearchHistory(store.get(key));
    }

    const buttonArray = [!support && expressUrl &&
        <CopyToClipboard key='1'
                         onCopy={() => {
                             message.success("运单号复制成功，可粘贴")

                         }}
                         text={value}
        >
            <Button type="primary" key="console" href={expressUrl}
                    target={getLinkOpenTarget()}
                    rel="noreferrer noopener nofollow"
            >去官网查询</Button></CopyToClipboard>, error && error.indexOf("重试") !== -1 &&
        <CopyToClipboard
            key='2'
            onCopy={() => {
                message.success("运单号复制成功，可粘贴")
            }}
            text={value}>
            <Button
                onClick={() => expressUrl ?
                    window.open(expressUrl, getLinkOpenTarget()) : window.location.reload}>
                {expressUrl ? '去官网查询' : '重载'}</Button>
        </CopyToClipboard>

        ]


    ;


    function getTrackColor(item, index) {
        if (index === 0) {
            return "已签收" === info.current ? 'green' : 'blue';
        }
        let desc = item.desc;
        return desc.indexOf("已取件") !== -1 || desc.indexOf("已签收") !== -1 ? 'green' : 'blue';
    }

    function formatDesc(desc) {
        if (!isMobile) return desc;
        let m = desc.match("(\\(\\d{3,4}\\)|\\d{3,4}-|\\s)?\\d{7,14}");
        let href;
        let a;
        if (m) {
            let phone = m[0];
            href = "tel:" + phone;
            a = `<a href="${href}">${phone}</a>`;
        }

        return m ? desc.replace(m[0], a) : desc;

    }


    function getResult(item) {
        if (!item) return;
        let value = item.number;
        let com = item.com;
        value = trim(value);
        if (!value) return;
        // setValue(value);
        setKeyword(value);
        setValue(value)
        setExpressUrl(null)
        setCompany(null);
        setError(null);
        setInfo(null);
        let cd = lscache.get("express_" + value);
        if (cd) {
            dealData(cd, value);
            // console.log("get from cache")
        } else {
            // console.log("get from net")
            queryJsonp(value, com);
        }
        searchRef.current.blur();
    }

    function onSearch(value) {
        if (!value || !trim(value)) return;
        getResult({number: value, com: com});
    }

    function comChange(value) {
        // console.log("comChange:" + value)
        setCom(value);
    }

    return (
        <div className={"mt-3"}>
            <div>
                <h5 className={"ms-5"}>{props.title || '快递查询'}</h5>
                <hr/>
                {searchHistory && searchHistory.length > 0 && <div className={"mt-2 ms-5 mb-2"}>
                    {
                        searchHistory.map((item, index) =>
                            <Button key={index} type={'link'} onClick={() => {
                                let number = item.number;
                                setKeyword(number)
                                setValue(number)
                                getResult(item)
                            }}>{item.number}</Button>
                        )
                    }
                </div>
                }

                <Row>
                    <Col span={isMobile ? 22 : 12} offset={isMobile ? 1 : 6}>
                        <Input.Group compact size="large">

                            <Select
                                size={"large"}
                                style={{width: isMobile ? 110 : 110}}
                                defaultValue={'_auto'}
                                value={com}
                                onChange={comChange}
                            >
                                {
                                    Object.keys(coms).map((item, index) =>
                                        <Option key={index} value={item}>
                                            {coms[item]}
                                        </Option>
                                    )
                                }


                            </Select>
                            <Search
                                style={{width: isMobile ? 250 : 500}}
                                placeholder='运单号'
                                enterButton
                                size="large"
                                value={keyword}
                                allowClear
                                autoFocus
                                loading={loading}
                                onSearch={onSearch}
                                onChange={onChange}
                                required="required"
                                ref={searchRef}

                            />
                        </Input.Group>


                        {
                            company &&
                            <div className={"container mt-2 "}>
                                <Card
                                    style={{
                                        width: 300
                                        // ,background:'#cccccc'
                                    }
                                    }
                                    actions={[

                                        <Tooltip title={company.website.url}>
                                            <CopyToClipboard text={value}>
                                                <Button type="link"
                                                        href={company.website.url}
                                                        target="_blank"
                                                        icon={<SearchOutlined key="site"/>}/>
                                            </CopyToClipboard>

                                        </Tooltip>,
                                        <Tooltip title={company.tel}>
                                            <Button type="link"
                                                    href={`tel:${company.tel}`}
                                                    icon={<PhoneTwoTone key="phone"/>}/>
                                        </Tooltip>


                                    ]}

                                >
                                    <Meta
                                        // avatar={<Avatar
                                        //     src={company.icon.smallurl}/>}
                                        title={company.shortname}
                                        description={`${company.fullname}`}
                                        // description="This is the description"
                                    />
                                </Card>
                            </div>

                        }


                        {error && <Result
                            // className="m-1"
                            status="error"
                            title="查询失败"
                            subTitle={error}
                            extra={buttonArray}

                        />
                        }


                        {info &&
                        (info.context ? <div className={"mt-3"}>
                            <h6><span>物流跟踪</span>{info.current &&
                            <span className={"ms-2"} style={{
                                color: '已签收' === info.current ? 'green' : 'blue'
                            }}>{info.current}</span>}</h6>

                            <hr/>

                            <div className={"container text-center"}>

                                <Timeline mode={'left'} className={"float-left"}>
                                    {
                                        info.context.map((item, i) => <Timeline.Item
                                                key={i}

                                                color={getTrackColor(item, i)}
                                                label={new Date(Number(item.time + "000")).toLocaleString()}

                                            >
                                                <div dangerouslySetInnerHTML={{__html: formatDesc(item.desc)}}/>
                                            </Timeline.Item>
                                        )
                                    }
                                </Timeline>
                            </div>
                        </div> : <span className={"m-3"}>{info.current}</span>)
                        }

                    </Col>
                </Row>


                {/*<div*/}
                {/*    className={"box"}*/}
                {/*    style={{*/}
                {/*        background: "#001529", height: 48*/}
                {/*        , position: 'fixed',*/}
                {/*        bottom: 0,*/}
                {/*        left: 0,*/}
                {/*        right: 0*/}
                {/*    }}*/}
                {/*><a style={{color: 'white'}} className={"ms-2"}*/}
                {/*    href="http://www.kuaidi.com/" target="_blank" rel="noreferrer noopener">快递查询接口</a></div>*/}
            </div>

        </div>
    )
}


// const fetchBusinesses = React.useCallback(() => {
//     // value = trim(value);
//     if (!value) return;
//
//     if (value.indexOf('SF') === 0 || value.indexOf('sf') === 0) {
//         setSupport(false);
//         let site;
//         if (!isMobile) {
//             site = "https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/" + value;
//         } else {
//             site = "https://www.sf-express.com/mobile/cn/sc/index.html";
//         }
//         setError("暂不支持顺丰快递查询");
//         setExpressUrl(site);
//         setInfo(null)
//         return;
//     }
//     setExpressUrl(null)
//     setLoading(true);
//     let url = "https://apish.zhidahao.com/tool/express/" + value;
//     if (value.indexOf('YT') === 0 || value.indexOf('yt') === 0) {
//         url = url + "?com=yuantong"
//     }
//     myFetch(url).then(data => {
//         let errorMessage = "获取数据失败,重试或稍后再试"
//         setLoading(false);
//         if (!data) {
//             // message.error(errorMessage);
//             setError(errorMessage)
//             setInfo(null)
//             return;
//         }
//
//         // }
//         let dd = data.data;
//
//
//         if (!dd || !dd.info) {
//             // message.error(errorMessage);
//             setError(errorMessage)
//             setInfo(null)
//             return;
//         }
//         let uLinks = dd.intervene_url_link;
//         let errorCode = data.error_code;
//         // alert(errorCode)
//         if (uLinks && uLinks.url) {
//             setExpressUrl(uLinks.url);
//         } else {
//             if (dd.company && dd.company.url) {
//                 setExpressUrl(dd.company.url)
//             }
//         }
//         let msg = data.msg;
//         let statusCode = data.status;
//         if ("11" === errorCode && "-3" === statusCode) {
//             setSupport(false);
//         }
//
//         if (msg) {
//             setError(msg);
//             setInfo(null)
//
//             if (dd.company && dd.company.url) {
//                 setExpressUrl(dd.company.url)
//             }
//             return;
//             // message.error(msg);
//         }
//
//         let info = dd.info;
//         setInfo(info);
//         setError(msg);
//         let iContext = info.context;
//         if (!iContext) {
//
//         } else {
//             addSearchHistory(expressNumberKey, value);
//         }
//         // alert(JSON.stringify(info));
//     }).catch(error1 => {
//         setLoading(false);
//         message.error(JSON.stringify(error1));
//     });
// }, [value])

// const [open, setOpen] = React.useState();
// const [options, setOptions] = React.useState([]);

// function getRandomInt(max: number, min: number = 0) {
//     return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
// }
// const searchResult = (query: string) => {
//     // alert(query)
//     // console.log("query:"+query)
//     if (!searchHistory) return '';
//     // if (searchHistory) {
//     //     console.log(searchHistory)
//     // }
//
//
//     return searchHistory
//         .join('.')
//         .split('.')
//         .map((item, idx) => {
//             // const category = `${query}${idx}`;
//             return {
//                 value: item,
//                 label: (
//                     <div
//                         style={{
//                             display: 'flex',
//                             justifyContent: 'space-between',
//                         }}
//                     >
//         <span>
//           {/*Found {query} on{' '}*/}
//             {/*  <a*/}
//             {/*      href={`https://s.taobao.com/search?q=${query}`}*/}
//             {/*      target="_blank"*/}
//             {/*      rel="noopener noreferrer"*/}
//             {/*  >*/}
//             {item}
//             {/*</a>*/}
//         </span>
//                         {/*<span>{getRandomInt(200, 100)} results</span>*/}
//                     </div>
//                 ),
//             };
//         });
// };

// const handleSearch = (value: string) => {
//     // alert(buttonSearch);
//
//     // if (!buttonSearch) {
//     // setOptions(value ? searchResult(value) : []);
//     setOptions(searchResult(value))
//     // }
//
// };
