import React from 'react';

import {Button, Input, message, Space, Switch} from 'antd';

import {isMobile} from "react-device-detect";
import pinyin from "pinyin";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {SettingOutlined} from '@ant-design/icons';
import pinyin4js from 'pinyin4js';
import store from 'store';

const {
    TextArea
    // , Search
} = Input;

// const {Option} = Select;

export function Hanzi2Pinyin(props) {
    const label = "tool_hanzi2pinyin_default";
    let setting = store.get(label);
    let sty = 0;
    let hete = false;
    if (setting) {
        sty = setting.style;
        hete = setting.heteronym;
    }
    // console.log("setting:"+JSON.stringify(setting))

    const [content, setContent] = React.useState();
    const [style, setStyle] = React.useState(sty);
    const [heteronym, setHeteronym] = React.useState(hete);
    const [result, setResult] = React.useState();
    const [split, setSplit] = React.useState(' ');
    // const [loading, setLoading] = React.useState(false);


    const tran = React.useCallback(() => {
        if (!content) {
            // message.warn('要转换的内容不能为空');
            setResult('');
            return;
        }
        // console.log("content:" + content)
        // console.log("style:" + style)
        // console.log("heteronym:" + heteronym)
        let pStyle = pinyin4js.WITH_TONE_MARK;
        if (style === 0) {
            pStyle = pinyin4js.WITHOUT_TONE;
        } else if (style === 1) {
            pStyle = pinyin4js.WITH_TONE_MARK;
        }
        // console.log("heteronym:" + heteronym)
        if (heteronym) {
            let resultArray = pinyin(content, {
                style: style, // 设置拼音风格
                heteronym: heteronym,
                segment: true
            });
            // console.log("resultArray:" + resultArray);
            let newArray = [];
            for (let i = 0; i < resultArray.length; i++) {
                let ri = resultArray[i];
                let riLen = ri.length;
                let r1s = ri[0];
                if (riLen > 1) {
                    r1s = "[" + ri.join(",") + "]";
                }
                newArray.push(r1s);
                // console.log("r1:" + r1s)
                // for (let j = 0; j < riLen; j++) {
                //     let r2 = ri[j];
                //     // console.log("r2:"+r2)
                // }
            }
            // console.log("newarray:"+newArray.join(" "))
            newArray && newArray.length > 0 && setResult(newArray.join(split));
        } else {
            try {
                let result = pinyin4js.convertToPinyinString(content, split, pStyle);
                result && setResult(result);
            } catch (e) {
                console.log("split:" + split)
            }

        }


    }, [content, style, heteronym, split]);


    React.useEffect(() => {

        tran()
    }, [tran]);


    function textChange(e) {
        let value = e.target.value;
        setContent(value);
    }


    function switchCheck(check) {
        // console.log("switchCheck:" + check);
        setHeteronym(check);
    }

    function toneCheck(check) {
        // console.log("toneCheck:" + check);
        let v = check ? 1 : 0;
        setStyle(v);
    }

    function setDefault() {
        console.log("style:" + style)
        console.log("heteronym:" + heteronym)
        store.set(label, {style: style, heteronym: heteronym});
        message.success("设置默认值成功");
    }

    const textAreaRef = React.useRef();


    function chack_name(str) {
        // let pattern = new RegExp("[`~!@#$^&*()=|{}':;,\\[\\].<>《》/?！￥…（）—【】‘；：”“。，、？ ]");
        let pattern = new RegExp("[`^*()\\[?…（）—【】‘；：”“。，、？]");
        return pattern.test(str);

    }

    function splitChange(e) {
        let value = e.target.value;
        if (chack_name(value)) {
            value = ' ';
        }
        setSplit(value);
    }

    return (

        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title}</h5>
            <hr/>

            <div
                className={"m-2"}
            >


                {/*<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className={"mt-3"}>*/}


                {/*    <Col className="gutter-row" span={12}>*/}

                        <Switch checkedChildren="有声调"

                                unCheckedChildren="没声调" defaultChecked={style === 1}

                                onChange={toneCheck}
                        />


                        <Switch checkedChildren="多音字"
                                className="ms-1"
                                unCheckedChildren="关闭" defaultChecked={heteronym}
                                checked={heteronym}
                                onChange={switchCheck}
                        />

                        {isMobile ? <Button
                            // shape="circle"
                            className="ms-2"
                            onClick={setDefault}
                            type="primary"
                            size={'small'}
                            icon={<SettingOutlined/>}
                        /> : <Button type="primary" className="ms-2" size={'small'} onClick={setDefault}>设置默认</Button>}
                        {/*设为默认*/}
                        <TextArea
                            ref={textAreaRef}
                            className={"mt-3"}
                            placeholder="输入要转换的汉字"
                            value={content}
                            onChange={textChange}
                            autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}/>
                    {/*</Col>*/}
                    {/*<Col className="gutter-row" span={12}>*/}

                        <Space className={"mt-2"}>
                            <Button disabled={!content}
                                    size={"small"}
                                    onClick={() => {
                                        setContent('');
                                        textAreaRef.current.focus();
                                    }}
                                    type="primary"
                            >清空</Button>


                            <CopyToClipboard
                                text={result} onCopy={() => message.success('复制成功')}>
                                <Button size={"small"}
                                        disabled={result === undefined || result === null || result === ''}
                                        type="primary"
                                >复制</Button>
                            </CopyToClipboard>


                            {/*<div style={{ marginBottom: 16 }}>*/}
                            {/*<Input  defaultValue=" " />*/}
                            <Input
                                disabled={result === undefined || result === null}
                                style={{width: 60}}
                                placeholder="分隔符"
                                // enterButton="确定"
                                size="small"
                                onChange={splitChange}
                            />
                        </Space>

                        <TextArea
                            className={"mt-3"}
                            placeholder="转换的结果"
                            value={result}
                            autoSize={{minRows: 6, maxRows: isMobile ? 10 : 20}}/>


                    {/*</Col>*/}
                {/*</Row>*/}

            </div>

        </div>

    )


}


// function callAsync(option) {
//     if (!content) return;
//     // let han = /^[\u4e00-\u9fa5]+$/;
//     // if (!han.test(content)) {
//     //     // alert("不是汉字")
//     //     console.log("不是汉字");
//     //     return false;
//     // }
//
//     console.log(pinyin(content, {
//         heteronym: true,              // 启用多音字模式
//         segment: true                 // 启用分词，以解决多音字问题。
//     }));                            // [ [ 'zhōng' ], [ 'xīn' ] ]
//     console.log(pinyin(content, {
//         style: pinyin.STYLE_INITIALS, // 设置拼音风格
//         heteronym: true
//     }));
//
//     console.log(pinyin(content, {
//         style: pinyin.STYLE_NORMAL, // 设置拼音风格
//         heteronym: true
//     }));
//
//
//     let textArray2 = pinyin(content, {
//         style: pinyin.STYLE_NORMAL, // 设置拼音风格
//         // heteronym: true
//     });
//     let textStr2 = textArray2.join(" ");
//     console.log("textStr2:" + textStr2);
//     console.log("text2 json:" + JSON.stringify(textStr2));
//
//     let textArray3 = pinyin(content, {
//         style: pinyin.STYLE_NORMAL, // 设置拼音风格
//         // heteronym: true
//     });
//     let textStr3 = textArray3.join(" ");
//     console.log("text3:" + textStr3);
//     console.log("text3 json:" + JSON.stringify(textStr3));
//     setResult(textArray3)
//
//
// }

// function callAsync(converter) {
//     // console.log("content:" + content)
//     // console.log("converter:" + converter)
//     if (!content || !converter) return;
//     setLoading(true);
//     const requestOptions = {
//         method: 'POST',
//         headers: {'Content-Type': 'application/json'},
//         body: JSON.stringify({
//             "key1": converter
//             , "key2": content
//         })
//     };
//     fetch("https://apish.zhidahao.com/opencc", requestOptions)
//         .then(response => response.json())
//         .then(data => {
//             data && data.content && setContent(data.content);
//             setLoading(false);
//             // setChangeFromOther(false);
//         });
//
// }
