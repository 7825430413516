import React from 'react';
import {Button, Col, InputNumber, Row, Select, Alert, Spin, Space, message, Tooltip} from "antd";
import jsonp from 'jsonp';
import {isMobile} from "react-device-detect";
import {SwapOutlined} from '@ant-design/icons';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const {OptGroup, Option} = Select
const exSelect = [{
    "id": "0",
    "moneys": {
        "money": [
            {"code": "CNY", "hot": "yes", "name": "\u4eba\u6c11\u5e01"},
            {
                "code": "USD", "hot": "yes", "name": "\u7f8e\u5143"
            },
            {"code": "JPY", "hot": "yes", "name": "\u65e5\u5143"}, {
                "code": "EUR",
                "hot": "yes",
                "name": "\u6b27\u5143"
            }, {"code": "GBP", "hot": "yes", "name": "\u82f1\u9551"}, {
                "code": "KRW",
                "hot": "yes",
                "name": "\u97e9\u5143"
            }, {"code": "HKD", "hot": "yes", "name": "\u6e2f\u5143"}, {
                "code": "AUD",
                "hot": "yes",
                "name": "\u6fb3\u5143"
            }, {"code": "CAD", "hot": "yes", "name": "\u52a0\u5143"}], "money#num#baidu": "9"
    },
    "txt": "\u70ed\u95e8"
}, {
    "id": "1",
    "moneys": {
        "money": [{
            "code": "AED",
            "idx": "A",
            "name": "\u963f\u8054\u914b\u8fea\u62c9\u59c6"
        }, {"code": "AUD", "idx": "A", "name": "\u6fb3\u5143"}, {
            "code": "MOP",
            "idx": "A",
            "name": "\u6fb3\u95e8\u5143"
        }, {"code": "DZD", "idx": "A", "name": "\u963f\u5c14\u53ca\u5229\u4e9a\u7b2c\u7eb3\u5c14"}, {
            "code": "OMR",
            "idx": "A",
            "name": "\u963f\u66fc\u91cc\u4e9a\u5c14"
        }, {"code": "EGP", "idx": "A", "name": "\u57c3\u53ca\u9551"}, {
            "code": "BYR",
            "idx": "B",
            "name": "\u767d\u4fc4\u7f57\u65af\u5362\u5e03"
        }, {"code": "BRL", "idx": "B", "name": "\u5df4\u897f\u96f7\u4e9a\u5c14"}, {
            "code": "PLN",
            "idx": "B",
            "name": "\u6ce2\u5170\u5179\u7f57\u63d0"
        }, {"code": "BHD", "idx": "B", "name": "\u5df4\u6797\u7b2c\u7eb3\u5c14"}, {
            "code": "BGN",
            "idx": "B",
            "name": "\u4fdd\u52a0\u5229\u4e9a\u5217\u5f17"
        }, {"code": "ISK", "idx": "B", "name": "\u51b0\u5c9b\u514b\u6717"}, {
            "code": "DKK",
            "idx": "D",
            "name": "\u4e39\u9ea6\u514b\u6717"
        }, {"code": "RUB", "idx": "E", "name": "\u4fc4\u7f57\u65af\u5362\u5e03"}], "money#num#baidu": "14"
    },
    "txt": "ABDE"
}, {
    "id": "2",
    "moneys": {
        "money": [{"code": "PHP", "idx": "F", "name": "\u83f2\u5f8b\u5bbe\u6bd4\u7d22"}, {
            "code": "HKD",
            "idx": "G",
            "name": "\u6e2f\u5143"
        }, {"code": "COP", "idx": "G", "name": "\u54e5\u4f26\u6bd4\u4e9a\u6bd4\u7d22"}, {
            "code": "CRC",
            "idx": "G",
            "name": "\u54e5\u65af\u8fbe\u9ece\u52a0\u79d1\u6717"
        }, {"code": "KRW", "idx": "H", "name": "\u97e9\u5143"}, {
            "code": "CAD",
            "idx": "J",
            "name": "\u52a0\u5143"
        }, {"code": "CZK", "idx": "J", "name": "\u6377\u514b\u514b\u6717"}, {
            "code": "KHR",
            "idx": "J",
            "name": "\u67ec\u57d4\u5be8\u745e\u5c14"
        }], "money#num#baidu": "8"
    },
    "txt": "FGHJ"
}, {
    "id": "3",
    "moneys": {
        "money": [{
            "code": "HRK",
            "idx": "K",
            "name": "\u514b\u7f57\u5730\u4e9a\u5e93\u7eb3"
        }, {"code": "QAR", "idx": "K", "name": "\u5361\u5854\u5c14\u91cc\u4e9a\u5c14"}, {
            "code": "KWD",
            "idx": "K",
            "name": "\u79d1\u5a01\u7279\u7b2c\u7eb3\u5c14"
        }, {"code": "KES", "idx": "K", "name": "\u80af\u5c3c\u4e9a\u5148\u4ee4"}, {
            "code": "LAK",
            "idx": "L",
            "name": "\u8001\u631d\u57fa\u666e"
        }, {"code": "RON", "idx": "L", "name": "\u7f57\u9a6c\u5c3c\u4e9a\u5217\u4f0a"}, {
            "code": "LBP",
            "idx": "L",
            "name": "\u9ece\u5df4\u5ae9\u9551"
        }, {"code": "CNH", "idx": "L", "name": "\u79bb\u5cb8\u4eba\u6c11\u5e01"}, {
            "code": "USD",
            "idx": "M",
            "name": "\u7f8e\u5143"
        }, {"code": "BUK", "idx": "M", "name": "\u7f05\u7538\u5143"}, {
            "code": "MYR",
            "idx": "M",
            "name": "\u9a6c\u6765\u897f\u4e9a\u6797\u5409\u7279"
        }, {"code": "MAD", "idx": "M", "name": "\u6469\u6d1b\u54e5\u9053\u62c9\u59c6"}, {
            "code": "MXN",
            "idx": "M",
            "name": "\u58a8\u897f\u54e5\u5143"
        }, {"code": "NOK", "idx": "N", "name": "\u632a\u5a01\u514b\u6717"}, {
            "code": "ZAR",
            "idx": "N",
            "name": "\u5357\u975e\u5170\u7279"
        }], "money#num#baidu": "15"
    },
    "txt": "KLMN"
}, {
    "id": "4",
    "moneys": {
        "money": [{"code": "EUR", "idx": "O", "name": "\u6b27\u5143"}, {
            "code": "CNY",
            "idx": "R",
            "name": "\u4eba\u6c11\u5e01"
        }, {"code": "CHF", "idx": "R", "name": "\u745e\u58eb\u6cd5\u90ce"}, {
            "code": "JPY",
            "idx": "R",
            "name": "\u65e5\u5143"
        }, {"code": "SEK", "idx": "R", "name": "\u745e\u5178\u514b\u6717"}, {
            "code": "SAR",
            "idx": "S",
            "name": "\u6c99\u7279\u91cc\u4e9a\u5c14"
        }, {"code": "LKR", "idx": "S", "name": "\u65af\u91cc\u5170\u5361\u5362\u6bd4"}, {
            "code": "RSD",
            "idx": "S",
            "name": "\u585e\u5c14\u7ef4\u4e9a\u7b2c\u7eb3\u5c14"
        }, {"code": "THB", "idx": "T", "name": "\u6cf0\u94e2"}, {
            "code": "TZS",
            "idx": "T",
            "name": "\u5766\u6851\u5c3c\u4e9a\u5148\u4ee4"
        }], "money#num#baidu": "10"
    },
    "txt": "ORST"
}, {
    "id": "5", "moneys": {
        "money": [{"code": "BND", "idx": "W", "name": "\u6587\u83b1\u5143"}, {
            "code": "UGX",
            "idx": "W",
            "name": "\u4e4c\u5e72\u8fbe\u5148\u4ee4"
        }, {"code": "ZMK", "idx": "X", "name": "\u65b0\u7684\u8d5e\u6bd4\u4e9a\u514b\u74e6\u67e5"}, {
            "code": "SYP",
            "idx": "X",
            "name": "\u53d9\u5229\u4e9a\u9551"
        }, {"code": "NZD", "idx": "X", "name": "\u65b0\u897f\u5170\u5143"}, {
            "code": "TRY",
            "idx": "X",
            "name": "\u65b0\u571f\u8033\u5176\u91cc\u62c9"
        }, {"code": "SGD", "idx": "X", "name": "\u65b0\u52a0\u5761\u5143"}, {
            "code": "TWD",
            "idx": "X",
            "name": "\u65b0\u53f0\u5e01"
        }, {"code": "HUF", "idx": "X", "name": "\u5308\u7259\u5229\u798f\u6797"}, {
            "code": "GBP",
            "idx": "Y",
            "name": "\u82f1\u9551"
        }, {"code": "JOD", "idx": "Y", "name": "\u7ea6\u65e6\u7b2c\u7eb3\u5c14"}, {
            "code": "IQD",
            "idx": "Y",
            "name": "\u4f0a\u62c9\u514b\u7b2c\u7eb3\u5c14"
        }, {"code": "VND", "idx": "Y", "name": "\u8d8a\u5357\u76fe"}, {
            "code": "ILS",
            "idx": "Y",
            "name": "\u4ee5\u8272\u5217\u65b0\u9521\u514b\u5c14"
        }, {"code": "INR", "idx": "Y", "name": "\u5370\u5ea6\u5362\u6bd4"}, {
            "code": "IDR",
            "idx": "Y",
            "name": "\u5370\u5c3c\u5362\u6bd4"
        }, {"code": "CLP", "idx": "Z", "name": "\u667a\u5229\u6bd4\u7d22"}], "money#num#baidu": "17"
    }, "txt": "WXYZ"
}];

export function ExchangeQuery(props) {

    const [number, setNumber] = React.useState(1);
    const [source, setSource] = React.useState('美元');
    const [target, setTarget] = React.useState('人民币');

    const [sourceValue, setSourceValue] = React.useState('0_1');
    const [targetValue, setTargetValue] = React.useState('0_0');

    const [error, setError] = React.useState();
    const [info, setInfo] = React.useState();
    const [loading, setLoading] = React.useState(false);


    const [moneyChange, setMoneyChange] = React.useState();

    const fetchExchange = React.useCallback(() => {
        if (0 === number) {
            setError('输入大于0的数值');
            setInfo(null);
            return;
        }
        if (!number) {
            setError('请输入一个有效的数字');
            setInfo(null);
            return;
        }
        if (source === target) {
            setError('货币单位不能相同');
            setInfo(null);
            return;
        }
        let value = number +
            source.replace(/[a-zA-Z]+/, "") + "等于多少" +
            target.replace(/[a-zA-Z]+/, "");
        setLoading(true)
        let timestamp1 = (new Date()).valueOf();
        let callback = "jQuery1102035239890649181427_" + timestamp1
        let opt = {
            name: callback,
            timeout: 5000,
        }
        let url = "https://sp0.baidu.com/8aQDcjqpAAV3otqbppnN2DJv/api.php?query=" + value + "&co=&resource_id=5293&t=" + timestamp1 + "&cardId=5293&ie=utf8&oe=gbk&cb=op_aladdin_callback&format=json&tn=baidu&alr=1&cb=" + callback + "&_=" + (timestamp1 + 100);
        jsonp(url, opt, (error, data) => {
            setLoading(false);
            if (!data || !data.Result) {
                errorResult();
                return;
            }
            let da = data.Result;
            if (!Array.isArray(da) || da.length < 1) {
                errorResult();
                return;
            }
            let dr = data.Result[0];
            if (!dr || !dr.DisplayData || !dr.DisplayData.resultData || !dr.DisplayData.resultData.tplData
                || !dr.DisplayData.resultData.tplData.pcdata
            ) {
                errorResult();
                return;
            }
            let infoObj = dr.DisplayData.resultData.tplData.pcdata;
            setInfo(infoObj);
            setMoneyChange(dr.DisplayData.resultData.tplData.money2_num);
            setError('');
        });
    }, [number, source, target]);


    React.useEffect(() => {
        fetchExchange()
    }, [fetchExchange]);


    function errorResult() {
        setError('查询失败');
        setInfo(null);
    }


    function numberChange1(value) {
        // console.log('changed1', value);
        setNumber(value);
    }


    const options = exSelect.map((d, di) =>
        <OptGroup key={di} label={d.txt}>
            {d.moneys.money.map((money, index) =>
                <Option key={`${di}_${index}`}
                        value={`${di}_${index}`}
                >{isMobile ? money.name : money.name + money.code}</Option>
            )
            }
        </OptGroup>
    );

    function change1(value, option) {
        // console.log("change1:" + value)
        value && setSourceValue(value);
        let name = option.children;
        // console.log("change1 name:" + name)
        name && setSource(name);
        // console.log("change1 option:" + JSON.stringify(option))

    }

    function change2(value, option) {
        // console.log("change2:" + value)
        let name = option.children;
        // console.log("change2 name:" + name)
        value && setTargetValue(value);
        name && setTarget(name);
    }

    function swap() {
        if (number && target && source) {
            let temp = source;
            let temp1 = target;
            let tempVal = sourceValue;
            let temVal2 = targetValue;
            // query(number, temp1, temp);
            setTargetValue(tempVal)
            setSourceValue(temVal2);
            setTarget(temp);
            setSource(temp1);
        }
    }

    const inputStyle = isMobile ? 110 : 190;

    function isCopyExchange() {
        return number === 1 && sourceValue === '0_1';
        // console.log("number:"+number)
        //   console.log("sourceValue:"+sourceValue)
        //   console.log("targetValue:"+targetValue)
        // console.log("r:"+r)
        // return r;

    }

    return (
        <div className={"mt-3"}>
            <h5 className={"ms-5"}>{props.title || '汇率查询'}</h5>
            <hr/>

            <Row>
                <Col span={isMobile ? 22 : 12} offset={isMobile ? 1 : 6}>
                    <Space>
                        <InputNumber
                            // style={{width: 80}}
                            // className={"m-1"}
                            min={0}
                            value={number}
                            // step={0.01}
                            onChange={numberChange1}/>
                        <Select
                            // className={"m-1"}
                            value={sourceValue}
                            style={{width: inputStyle}}
                            defaultValue="美元"
                            onChange={change1}>
                            {options}
                        </Select>
                        <Button
                            // type="primary"
                            // className={"m-1"}
                            icon={<SwapOutlined/>}
                            onClick={swap}
                        />
                        <Select
                            // className={"m-1"}
                            style={{width: inputStyle}}
                            defaultValue="人民币" onChange={change2}
                            value={targetValue}
                        >
                            {options}
                        </Select>
                        {/*<Button type="primary" className={"m-1"}*/}
                        {/*        // onClick={tran}*/}
                        {/*        loading={loading}>转换</Button>*/}
                    </Space>
                </Col>
            </Row>

            <div className={"m-3 text-center"}>
                {
                    error && <div><Alert message={error} type="warning" showIcon closable/>
                    </div>
                }


                {
                    loading ? <Spin/> :
                        info &&
                        <div>
                            {isCopyExchange() ? <CopyToClipboard
                                    text={moneyChange}
                                    onCopy={() => message.success("复制成功")}
                                >
                                    <Tooltip placement="topRight" title="可复制汇率">
                                        <span>{info.content1Mini}</span>
                                    </Tooltip>
                                </CopyToClipboard> :
                                info.content1Mini}

                            {info.support && <div className={"m-1"}>{info.support}</div>}
                        </div>

                }
            </div>


        </div>
    )
}
