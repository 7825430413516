import React from "react";
import { Fireworks } from 'fireworks-js/dist/react'
// https://github.com/crashmax-dev/fireworks-js
function MyFireworks() {
    const options = {
        speed: 1,
        explosion:10

    }

    const style = {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        opacity:1,
        // background: '#000'
        background: 'rgba(255,255,255,0)'
    }

    return <Fireworks options={options} style={style} />
}

export default MyFireworks;



