import React from "react";

import {useParams} from "react-router-dom";
import {RestUrls} from "../data/Urls";
import {DataTypes} from "../data/Types";
import {PanDetailBase} from "./PanDetailBase";

export function PanDetail() {
    let {id} = useParams();
    let url = RestUrls[DataTypes.PAN_URL] +  "/" + id;
    return <PanDetailBase url={url}  id={id}/>
}
