import AppTemp from "../AppTemp";
import React from "react";
import {isMobile} from "react-device-detect";

// src={RestUrls[DataTypes.MUSIC_SERVER]

export function Music(props) {
    let current = props.current;
    // }
    let navBarHeight = isMobile ? 48 : 64;

    function getContentHeight() {
        let height = window.screen.height;
        let offset = 93;
        return height - navBarHeight - offset;
    }

    console.log(getContentHeight());

    return (

        <AppTemp current={current} showFooter={false}>
            <iframe

                style={{position:"fixed","z-index":9999}}

                width="100%" height={getContentHeight()} title={"在线音乐播放"} src="//music.zhidahao.com"
                    allowFullScreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
            />
        </AppTemp>
    )


}
